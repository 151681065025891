import firebase from "firebase";

import { useTranslation } from "react-i18next";

import softDeleteDocument from "astrid-firestore/src/api/documents/softDeleteDocument";

import { useProfile } from "../../authentication/state/profile";

export default function useWorksTableDropdownMenuOptions() {
	const { t } = useTranslation();
	const user = useProfile();

	return (work) => [
		{
			text: t("view", "View"),
			linkTo: `/publisher/${work.publisher.id}/works/${work.id}`,
		},
		{
			text: t("delete", "Delete"),
			confirmText: t("deleteWorkConfirmation", "Are you sure you want to delete this work?"),
			action: () => {
				return softDeleteDocument(firebase, work, user);
			},
		},
	];
}
