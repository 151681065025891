import React from "react";

import useDocumentData from "astrid-firestore/src/hooks/useDocumentData";

import LoadingContext from "../../../ui/components/LoadingContext/LoadingContext";

import ArticleCard from "../../../articles/components/ArticleCard/ArticleCard";

export default function ProductionOverviewView({ production }) {
	const [work, loading, error] = useDocumentData(production.work?.ref);

	return (
		<LoadingContext data={work} loading={loading} error={error}>
			{work && <ArticleCard article={work} to={`/publisher/${work.publisher.id}/works/${work.id}`} />}
		</LoadingContext>
	);
}
