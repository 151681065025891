import db from "astrid-firebase/src/db";
import { useLoadingValues } from "astrid-firestore/src/hooks";
import useDocumentData from "astrid-firestore/src/hooks/useDocumentData";

import LoadingContext from "../../../ui/components/LoadingContext/LoadingContext";

import useInvoicesByOrderId from "../../../invoices/hooks/useInvoicesByOrderId";

import OrderActions from "./components/OrderActions";
import OrderInfo from "./components/OrderInfo";
import OrderInvoices from "./components/OrderInvoices";

export default function OrderView({ id, enableInvoices }) {
	const [[order, invoices], loading, error] = useLoadingValues(
		useDocumentData(id && db.collection("orders").doc(id)),
		useInvoicesByOrderId(id),
	);

	return (
		<LoadingContext data={order} loading={loading} error={error}>
			{order && (
				<>
					<OrderInfo order={order} />
					<OrderActions order={order} invoices={invoices} />
					{enableInvoices && !order.expense && <OrderInvoices order={order} invoices={invoices} />}
				</>
			)}
		</LoadingContext>
	);
}
