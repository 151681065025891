import { Route } from "react-router-dom";

import ForwardContext from "../../../layouts/ForwardContext";

import Serie from "../pages/Serie/Serie";
import SerieArticles from "../pages/Serie/SerieArticles";
import SeriesMetadata from "../pages/Serie/SeriesMetadata";
import Series from "../pages/Series";

export const series = (
	<Route path="series" element={<ForwardContext />} handle={{ crumb: ({ t }) => t("series", "Series") }}>
		<Route index element={<Series />} />

		<Route path=":serie" element={<Serie />} handle={{ crumb: ({ serie }) => serie?.name }}>
			<Route index element={<SerieArticles />} />

			<Route path="metadata" element={<SeriesMetadata />} />
		</Route>
	</Route>
);
