import { useTranslation } from "react-i18next";

import AdminCard from "../../../admin/components/AdminCard/AdminCard";
import ArticlesCollectionDataTable from "../../../articles/components/ArticlesCollectionDataTable/ArticlesCollectionDataTable";

export default function ImprintArticlesView({ imprint }) {
	const { t } = useTranslation();

	return (
		<AdminCard header={t("articles", "Articles")}>
			<ArticlesCollectionDataTable imprint={imprint} />
		</AdminCard>
	);
}
