import { Button } from "semantic-ui-react";

import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";

import db from "astrid-firebase/src/db";
import useDocumentDataOnce from "astrid-firestore/src/hooks/useDocumentDataOnce";

import LoadingContext from "../../../ui/components/LoadingContext/LoadingContext";

import AdminHeader from "../../../admin/components/AdminHeader/AdminHeader";

import AgreementTemplateCard from "../../components/AgreementTemplateCard/AgreementTemplateCard";
import AgreementsForm from "../../components/AgreementsForm/AgreementsForm";

export default function PrepareAgreementsView({ templateId, organization }) {
	const { t } = useTranslation();

	const navigate = useNavigate();

	const [template, loading, error] = useDocumentDataOnce(
		templateId && db.collection("agreementTemplates").doc(templateId),
	);

	return (
		<>
			<AdminHeader>
				{t("prepareAgreements", "Prepare agreements")}

				<Button.Group basic secondary>
					<Button as={Link} to="../templates">
						{t("createFromAgreementTemplate", "Create from agreement template")}
					</Button>
				</Button.Group>
			</AdminHeader>

			<LoadingContext data={template} loading={loading} error={error}>
				{template && <AgreementTemplateCard template={template} />}

				<AgreementsForm
					template={template}
					organization={organization}
					afterSubmit={() => navigate("../pending")}
				/>
			</LoadingContext>
		</>
	);
}
