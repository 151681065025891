import React, { useState } from "react";

import InstantSearchSelect from "../../../ui/components/InstantSearch/InstantSearchSelect";

import useFormField from "../../hooks/useFormField";

export default function FormInstantSearchSelect({ onAddDocument, multiple, shouldUnregister, getOption, ...props }) {
	const { field } = useFormField({ shouldUnregister, ...props });

	const initialOptions = (multiple ? field.value : [field.value])
		.filter(Boolean)
		.map(getOption)
		.map((a) => ({ ...a, disabled: true }));

	const [additionalOptions, setAdditionalOptions] = useState(initialOptions);

	const addDocument = async (value) => {
		const data = await onAddDocument(value);

		if (data?.id) {
			setAdditionalOptions((options) => [
				{
					key: data.id,
					text: value,
					value: data.id,
					data,
				},
				...options,
			]);
		}

		return data;
	};

	const onChange = async (e, { value, foundOptions }) => {
		const values = multiple ? value : [value];

		const allOptions = [...foundOptions, ...additionalOptions];

		const optionValues = allOptions.map(({ value }) => value);

		const data = await Promise.all(
			values.filter(Boolean).map((value) => {
				if (!optionValues.includes(value)) {
					return addDocument(value);
				}

				return allOptions.find(({ value: optionValue }) => optionValue === value).data;
			}),
		);

		field.onChange(e, { value: multiple ? data : data[0] || null });
	};

	return (
		<InstantSearchSelect
			{...field}
			value={multiple ? field.value?.map?.(({ id }) => id) : field.value?.id || null}
			additionalOptions={additionalOptions}
			onChange={onChange}
			multiple={multiple}
			ref={undefined}
			getOption={getOption}
			{...props}
		/>
	);
}
