import { Route } from "react-router-dom";

import Calendar from "../pages/Calendar";

export const calendar = (
	<Route path="calendar" element={<Calendar />}>
		<Route path=":productionId" element={null} />
	</Route>
);

export const calendarNarrator = <Route path="calendar" element={<Calendar />} />;
