import updateArtifact from "./updateArtifact";

export default function addArtifactMarker(firebase, { artifact, type, position }) {
	return updateArtifact(firebase, {
		...artifact,
		markers: [
			...artifact.markers,
			{
				type,
				position,
				text: "",
			},
		],
	});
}
