import FirestoreDocument from "../../../types/FirestoreDocument";
import FirestoreLifecycle from "../../../types/FirestoreLifecycle";
import { array, string } from "../../../types/_types";

import { OrganizationRef } from "../../organizations/types/OrganizationRef";

import { WorkMetaData } from "./WorkMetaData";

export const Work = WorkMetaData.extend({
	publisher: OrganizationRef,
	articleIds: array(string()),
})
	.merge(FirestoreDocument)
	.merge(FirestoreLifecycle);
