import DropdownMenu from "../../../ui/components/DropdownMenu/DropdownMenu";
import ErrorMessage from "../../../ui/components/Messages/ErrorMessage";
import useBreadcrumbs from "../../../ui/hooks/useBreadcrumbs";
import PageView from "../../../ui/views/PageView/PageView";

import CloudRunStatusLabel from "../../components/CloudRunStatus/CloudRunStatusLabel";
import PipelineStatusLabel from "../../components/DistributionStatus/PipelineStatusLabel";
import useDistributionDropdownMenuOptions from "../../hooks/useDistributionDropdownMenuOptions";

export default function DistributionView({ article, distribution, children }) {
	const breadcrumbs = useBreadcrumbs({ article, distribution });
	const dropdownMenuOptions = useDistributionDropdownMenuOptions(distribution);

	return (
		<PageView
			breadcrumbs={breadcrumbs}
			header={distribution.id}
			subheader={`${distribution.article.name} > ${distribution.channel.name}`}
			extra={
				<>
					<PipelineStatusLabel distribution={distribution} />
					<CloudRunStatusLabel distribution={distribution} />
					<DropdownMenu options={dropdownMenuOptions} />
				</>
			}
		>
			{distribution.error && <ErrorMessage error={distribution.error} />}

			{children}
		</PageView>
	);
}
