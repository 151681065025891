import React from "react";
import { Outlet, useOutletContext } from "react-router-dom";

import { useDocumentData } from "astrid-firestore/src/hooks";

import LoadingContext from "../../ui/components/LoadingContext/LoadingContext";

import featureFlags from "../../authorization/constants/featureFlags";

import ArticleView from "../views/ArticleView/ArticleView";

export default function Article() {
	const { organization, work, article } = useOutletContext();

	const [publisher, loading, error] = useDocumentData(article.publisher.ref);

	return (
		<LoadingContext data={publisher} loading={loading} error={error} featureFlag={featureFlags.FLATTENING}>
			<ArticleView organization={organization} publisher={publisher} work={work} article={article}>
				<Outlet context={{ organization, publisher, work, article }} />
			</ArticleView>
		</LoadingContext>
	);
}
