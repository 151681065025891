import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import db from "astrid-firebase/src/db";
import agreementStatuses from "astrid-firestore/src/api/agreements/constants/agreementStatuses";

import CollectionDataTable from "../../../ui/components/CollectionDataTable/CollectionDataTable";

import AdminHeader from "../../../admin/components/AdminHeader/AdminHeader";

import useAgreementColumns from "../../hooks/useAgreementColumns";

const pendingAgreementStatuses = [
	agreementStatuses.PENDING_APPROVAL,
	agreementStatuses.APPROVAL_DENIED,
	agreementStatuses.SIGNING,
	agreementStatuses.SIGNING_FAILED,
	agreementStatuses.SIGNING_EXPIRED,
];

export default function AgreementsPendingView({ firstPartyId }) {
	const { t } = useTranslation();
	const navigate = useNavigate();

	const query = db
		.collection("agreements")
		.where("deleted", "==", false)
		.where("firstParty.id", "==", firstPartyId)
		.where("status", "in", pendingAgreementStatuses);

	const columns = useAgreementColumns();

	return (
		<>
			<AdminHeader>{t("pendingAgreements", "Pending agreements")}</AdminHeader>

			<CollectionDataTable
				persist
				query={query}
				columns={columns}
				onClick={({ id }) => navigate(id)}
				sortBy={{
					id: "created",
					desc: true,
				}}
			/>
		</>
	);
}
