import AdminPageView from "../../../admin/components/AdminPage/AdminPageView";

import ExportHeader from "./components/ExportHeader";

export default function ExportView(props) {
	return (
		<AdminPageView {...props}>
			<ExportHeader />
		</AdminPageView>
	);
}
