import { useTranslation } from "react-i18next";

import OpenButton from "../../../ui/components/OpenButton/OpenButton";

import CreateArticleForm from "./CreateArticleForm";

export default function CreateArticleButton({ work, publisher, production, onSuccess, ...props }) {
	const { t } = useTranslation();

	return (
		<OpenButton text={t("createArticle", "Create article")} primary {...props}>
			{({ setOpen }) => (
				<CreateArticleForm
					work={work}
					publisher={publisher}
					production={production}
					onSuccess={onSuccess}
					onClose={() => setOpen(false)}
				/>
			)}
		</OpenButton>
	);
}
