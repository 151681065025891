import { useTranslation } from "react-i18next";

import InstantSearchSelect from "../../../ui/components/InstantSearch/InstantSearchSelect";

export default function WorkTags({ work, value, onChange }) {
	const { t } = useTranslation();

	return (
		<InstantSearchSelect
			multiple
			allowEmpty
			clearable
			allowAdditions
			label={t("tags", "Tags")}
			indexName="works"
			filters={`language:${work.language}`}
			refinement={{
				attribute: "tags",
				limit: 10,
			}}
			getOption={({ value, count }, index) => {
				return {
					key: `tags-${index}`,
					value: value,
					text: value,
					label: { circular: true, content: count },
				};
			}}
			value={value}
			onChange={onChange}
		/>
	);
}
