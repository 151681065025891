import { Card, Icon, Popup } from "semantic-ui-react";

import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { mapAgreementPrices } from "astrid-firestore/src/api/agreements/utils/agreementMappers";

import Table from "../../../../ui/components/Table/Table";

import useAgreementPricesColumns from "../../../hooks/useAgreementPricesColumns";

export default function AgreementPrices({ agreement }) {
	const { t } = useTranslation();

	const pricesColumns = useAgreementPricesColumns();
	const prices = mapAgreementPrices(agreement);

	const columns = useMemo(
		() => [
			...pricesColumns,
			{
				id: "approved",
				Cell: ({ value }) =>
					!value && (
						<Popup
							basic
							inverted
							content={t("costDifferFromTemplate", "Cost differ from template")}
							trigger={<Icon name="warning circle" />}
						/>
					),
			},
		],
		[pricesColumns, t],
	);

	return (
		<Card fluid>
			<Card.Content>
				<Card.Header>{t("prices", "Prices")}</Card.Header>
			</Card.Content>
			<Card.Content>
				<Table data={prices} columns={columns} footer={false} />
			</Card.Content>
		</Card>
	);
}
