import React from "react";
import { useTranslation } from "react-i18next";

import agreementParts from "astrid-firestore/src/api/agreements/constants/agreementParts";
import agreementTypes from "astrid-firestore/src/api/agreements/constants/agreementTypes";
import createPublisherPrice from "astrid-firestore/src/api/prices/createPublisherPrice";

import useAgreementsPriceOptions from "../../../../agreements/hooks/useAgreementsPriceOptions";
import Form from "../../../../forms/components/Form/Form";
import PriceTypeSelect from "../../../../prices/components/PriceTypeSelect/PriceTypeSelect";
import ModalPrompt from "../../../../ui/components/ModalPrompt/ModalPrompt";

import usePublisherPriceForm from "../hooks/usePublisherPriceForm";

export default function PublisherPriceForm({ publisher, organization, onClose }) {
	const { t } = useTranslation();

	const form = usePublisherPriceForm();

	const { watch, setValue, formState, handleSubmit } = form;

	const type = watch("type");

	const priceOptions = useAgreementsPriceOptions({
		priceTypes: [type],
		type: agreementTypes.FRAMEWORK,
		part: agreementParts.PUBLISHER,
		firstPartyId: organization?.id,
		secondPartyId: publisher?.id,
	});

	const onSubmit = async ({ type, price }) => {
		try {
			await createPublisherPrice({ type, price, publisher, organization });
		} catch (error) {
			throw error;
		}
	};

	return (
		<ModalPrompt
			header={t("addDefaultPrice", "Add default price")}
			disabled={!formState.isValid}
			onClose={onClose}
			onSave={handleSubmit(onSubmit)}
		>
			<Form form={form}>
				<Form.Group widths={2}>
					<Form.Field name="type">
						<PriceTypeSelect part={agreementParts.PUBLISHER} label={t("type", "Type")} />
					</Form.Field>

					{type && (
						<Form.Select
							name="priceId"
							label={t("price", "Price")}
							options={priceOptions}
							onChangeOption={(option) => setValue("price", option?.data)}
						/>
					)}
				</Form.Group>
			</Form>
		</ModalPrompt>
	);
}
