import { Route } from "react-router-dom";

import ForwardContext from "../../../layouts/ForwardContext";

import Scripts from "../pages/Scripts";

export const scripts = (
	<Route path="scripts" element={<ForwardContext />} handle={{ crumb: ({ t }) => t("scripts", "Scripts") }}>
		<Route index element={<Scripts />} />
	</Route>
);
