import { Route } from "react-router-dom";

import HomeAdmin from "../pages/HomeAdmin";
import HomeNarrator from "../pages/HomeNarrator";
import HomeProducer from "../pages/HomeProducer";
import HomePublisher from "../pages/HomePublisher";

export const homeProducer = <Route index element={<HomeProducer />} />;

export const homePublisher = <Route index element={<HomePublisher />} />;

export const homeSuperAdmin = <Route index element={<HomeAdmin />} />;

export const homeNarrator = <Route index element={<HomeNarrator />} />;
