import { useOutletContext } from "react-router-dom";

import Unauthorized from "../../ui/components/Unauthorized/Unauthorized";

import featureFlags from "../../authorization/constants/featureFlags";
import useFeatureFlag from "../../authorization/hooks/useFeatureFlag";

import ProducerDashboardView from "../views/ProducerDashboardView/ProducerDashboardView";

export default function ProducerDashboard() {
	const { organization } = useOutletContext();

	const hasFlatteningFeature = useFeatureFlag(featureFlags.FLATTENING);

	if (!hasFlatteningFeature) {
		return <Unauthorized />;
	}

	return <ProducerDashboardView organization={organization} />;
}
