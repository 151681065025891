import React from "react";
import { useTranslation } from "react-i18next";

import { firebase } from "astrid-firebase";
import { Serie } from "astrid-firestore/src/api/series/types/Serie";
import updateSerie from "astrid-firestore/src/api/series/updateSerie";

import AdminForm from "../../../../admin/components/AdminForm/AdminForm";
import Form from "../../../../forms/components/Form/Form";
import useSimpleForm from "../../../../forms/hooks/useSimpleForm";

export default function SerieMetadataForm({ serie }) {
	const { t } = useTranslation();

	const form = useSimpleForm({
		schema: Serie,
		values: {
			name: serie.name || null,
			description: serie.description || null,
			seasons: serie.seasons || [],
		},
		onSubmit: ({ name, description, seasons }) => {
			return updateSerie(firebase, { ref: serie.ref, name, description, seasons });
		},
	});

	return (
		<AdminForm header={t("metadata", "Metadata")} {...form}>
			<Form.Input name="name" label={t("name", "Name")} />
			<Form.TextArea name="description" label={t("description", "Description")} />
			<Form.FormFieldArrayTable name="seasons" header={t("seasons", "Seasons")}>
				{({ name }, index) => (
					<Form.Group widths="equal" style={{ margin: 0 }}>
						<Form.Input
							name={`seasons.${index}.name`}
							value={name}
							placeholder={t("seasonOne", "Season 1")}
						/>
					</Form.Group>
				)}
			</Form.FormFieldArrayTable>
		</AdminForm>
	);
}
