import { Route } from "react-router-dom";

import ForwardContext from "../../../layouts/ForwardContext";

import Order from "../pages/Order";
import PurchaseOrders from "../pages/PurchaseOrders";
import SalesOrders from "../pages/SalesOrders";

export const orders = (
	<Route path="orders" element={<ForwardContext />}>
		<Route path="purchases" element={<PurchaseOrders />} />
		<Route path=":id" element={<Order />} />
	</Route>
);

export const purchaseOrders = (
	<Route path="purchases" element={<ForwardContext />} handle={{ crumb: ({ t }) => t("POs") }}>
		<Route index element={<PurchaseOrders />} />
		<Route path=":id" element={<Order />} handle={{ crumb: ({ id }) => id }} />
	</Route>
);

export const salesOrders = (
	<Route path="sales" element={<ForwardContext />} handle={{ crumb: ({ t }) => t("salesOrders", "Sales orders") }}>
		<Route index element={<SalesOrders />} />
		<Route path=":id" element={<Order />} handle={{ crumb: ({ id }) => id }} />
	</Route>
);
