import { Route } from "react-router-dom";

import ForwardContext from "../../../layouts/ForwardContext";

import Agreement from "../pages/Agreement";
import AgreementTemplate from "../pages/AgreementTemplate";
import AgreementTemplates from "../pages/AgreementTemplates";
import AgreementsExpiring from "../pages/AgreementsExpiring";
import AgreementsPending from "../pages/AgreementsPending";
import CreateAgreementTemplate from "../pages/CreateAgreementTemplate";
import PrepareAgreements from "../pages/PrepareAgreements";

export const agreements = (
	<Route path="agreements" element={<ForwardContext />} handle={{ crumb: ({ t }) => t("agreements", "Agreements") }}>
		<Route index element={<AgreementsPending />} />
		<Route path=":id" element={<Agreement />} handle={{ crumb: ({ id }) => id }} />
	</Route>
);

export const agreementsExpiring = (
	<Route
		path="agreements-expiring"
		element={<ForwardContext />}
		handle={{ crumb: ({ t }) => t("expiringAgreements", "Expiring agreements") }}
	>
		<Route index element={<AgreementsExpiring />} />
		<Route path=":id" element={<Agreement />} handle={{ crumb: ({ id }) => id }} />
	</Route>
);

export const prepareAgreements = (
	<Route
		path="agreements-prepare"
		element={<ForwardContext />}
		handle={{ crumb: ({ t }) => t("prepareAgreements", "Prepare agreements") }}
	>
		<Route index element={<PrepareAgreements />} />
		<Route path=":templateId" element={<PrepareAgreements />} />
	</Route>
);

export const agreementTemplates = (
	<Route
		path="agreements-templates"
		element={<ForwardContext />}
		handle={{ crumb: ({ t }) => t("agreementTemplates", "Agreement templates") }}
	>
		<Route index element={<AgreementTemplates />} />
		<Route path=":id" element={<AgreementTemplate />} handle={{ crumb: ({ id }) => id }} />
		<Route path="create" element={<CreateAgreementTemplate />} />
	</Route>
);
