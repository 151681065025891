import firebase from "firebase";

import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { db } from "astrid-firebase";
import organizationTypes from "astrid-firestore/src/api/organizations/constants/organizationTypes";
import createWork from "astrid-firestore/src/api/works/createWork";
import { Work } from "astrid-firestore/src/api/works/types/Work";

import Form from "../../../forms/components/Form/Form";
import useSimpleForm from "../../../forms/hooks/useSimpleForm";

export default function CreateWorkForm({ publisher, onClose }) {
	const { t } = useTranslation();
	const navigate = useNavigate();

	const { form, onSubmit, error } = useSimpleForm({
		schema: Work.passthrough(),
		values: {
			name: "",
			language: null,
			publisher: publisher || null,
		},
		onSubmit: (data) => {
			return createWork(firebase, data);
		},
		onSuccess: ({ id }) => navigate(id),
	});

	return (
		<Form.Modal
			header={t("createWork", "Create work")}
			size="tiny"
			form={form}
			error={error}
			onSubmit={onSubmit}
			onClose={onClose}
		>
			{!publisher && (
				<Form.CollectionSelect
					name="publisher"
					label={t("publisher", "Publisher")}
					query={db.collection("organizations").where("type", "==", organizationTypes.PUBLISHER)}
				/>
			)}

			<Form.Input name="name" label={t("title", "Title")} />

			<Form.LanguageSelect name="language" label={t("language", "Language")} />
		</Form.Modal>
	);
}
