import trim from "lodash/trim";

import createDocumentData from "../../utils/createDocumentData";
import getDocumentData from "../../utils/getDocumentData";

import { Production } from "./types/Production";

export default async function createProduction(firebase, data) {
	if (data.title) {
		data.title = trim(data.title);
	}

	if (data.publisherId) {
		data.publisher = data.publisherId;
	}

	if (data.producerId) {
		data.producer = data.producerId;
	}

	if (data.workId) {
		data.work = await getDocumentData(firebase.firestore().collection("works").doc(data.workId));
	}

	if (data.publisherManagers) {
		data.publisherManagerIds = data.publisherManagers.map(({ id }) => id);
	}

	const production = Production.parse(createDocumentData(firebase, "productions", data));

	await production.ref.set(production);

	return production;
}
