import { Card } from "semantic-ui-react";

export default function PublisherInfo({ publisher }) {
	return (
		<Card fluid>
			<Card.Content>
				<Card.Header>{publisher.name}</Card.Header>
				<Card.Description>{publisher.email}</Card.Description>
			</Card.Content>
		</Card>
	);
}
