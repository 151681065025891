import distributeArticles from "../../src/api/distribution/distributeArticles";
import { canDeliverArticlesToChannels } from "../../src/api/distribution/utils/deliverArticlesToChannels";
import { filterDistributionChannelsByArticles } from "../../src/api/distribution/utils/filterDistributionChannels";
import { getCollectionData, getDocumentData } from "../../src/helpers/index";

export default async function distributeMigratedArticles({ api, env }, { articles }) {
	const publisher = await getDocumentData(articles[0]?.publisher.ref);
	const channels = await getCollectionData(
		api[env].db.collection("distributionChannels").where("deleted", "==", false),
	);

	const filteredData = filterDistributionChannelsByArticles({ publisher, articles, channels });
	const filteredChannelIds = [
		...new Set(filteredData.flatMap(({ filteredChannels }) => filteredChannels).map(({ id }) => id)),
	];
	const filteredChannels = channels?.filter(({ id }) => filteredChannelIds.includes(id)) || [];

	const delivery = filteredChannels.reduce((acc, channel) => {
		return {
			...acc,
			[channel.id]: {
				artifact: canDeliverArticlesToChannels({
					channel,
					articles,
					deliveryType: "artifact",
				}),
				metadata: canDeliverArticlesToChannels({
					channel,
					articles,
					deliveryType: "metadata",
				}),
			},
		};
	}, {});

	return distributeArticles(api.admin, { articles, channels: filteredChannels, delivery });
}
