import { useOutletContext, useParams } from "react-router-dom";

import Unauthorized from "../../ui/components/Unauthorized/Unauthorized";

import AdminPageView from "../../admin/components/AdminPage/AdminPageView";
import useHasRight from "../../authorization/hooks/useHasRight";

import PublisherView from "../views/PublisherView/PublisherView";

export default function Publisher() {
	const { id } = useParams();
	const { organization } = useOutletContext();

	const hasRight = useHasRight();

	if (!hasRight("publishers.view")) {
		return <Unauthorized />;
	}

	return (
		<>
			<AdminPageView id={id} organization={organization}>
				<PublisherView />
			</AdminPageView>
		</>
	);
}
