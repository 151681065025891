import firebase from "firebase/app";

import React from "react";
import { useNavigate } from "react-router-dom";

import getArticlesQuery from "astrid-firestore/src/api/articles/getArticlesQuery";

import CollectionDataTable from "../../../ui/components/CollectionDataTable/CollectionDataTable";

import hardCodedFeatureFlags from "../../../authorization/constants/hardCodedFeatureFlags";
import SyncWorkMetaDataButton from "../../../works/components/SyncWorkMetadataForm/SyncWorkMetaDataButton";

import useArticlesTableColumns from "../../hooks/useArticlesTableColumns";

import AddArticleButton from "../AddArticleForm/AddArticleButton";
import DistributeArticlesButton from "../DistributeArticles/DistributeArticlesButton";

export default function ArticlesCollectionDataTable({ publisher, imprint, serie, work, ...props }) {
	const navigate = useNavigate();

	const columns = useArticlesTableColumns();

	const distributeMultipleArticles = hardCodedFeatureFlags.distributeMultipleArticles;

	const query = getArticlesQuery(firebase, {
		publisher,
		imprint,
		serie,
		work,
	});

	return (
		<CollectionDataTable
			selectable
			query={query}
			columns={columns}
			onClick={({ id }) => navigate(id)}
			footer={({ toggleAllRowsSelected, selectedRows }) => (
				<>
					{work && <AddArticleButton primary work={work} />}

					{work && (
						<SyncWorkMetaDataButton
							work={work}
							articles={selectedRows}
							onSuccess={() => toggleAllRowsSelected(false)}
						/>
					)}

					{distributeMultipleArticles && selectedRows && (
						<DistributeArticlesButton
							articles={selectedRows}
							onSuccess={() => toggleAllRowsSelected(false)}
						/>
					)}
				</>
			)}
			{...props}
		/>
	);
}
