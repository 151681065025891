import getCollectionQuery from "../../utils/getCollectionQuery";

export default function getArticlesQuery(firebase, { publisher, production, imprint, serie, work, limit }) {
	return getCollectionQuery(firebase.firestore().collection("articles"), {
		fields: {
			publisher,
			production,
			imprint,
			serie,
			work,
		},
		limit,
		softDeletes: true,
	});
}
