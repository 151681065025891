import { Header } from "semantic-ui-react";

import React from "react";

import AdminPageHeader from "../../../../admin/components/AdminPage/AdminPageHeader";
import CoverImage from "../../../../ui/components/CoverImage/CoverImage";

import useArticleVersionMenuOptions from "../hooks/useArticleVersionMenuOptions";

export default function ArticleVersionHeader({ version }) {
	const menuOptions = useArticleVersionMenuOptions();

	const article = version.after;

	return (
		<AdminPageHeader menuOptions={menuOptions}>
			<CoverImage thumb rounded data={article} style={{ width: 80, height: 80 }} />

			<Header style={{ margin: 0 }}>{article.name}</Header>
		</AdminPageHeader>
	);
}
