import React from "react";
import { useTranslation } from "react-i18next";

import AdminCard from "../../../admin/components/AdminCard/AdminCard";
import ArticlesCollectionDataTable from "../../../articles/components/ArticlesCollectionDataTable/ArticlesCollectionDataTable";

export default function WorkArticlesView({ work }) {
	const { t } = useTranslation();

	return (
		<AdminCard transparent header={t("articles", "Articles")}>
			<ArticlesCollectionDataTable work={work} />
		</AdminCard>
	);
}
