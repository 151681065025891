import { Card } from "semantic-ui-react";

import { useTranslation } from "react-i18next";

import OrderDetails from "../../../components/OrderDetails/OrderDetails";

export default function OrderInfo({ order }) {
	const { t } = useTranslation();

	return (
		<Card fluid>
			<Card.Content>
				<Card.Header>{order.id}</Card.Header>
			</Card.Content>

			<Card.Content extra>
				{order.expense ? t("resource") : t("publisher")}

				<Card.Meta>{order.secondParty?.name}</Card.Meta>
			</Card.Content>

			{order.created?.toDate && (
				<Card.Content extra>
					{t("date", "Date")}

					<Card.Meta>{order.created.toDate().toLocaleString()}</Card.Meta>
				</Card.Content>
			)}

			<Card.Content>
				<OrderDetails order={order} />
			</Card.Content>
		</Card>
	);
}
