import { Dropdown } from "semantic-ui-react";

import { useProfile } from "../../../../authentication/state/profile";
import DropdownMenu from "../../../../ui/components/DropdownMenu/DropdownMenu";
import DropdownMenuItems from "../../../../ui/components/DropdownMenu/DropdownMenuItems";

import useSecondaryMenuOptions from "../hooks/useSecondaryMenuOptions";

export default function SecondaryMenu({ organization }) {
	const profile = useProfile();

	const options = useSecondaryMenuOptions({ organization });

	return (
		<DropdownMenu icon="bars">
			{() => (
				<>
					<Dropdown.Header>{profile?.email}</Dropdown.Header>

					<Dropdown.Divider style={{ margin: "10px 0" }} />

					<DropdownMenuItems options={options} />
				</>
			)}
		</DropdownMenu>
	);
}
