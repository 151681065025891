import createDocument from "../../utils/createDocument";
import createDocumentData from "../../utils/createDocumentData";

import { Work } from "./types/Work";

export default function createWork(firebase, data) {
	const work = Work.parse(createDocumentData(firebase, "works", data));

	return createDocument(work);
}
