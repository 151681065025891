import { Button, Message } from "semantic-ui-react";

import React from "react";
import { useTranslation } from "react-i18next";
import { useMatch, useNavigate } from "react-router-dom";

import organizationTypes from "astrid-firestore/src/api/organizations/constants/organizationTypes";

import { useProfile } from "../../../features/authentication/state/profile";

export default function VendorMissing({ organization }) {
	const { t } = useTranslation();

	const match = useMatch("/profile");
	const navigate = useNavigate();
	const profile = useProfile();

	const { id, type } = organization;

	if (type === organizationTypes.PUBLISHER || match || profile?.vendors || profile?.producers?.[id]?.employee) {
		return false;
	}

	return (
		<Message
			color="yellow"
			icon="file outline alternate"
			header={t(
				"vendorMissingMessage",
				"Please fill in your invoicing details in order to get new offers from Astrid",
			)}
			content={
				<Button
					color="green"
					content={t("goToYourAccount", "Go to your account")}
					size="small"
					style={{ marginTop: "1em" }}
					onClick={() => navigate(`/account/${profile.id}/settings`)}
				/>
			}
		/>
	);
}
