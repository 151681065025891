import { Route } from "react-router-dom";

import ForwardContext from "../../../layouts/ForwardContext";

import ArticleExports from "../../articles/pages/ArticleExports";

import Export from "../pages/Export";
import ExportPipeline from "../pages/ExportPipeline";

export const exports = (
	<>
		<Route path="exports" element={<ArticleExports />} handle={{ crumb: ({ t }) => t("exports", "Exports") }} />

		<Route path="exports" element={<ForwardContext />} handle={{ crumb: ({ t }) => t("exports", "Exports") }}>
			<Route path=":export" element={<Export />} handle={{ crumb: ({ export: theExport }) => theExport?.id }}>
				<Route index element={<ExportPipeline />} />
			</Route>
		</Route>
	</>
);
