import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import AdminCard from "../../../admin/components/AdminCard/AdminCard";

import ArticlesCollectionDataTable from "../../components/ArticlesCollectionDataTable/ArticlesCollectionDataTable";

export default function ArticleBundleView({ article }) {
	const { t } = useTranslation();

	const navigate = useNavigate();

	if (!article.work) {
		return null;
	}

	return (
		<AdminCard transparent header={t("relatedArticles", "Related articles")}>
			<ArticlesCollectionDataTable work={article.work} onClick={({ id }) => navigate(`../../${id}`)} />
		</AdminCard>
	);
}
