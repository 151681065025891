import { Header, Icon, Segment } from "semantic-ui-react";

import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import distributors from "astrid-config/src/distributors";
import { db } from "astrid-firebase";
import DocTitle from "astrid-web/src/components/DocTitle";
import { Store } from "astrid-web/src/helpers/context";

import DistributorSetup from "../components/distribution/DistributorSetup";

const DistributionOld = () => {
	const { t } = useTranslation();
	const [publishers, setPublishers] = useState({});
	const [producerSetup, setProducerSetup] = useState({});
	const [distributorSetup, setDistributorSetup] = useState([]);
	const store = useContext(Store);
	const producerId = store.state.producerId || "HfvG4FQfXWpWv6dzqM5E"; // MEMO: should support choosing producer

	useEffect(
		() =>
			db
				.collection("organizations")
				.doc(producerId)
				.onSnapshot((doc) => {
					setDistributorSetup(doc.data().distributors || []);
				}),
		[producerId],
	);

	// listen to producer distribution setup document
	useEffect(
		() =>
			db
				.collection("organizations")
				.doc(producerId)
				.collection("secrets")
				.doc("distribution")
				.onSnapshot((doc) => {
					setProducerSetup(doc.data());
				}),
		[producerId],
	);

	// get all publishers
	useEffect(() => {
		db.collection("organizations")
			.where("type", "==", "publisher")
			.get()
			.then((q) => {
				const publishers = {};
				q.forEach((doc) => {
					const pub = doc.data();
					publishers[doc.id] = pub.name;
				});

				setPublishers(publishers);
			});
	}, []);

	return (
		<>
			<DocTitle title="Distribution" />

			<Header as="h4">
				<Icon name="sitemap" />
				<Header.Content>{t("distribution")}</Header.Content>
			</Header>
			<div
				style={{
					fontStyle: "italic",
					fontSize: "small",
					whiteSpace: "pre-wrap",
				}}
			>
				{t("distributionInfo")}
			</div>

			<Segment>
				{Object.keys(distributors)
					.filter((key) => distributorSetup.includes(key))
					.map((dist) => (
						<DistributorSetup
							key={dist}
							distributor={dist}
							producerId={producerId}
							producerSetup={producerSetup}
							publishers={publishers}
						/>
					))}
			</Segment>
		</>
	);
};

export default DistributionOld;
