import PropTypes from "prop-types";

import { Dropdown, Form } from "semantic-ui-react";

import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";

Dropdown.propTypes.value = PropTypes.any;
Dropdown.Item.propTypes.value = PropTypes.any;

const scrollToTop = (e) => {
	e.target.parentNode.querySelector(".menu").scrollTop = 0;
};

export default function Select({
	value,
	multiple,
	allowEmpty,
	autoSelect,
	options = [],
	filterOptions,
	limitedOptions,
	additionalOptions,
	onChange,
	onChangeOption,
	onChangeOptions,
	getMissingOption = (option) => option,
	...props
}) {
	const { t } = useTranslation();

	// Used to map id -> text when the options no longer contains the value that was previously selected (i.e we render a search result that does not match selected values)
	const valueToTextMapping = useRef({});

	const allOptions = [
		// Add the empty option if allowed
		...(allowEmpty ? [{ key: null, value: null, text: t("none", "None") }] : []),

		// Add the additional options
		...(additionalOptions || []),

		// Filter the options
		...(limitedOptions || filterOptions
			? options.filter(
					(option) =>
						// Only show the options that are in the limited options
						(!limitedOptions || limitedOptions.includes(option.value)) &&
						// Only show the options that pass the filter
						(!filterOptions || filterOptions(option)),
			  )
			: options),
	];

	const missingValueOptions = (multiple ? value : [value]).filter(
		(value) => value && !allOptions.some((option) => option.value === value),
	);

	if (missingValueOptions.length > 0) {
		allOptions.push(
			...missingValueOptions
				.map((value) =>
					getMissingOption({
						key: value,
						text: valueToTextMapping.current[value] || value,
						value,
					}),
				)
				.filter(Boolean),
		);
	}

	const onChangeOptionRef = useRef(onChangeOption);

	onChangeOptionRef.current = onChangeOption;

	useEffect(() => {
		const option = multiple
			? allOptions.filter((option) => value?.includes(option.value))
			: allOptions.find((option) => option.value === value);

		onChangeOptionRef.current?.(option);
		// eslint-disable-next-line
	}, [value]);

	useEffect(() => {
		if (allOptions.length === 1 && autoSelect) {
			const option = allOptions[0];

			onChange(null, { value: option.value, option });
		}
		// eslint-disable-next-line
	}, [allOptions.length]);

	return (
		<Form.Select
			value={value}
			multiple={multiple}
			additionLabel={t("create", "Create") + ": "}
			options={allOptions}
			disabled={allOptions.length === 0}
			onSearchChange={scrollToTop}
			openOnFocus={false}
			selectOnBlur={false}
			onChange={(e, { value, ...data }) => {
				const values = Array.isArray(value) ? value : [value];

				// Find the options that are selected
				const foundOptions = values
					.map((value) => options.find((option) => option?.value === value))
					.filter(Boolean);

				const option = foundOptions[foundOptions.length - 1];
				if (option) {
					valueToTextMapping.current[option.value] = option.text;
				}

				onChange(e, { value, option, foundOptions, ...data });
				onChangeOptions?.({ value, options: foundOptions });
			}}
			{...props}
		/>
	);
}
