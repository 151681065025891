import { useTranslation } from "react-i18next";

import useFirebaseHttpsCallableValue from "astrid-firestore/src/hooks/useFirebaseHttpsCallableValue";

import themeColors from "../../ui/constants/themeColors";

export default function usePublisherDashboardStatistics({ organization }) {
	const { t } = useTranslation();

	const [value, loading, error] = useFirebaseHttpsCallableValue("dashboard-getPublisherDashboard", {
		id: organization.id,
	});

	const { delivered, deliveryNeeded, scheduled, drafts, notDelivered } = value?.data.statistics || {};

	const statistics = [
		{
			total: delivered?.total,
			label: t("deliveredPlural", "Delivered"),
			color: "green",
			detailColor: themeColors.GREEN,
			link: `/${organization?.type}/${organization?.id}/articles?articles%5BrefinementList%5D%5Bdelivery.metadata.status%5D%5B0%5D=delivery_completed`,
			details: [
				{
					text: t("audiobook", "Audiobook"),
					icon: "headphones",
					amount: delivered?.audiobooks,
				},
				{
					text: t("ebook", "E-book"),
					icon: "book",
					amount: delivered?.ebooks,
				},
				{
					text: t("podcast", "Podcast"),
					icon: "podcast",
					amount: delivered?.podcasts,
				},
			],
		},
		{
			total: deliveryNeeded?.total,
			label: t("delivereyNeeded", "Delivery needed"),
			color: "orange",
			detailColor: themeColors.ORANGE,
			link: `/${organization?.type}/${organization?.id}/articles?articles%5BrefinementList%5D%5Bdelivery.metadata.status%5D%5B0%5D=delivery_needed&articles%5BrefinementList%5D%5Bdelivery.metadata.status%5D%5B1%5D=delivery_failed`,
			details: [
				{
					text: t("audiobook", "Audiobook"),
					icon: "headphones",
					amount: deliveryNeeded?.audiobooks,
				},
				{
					text: t("ebook", "E-book"),
					icon: "book",
					amount: deliveryNeeded?.ebooks,
				},
				{
					text: t("podcast", "Podcast"),
					icon: "podcast",
					amount: deliveryNeeded?.podcasts,
				},
			],
		},
		{
			total: scheduled?.total,
			label: t("scheduled", "Scheduled"),
			color: "blue",
			detailColor: themeColors.BLUE,
			link: `/${organization?.type}/${organization?.id}/articles?articles%5BrefinementList%5D%5Bdelivery.metadata.status%5D%5B0%5D=delivery_pending&articles%5BrefinementList%5D%5Bdelivery.metadata.status%5D%5B1%5D=delivery_in_progress`,
			details: [
				{
					text: t("audiobook", "Audiobook"),
					icon: "headphones",
					amount: scheduled?.audiobooks,
				},
				{
					text: t("ebook", "E-book"),
					icon: "book",
					amount: scheduled?.ebooks,
				},
				{
					text: t("podcast", "Podcast"),
					icon: "podcast",
					amount: scheduled?.podcasts,
				},
			],
		},
		{
			total: drafts?.total,
			label: t("drafts", "Drafts"),
			color: "grey",
			detailColor: themeColors.GREY,
			link: `/${organization?.type}/${organization?.id}/articles?articles%5BrefinementList%5D%5Bstatus%5D%5B0%5D=draft`,
			details: [
				{
					text: t("audiobook", "Audiobook"),
					icon: "headphones",
					amount: drafts?.audiobooks,
				},
				{
					text: t("ebook", "E-book"),
					icon: "book",
					amount: drafts?.ebooks,
				},
				{
					text: t("podcast", "Podcast"),
					icon: "podcast",
					amount: drafts?.podcasts,
				},
			],
		},
		{
			total: notDelivered?.total,
			label: t("notDeliveredPlural", "Not delivered"),
			link: `/${organization?.type}/${organization?.id}/articles?articles%5BrefinementList%5D%5Bdelivery.metadata.status%5D%5B0%5D=delivery_not_started`,
			details: [
				{
					text: t("audiobook", "Audiobook"),
					icon: "headphones",
					amount: notDelivered?.audiobooks,
				},
				{
					text: t("ebook", "E-book"),
					icon: "book",
					amount: notDelivered?.ebooks,
				},
				{
					text: t("podcast", "Podcast"),
					icon: "podcast",
					amount: notDelivered?.podcasts,
				},
			],
		},
	];

	return [statistics, loading, error];
}
