import { Icon } from "semantic-ui-react";

import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import useWindowSize from "react-use/lib/useWindowSize";

import organizationTypes from "astrid-firestore/src/api/organizations/constants/organizationTypes";

import { useProfile } from "../../../../authentication/state/profile";
import useUserOrganizations from "../../../../organizations/hooks/useUserOrganizations";
import DropdownMenu from "../../../../ui/components/DropdownMenu/DropdownMenu";

export default function OrganizationMenu({ organization = {} }) {
	const { t } = useTranslation();
	const { width } = useWindowSize();

	const user = useProfile();
	const { id } = organization;

	const navigate = useNavigate();
	const location = useLocation();
	const organizations = useUserOrganizations(user);

	const large = width > 600;

	const isAccount = location.pathname.startsWith("/account");

	const options = [
		{
			text: t("myAccount", "My Account"),
			active: isAccount,
			onClick: () => navigate(`/account/${user?.id}/settings`),
		},
		{
			type: "divider",
		},
		{
			text: "Astrid",
			active: !isAccount && id === organizationTypes.ADMIN,
			hidden: !["owner", "admin"].includes(user?.permissions?.role),
			onClick: () => navigate(`/${organizationTypes.ADMIN}`),
		},
		...organizations.map((organization) => ({
			text: organization.name,
			active: !isAccount && id === organization.id,
			onClick: () => navigate(`/${organization.type}/${organization.id}`),
		})),
	];

	const activeText = options.find(({ active }) => active)?.text || organization?.name;

	return (
		<DropdownMenu
			item
			icon={<Icon size="small" name="chevron down outline" fitted style={{ paddingLeft: 5 }} />}
			direction="center"
			options={options}
			text={activeText}
			style={{ margin: 0, maxWidth: large ? 220 : 80 }}
		/>
	);
}
