import React from "react";
import { useTranslation } from "react-i18next";

import { languageName, languageNames } from "astrid-helpers/src/languages";

import CoverImage from "../../ui/components/CoverImage/CoverImage";
import truncateString from "../../ui/utils/truncateString";

export default function useWorkMetadataFields() {
	const { t } = useTranslation();

	// abridged: true,
	// additionalLanguages: true,
	// contributors: true,
	// cover: true,
	// imprint: true,
	// language: true,
	// name: true,
	// subtitle: true,
	// originalLanguage: true,
	// originalName: true,
	// serie: true,
	// season: true,
	// serieNumber: true,
	// synopsis: true,
	// tags: true,
	// themaCodes: true,

	return {
		abridged: {
			label: t("abridged", "Abridged"),
			accessor: (abridged) => (abridged ? t("yes", "Yes") : t("no", "No")),
		},
		additionalLanguages: {
			label: t("otherLanguages", "Other occuring languages"),
			accessor: (additionalLanguages) => languageNames(additionalLanguages),
		},
		contributors: {
			label: t("contributors", "Contributors"),
			accessor: (contributors) =>
				contributors.map(({ firstName, lastName }) => `${firstName} ${lastName}`).join(", "),
		},
		cover: {
			label: t("cover", "Cover"),
			accessor: (cover) => (
				<CoverImage thumb rounded data={{ cover }} style={{ marginRight: "1em", height: 45, width: 45 }} />
			),
		},
		imprint: {
			label: t("imprint", "Imprint"),
			accessor: (imprint) => imprint?.name,
		},
		language: {
			label: t("language", "Language"),
			accessor: (language) => languageName(language),
		},
		name: {
			label: t("title", "Title"),
			accessor: (name) => name,
		},
		subtitle: {
			label: t("subtitle", "Subtitle"),
			accessor: (subtitle) => subtitle,
		},
		originalLanguage: {
			label: t("originalLanguage", "Original language"),
			accessor: (originalLanguage) => languageName(originalLanguage),
		},
		originalName: {
			label: t("originalTitle", "Original title"),
			accessor: (originalName) => originalName,
		},
		serie: {
			label: t("serie", "Series"),
			accessor: (serie) => serie?.name,
		},
		season: {
			label: t("season", "Season"),
			accessor: (season) => season?.name,
		},
		serieNumber: {
			label: t("part", "Part"),
			accessor: (serieNumber) => serieNumber,
		},
		synopsis: {
			label: t("synopsis", "Synopsis"),
			accessor: (synopsis) => truncateString(synopsis, 40),
		},
		tags: {
			label: t("tags", "Tags"),
			accessor: (tags) => tags.join(", "),
		},
		themaCodes: {
			label: t("themaCodes", "Thema codes"),
			accessor: (themaCodes) => themaCodes.join(", "),
		},
	};
}
