import runBatch from "../../utils/runBatch";

import { WorkRef } from "./types/WorkRef";

export default function addArticleToWork(firebase, { work, article }) {
	return runBatch(firebase, (batch) => {
		batch.update(article.ref, {
			work: WorkRef.parse(work),
		});

		batch.update(work.ref, {
			articleIds: firebase.firestore.FieldValue.arrayUnion(article.id),
		});
	});
}
