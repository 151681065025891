import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";

import db from "astrid-firebase/src/db";

import PrimaryButton from "../../../ui/components/Buttons/PrimaryButton";
import CollectionDataTable from "../../../ui/components/CollectionDataTable/CollectionDataTable";

import AdminCard from "../../../admin/components/AdminCard/AdminCard";
import useProductionsTableColumns from "../../../productions/hooks/useProductionsTableColumns";
import { getOrganizationPath } from "../../../routing/utils/getOrganizationPath";

export default function WorkProductionsView({ work }) {
	const { t } = useTranslation();
	const navigate = useNavigate();

	const query = db.collection("productions").where("work.id", "==", work.id);

	const columns = useProductionsTableColumns();

	return (
		<AdminCard
			transparent
			header={t("productions", "Productions")}
			extra={
				<PrimaryButton as={Link} to={getOrganizationPath(`/create-production?work=${work.id}`)}>
					{t("createProduction", "Create production")}
				</PrimaryButton>
			}
		>
			<CollectionDataTable
				query={query}
				columns={columns}
				onClick={({ id }) => navigate(`/production/${id}`)}
				searchableKeys={["name"]}
				sortBy={{
					id: "name",
					desc: false,
				}}
			/>
		</AdminCard>
	);
}
