import React from "react";
import { useTranslation } from "react-i18next";

import useSearchInput from "../../../ui/hooks/useSearchInput";
import PageView from "../../../ui/views/PageView/PageView";

import CreateSerieButton from "../../components/CreateSerieForm/CreateSerieButton";
import SeriesInstantSearchDataTable from "../../components/SeriesInstantSearchDataTable/SeriesInstantSearchDataTable";

export default function SeriesView({ organization }) {
	const { t } = useTranslation();

	const [searchInput, searchQuery] = useSearchInput();

	return (
		<PageView
			header={t("serie", "Series")}
			extra={
				<>
					{searchInput}

					<CreateSerieButton organization={organization} />
				</>
			}
		>
			<SeriesInstantSearchDataTable publisher={organization} searchQuery={searchQuery} />
		</PageView>
	);
}
