import { Divider, Segment, Statistic } from "semantic-ui-react";

import React from "react";

import arrayChunk from "astrid-helpers/src/arrayChunk";

import Menu from "../../../ui/components/Menu/Menu";

import DashboardStatistic from "../DashboardStatistic/DashboardStatistic";

export default function Dashboard({ menuOptions, statistics, children }) {
	const groups = arrayChunk(statistics, statistics.length > 5 ? statistics.length / 2 : statistics.length);

	return (
		<>
			{menuOptions && (
				<>
					<Menu secondary options={menuOptions} style={{ margin: "0 -0.35714286em" }} />

					<Divider />
				</>
			)}

			<Segment basic padded>
				{groups.map((statistics, index) => {
					return (
						<Statistic.Group
							key={index}
							widths={statistics.length}
							style={{ marginTop: index > 0 ? 40 : undefined, justifyContent: "center" }}
						>
							{statistics.map(
								({ total, details, color, detailColor, label, link, loading, organization }) => (
									<DashboardStatistic
										key={label}
										total={total}
										details={details}
										color={color}
										detailColor={detailColor}
										label={label}
										link={link}
										organization={organization}
										loading={loading}
									/>
								),
							)}
						</Statistic.Group>
					);
				})}
			</Segment>

			{children}
		</>
	);
}
