import { useState } from "react";

import DataTable from "../../../../ui/components/DataTable/DataTable";

import ArticleDistributionFooter from "../../../components/ArticleDistributionChannels/components/ArticleDistributionFooter";
import useArticleDistributionChannelsTableColumns from "../../../components/ArticleDistributionChannels/hooks/useArticleDistributionChannelsTableColumns";

import ArticleDistributionChannelForm from "./ArticleDistributionChannelForm";

export default function ArticleDistributionChannels({ presetChannels, articleChannels, publisher, article, ...props }) {
	const [selectedChannel, setSelectedChannel] = useState(null);

	const presetChannelIds = presetChannels.map(({ id }) => id);

	const columns = useArticleDistributionChannelsTableColumns({ article, presetChannels, setSelectedChannel });

	return (
		<>
			<DataTable
				selectable={({ id }) => presetChannelIds.includes(id)}
				onClick={(channel) => {
					setSelectedChannel(channel);
				}}
				data={articleChannels}
				allowEmpty
				columns={columns}
				sortBy={{
					id: "name",
					desc: false,
				}}
				searchableKeys={["name"]}
				footer={<ArticleDistributionFooter article={article} channels={articleChannels} />}
				{...props}
			/>

			{selectedChannel && (
				<ArticleDistributionChannelForm
					channel={selectedChannel}
					article={article}
					publisher={publisher}
					onClose={() => setSelectedChannel(null)}
				/>
			)}
		</>
	);
}
