import { Button } from "semantic-ui-react";

import { useState } from "react";
import { useTranslation } from "react-i18next";

import UserPriceForm from "./UserPriceForm";

export default function UserPricesActions(props) {
	const { t } = useTranslation();

	const [open, setOpen] = useState(false);

	return (
		<>
			<Button onClick={() => setOpen(true)}>{t("addDefaultPrice", "Add default price")}</Button>

			{open && <UserPriceForm {...props} onClose={() => setOpen(false)} />}
		</>
	);
}
