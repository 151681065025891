import { useParams } from "react-router-dom";

import Unauthorized from "../../ui/components/Unauthorized/Unauthorized";

import useHasRight from "../../authorization/hooks/useHasRight";

import OfferView from "../views/OfferView/OfferView";

export default function Offer() {
	const { id } = useParams();

	const hasRight = useHasRight();

	if (!hasRight("offers.view")) {
		return <Unauthorized />;
	}

	return <OfferView id={id} />;
}
