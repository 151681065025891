import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import money from "astrid-helpers/src/money";

export default function useOrdersTableColumns() {
	const { t } = useTranslation();

	return useMemo(
		() => [
			{
				id: "created",
				Header: t("created", "Created"),
				accessor: ({ created }) => created?.toDate().getTime() || "",
				Cell: ({ value }) => new Date(value).toLocaleString(),
				collapsing: true,
			},
			{
				id: "id",
				filter: "text",
				Header: t("id", "ID"),
				collapsing: true,
			},
			{
				id: "secondParty.name",
				filter: "text",
				Header: t("secondParty", "Second party"),
				collapsing: true,
			},
			{
				id: "total",
				Header: t("totalExclVAT", "Total excl. VAT"),
				accessor: ({ total, currency }) => money(total, currency),
				collapsing: false,
			},
			{
				id: "processed",
				filter: "select",
				Header: t("processed"),
				accessor: ({ processed }) => (processed ? t("processed") : t("unprocessed")),
				collapsing: true,
			},
		],
		[t],
	);
}
