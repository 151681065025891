import { updateDocument } from "astrid-firestore/src/helpers";
import { getAgreementRef, getPriceRef } from "astrid-firestore/src/utils/referenceMappers";

export default async function submitUserPrice({ role, user, price, language, agreement, organization }) {
	const key = `prices.${organization.id}.${language}-${role}`;

	await updateDocument(user.ref, {
		[`${key}.language`]: language,
		[`${key}.role`]: role,
		[`${key}.price`]: getPriceRef(price),
		[`${key}.agreement`]: getAgreementRef(agreement),
	});
}
