import { Route } from "react-router-dom";

import organizationTypes from "astrid-firestore/src/api/organizations/constants/organizationTypes";

import ListProductions from "../pages/ListProductions";

export const listProductionsPublisher = (
	<Route path="list-productions" element={<ListProductions type={organizationTypes.PUBLISHER} />} />
);

export const listProductionsProducer = (
	<Route path="list-productions" element={<ListProductions type={organizationTypes.PRODUCER} />} />
);

export const listProductionsAdmin = (
	<Route path="list-productions" element={<ListProductions type={organizationTypes.ADMIN} />} />
);
