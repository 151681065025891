import db from "astrid-firebase/src/db";
import useDocumentData from "astrid-firestore/src/hooks/useDocumentData";

import LoadingContext from "../../../ui/components/LoadingContext/LoadingContext";

import PublisherAgreements from "./components/PublisherAgreements";
import PublisherInfo from "./components/PublisherInfo";
import PublisherPrices from "./components/PublisherPrices";
import PublisherUsers from "./components/PublisherUsers";

export default function PublisherView({ id, organization }) {
	const [publisher, loading, error] = useDocumentData(id && db.collection("organizations").doc(id));

	return (
		<LoadingContext loading={loading} error={error} data={publisher}>
			{publisher && (
				<>
					<PublisherInfo publisher={publisher} />

					<PublisherPrices publisher={publisher} organization={organization} />

					<PublisherAgreements publisher={publisher} organization={organization} />

					<PublisherUsers publisher={publisher} />
				</>
			)}
		</LoadingContext>
	);
}
