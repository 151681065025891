import { useEffect } from "react";
import { useLocation } from "react-router-dom";

import db from "astrid-firebase/src/db";
import useDocumentData from "astrid-firestore/src/hooks/useDocumentData";

import { localStorage } from "../../../helpers/fnc";

import { useProfile } from "../../authentication/state/profile";

import { useOrganizationId } from "../state/organizationId";
import { useOrganizationType } from "../state/organizationType";
import getLocationOrganization from "../utils/getLocationOrganization";

import useSelectOrganization from "./useSelectOrganization";

export default function useCurrentOrganization() {
	const user = useProfile();
	const location = useLocation();
	const selectOrganization = useSelectOrganization();

	const localOrganization = localStorage("organizations", user?.id);

	const { id, type } = getLocationOrganization(location, {
		id: useOrganizationId() || localOrganization?.id,
		type: useOrganizationType() || localOrganization?.type,
	});

	useEffect(() => {
		if (id && type) {
			selectOrganization({ id, type });
		}
	}, [id, selectOrganization, type]);

	const [document, loading, error] = useDocumentData(id && db.collection("organizations").doc(id));

	const organization = document?.id === id ? document : undefined;

	return [organization || { id, type }, loading, error];
}
