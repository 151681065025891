import { useOutletContext } from "react-router-dom";

import DistributionPipelineView from "../views/DistributionView/DistributionPipelineView";

export default function DistributionPipeline() {
	const { distribution } = useOutletContext();

	if (!distribution.pipeline) {
		return null;
	}

	return <DistributionPipelineView distribution={distribution} />;
}
