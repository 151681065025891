import { Route } from "react-router-dom";

import Account from "../pages/Account";
import AccountAgreements from "../pages/AccountAgreements";
import AccountOrders from "../pages/AccountOrders";
import AccountRevenues from "../pages/AccountRevenues";
import AccountSettings from "../pages/AccountSettings";

export const account = (
	<Route path="account/:id" element={<Account />}>
		<Route path="agreements" element={<AccountAgreements />} />

		<Route path="revenues" element={<AccountRevenues />} />

		<Route path="orders" element={<AccountOrders />} />

		<Route path="settings" element={<AccountSettings />} />
	</Route>
);
