import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

export default function useWorkMenuOptions({ work }) {
	const { t } = useTranslation();

	const location = useLocation();

	const { pathname } = location;

	// const [artifactsCount, artifactsCountLoading] = api.artifacts.useCount({ article });

	// const [productionsCount, productionsCountLoading] = api.productions.useCount((query) =>
	//     query.where("articleIds", "array-contains", article.id),
	// );

	return [
		{
			to: "",
			active: pathname.endsWith(work.id),
			text: t("metaData", "Meta data"),
		},
		{
			to: "articles",
			active: pathname.endsWith("/articles"),
			text: t("articles", "Articles"),
			// label: artifactsCount || article.artifactIds?.length,
			// loading: artifactsCountLoading,
		},
		{
			to: "artifacts",
			active: pathname.endsWith("/artifacts"),
			text: t("files", "Files"),
			// label: artifactsCount || article.artifactIds?.length,
			// loading: artifactsCountLoading,
		},
		{
			to: "productions",
			active: pathname.endsWith("/productions"),
			text: t("productions", "Productions"),
			// label: productionsCount || undefined,
			// loading: productionsCountLoading,
		},
	];
}
