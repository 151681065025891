import getCollectionDataByArray from "../../src/utils/getCollectionDataByArray";
import getDocumentData from "../../src/utils/getDocumentData";

function hasNoOtherArticlesReferenced(articleIds, comparisonIds) {
	if (articleIds.length !== comparisonIds.length) {
		return false;
	}

	const first = new Set(articleIds);
	const second = new Set(comparisonIds);

	for (const item of first) {
		if (!second.has(item)) {
			return false;
		}
	}
	return true;
}

export default async function revertMigratedProduction({ api, env }, { production }) {
	const documents = production.migrations?.flattening?.createdDocuments;

	const work = documents.find((doc) => doc.collection === "works");
	const bundle = documents.find((doc) => doc.collection === "articleBundles");
	const imprint = documents.find((doc) => doc.collection === "imprints");
	const series = documents.find((doc) => doc.collection === "series");
	const articles = documents.filter((doc) => doc.collection === "articles");

	const actualWork = work && (await getDocumentData(api[env].db.collection(work.collection).doc(work.id)));
	const actualBundle = bundle && (await getDocumentData(api[env].db.collection(bundle.collection).doc(bundle.id)));
	const actualImprint =
		imprint && (await getDocumentData(api[env].db.collection(imprint.collection).doc(imprint.id)));
	const actualSeries = series && (await getDocumentData(api[env].db.collection(series.collection).doc(series.id)));

	const articleIds = articles.map((article) => article.id);
	const deleteWork = work && hasNoOtherArticlesReferenced(articleIds, actualWork.articleIds) && work;
	const deleteBundle = bundle && hasNoOtherArticlesReferenced(articleIds, actualBundle.articleIds) && bundle;
	const deleteImprint = imprint && hasNoOtherArticlesReferenced(articleIds, actualImprint.articleIds) && imprint;
	const deleteSeries = series && hasNoOtherArticlesReferenced(articleIds, actualSeries.articleIds) && series;

	const createdExports = await getCollectionDataByArray(
		api[env].db,
		api[env].db.collection("exports"),
		"article.id",
		articles.map((article) => article.id),
	);

	const createdDistributions = await getCollectionDataByArray(
		api[env].db,
		api[env].db.collection("distributions"),
		"article.id",
		articles.map((article) => article.id),
	);

	const createdArtifacts = await getCollectionDataByArray(
		api[env].db,
		api[env].db.collection("artifacts"),
		"article.id",
		articles.map((article) => article.id),
	);

	const exports = createdExports?.map((exportDoc) => ({ ...exportDoc, collection: "exports" })) || [];
	const distributions =
		createdDistributions?.map((distributionDoc) => ({ ...distributionDoc, collection: "distributions" })) || [];
	const artifacts = createdArtifacts?.map((artifactDoc) => ({ ...artifactDoc, collection: "artifacts" })) || [];

	const documentsToDelete = [
		deleteWork,
		deleteBundle,
		deleteImprint,
		deleteSeries,
		...artifacts,
		...articles,
		...exports,
		...distributions,
	].filter(Boolean);

	await api[env].db.runTransaction(async (transaction) => {
		documentsToDelete.forEach((doc) => {
			const docRef = api[env].db.collection(doc.collection).doc(doc.id);
			transaction.delete(docRef);
		});

		transaction.update(api[env].db.collection("productions").doc(production.id), {
			work: null,
			articleIds: [],
			["migrations.flattening"]: null,
		});
	});

	return documentsToDelete;
}
