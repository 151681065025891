import { useOutletContext } from "react-router-dom";

import Unauthorized from "../../ui/components/Unauthorized/Unauthorized";

import featureFlags from "../../authorization/constants/featureFlags";
import useFeatureFlag from "../../authorization/hooks/useFeatureFlag";

import ImprintsView from "../views/ImprintsView/ImprintsView";

export default function Imprints() {
	const { organization } = useOutletContext();

	const hasFlatteningFeature = useFeatureFlag(featureFlags.FLATTENING);

	if (!hasFlatteningFeature) {
		return <Unauthorized />;
	}

	return <ImprintsView organization={organization} />;
}
