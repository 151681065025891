import { useMemo } from "react";

import organizationTypes from "astrid-firestore/src/api/organizations/constants/organizationTypes";

export default function useUserOrganizations(user) {
	return useMemo(() => {
		const organizations = [];

		if (user?.organizations) {
			for (const [id, organization] of Object.entries(user.organizations)) {
				organizations.push({
					id,
					...organization,
				});
			}
		}

		if (user?.permissions?.reader?.length) {
			organizations.push({
				id: user.id,
				type: organizationTypes.NARRATOR,
				name: `${user.firstName} ${user.lastName}`,
			});
		}

		return organizations;
	}, [user]);
}
