import { useTranslation } from "react-i18next";

import { getOrganizationPath } from "../../routing/utils/getOrganizationPath";

export default function useArticleDropdownMenuOptions() {
	const { t } = useTranslation();

	return (article) => [
		{
			text: t("view", "View"),
			linkTo: getOrganizationPath(`/articles/${article.id}`),
		},
	];
}
