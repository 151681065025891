import runTransaction from "../../utils/runTransaction";
import updateDocumentData from "../../utils/updateDocumentData";

import getArticleDeliveryNeededData from "../articles/data/getArticleDeliveryNeededData";

import { Artifact } from "./types/Artifact";

export default function updateArtifact(firebase, data) {
	return runTransaction(firebase, [data.article?.ref], (transaction, [article]) => {
		if (data.files) {
			data.size = data.files.reduce((sum, file) => sum + (file.size || 0), 0);
		}
		transaction.set(data.ref, Artifact.partial().parse(updateDocumentData(firebase, data)));

		if (article) {
			const duration = data?.files?.reduce((acc, file) => acc + (file.duration || 0), 0);
			transaction.update(article.ref, { duration });
			const deliveryUpdates = getArticleDeliveryNeededData(article, { duration }, true);
			if (deliveryUpdates) {
				transaction.update(article.ref, deliveryUpdates);
			}
		}
	});
}
