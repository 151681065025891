import AdminPageView from "../../../admin/components/AdminPage/AdminPageView";

import ArticleVersionHeader from "./components/ArticleVersionHeader";

export default function ArticleVersionView(props) {
	return (
		<AdminPageView {...props}>
			<ArticleVersionHeader />
		</AdminPageView>
	);
}
