import { Route } from "react-router-dom";

import ForwardContext from "../../../layouts/ForwardContext";

import CreatePrices from "../pages/CreatePrices";
import Price from "../pages/Price";
import Prices from "../pages/Prices";

export const prices = (
	<Route path="prices" element={<ForwardContext />} handle={{ crumb: ({ t }) => t("globalPrices", "Global prices") }}>
		<Route index element={<Prices />} />
		<Route path=":id" element={<Price />} handle={{ crumb: ({ id }) => id }} />
		<Route path="create" element={<CreatePrices />} />
	</Route>
);
