import api from "../../../api";
import Unauthorized from "../../ui/components/Unauthorized/Unauthorized";

import featureFlags from "../../authorization/constants/featureFlags";
import useFeatureFlag from "../../authorization/hooks/useFeatureFlag";
import FirestoreDocumentContext from "../../firebase/components/FirestoreDocumentContext";

import ExportView from "../views/ExportView/ExportView";

export default function Export() {
	const hasFlatteningFeature = useFeatureFlag(featureFlags.FLATTENING);

	if (!hasFlatteningFeature) {
		return <Unauthorized />;
	}

	return (
		<FirestoreDocumentContext param="export" api={api.exports}>
			<ExportView />
		</FirestoreDocumentContext>
	);
}
