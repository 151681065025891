import createDocumentData from "../../utils/createDocumentData";
import runBatch from "../../utils/runBatch";

import { WorkMetaData } from "../works/types/WorkMetaData";

import applyArticlePresets from "./applyArticlePresets";
import validateArticleData from "./validateArticleData";

export default async function createArticle(firebase, data) {
	if (data.work) {
		const metadata = WorkMetaData.parse(data.work);

		data = { ...metadata, ...data };
	}

	const articleWithPresets = await applyArticlePresets(firebase, { article: { ...data }, publisher: data.publisher });

	const article = await validateArticleData(createDocumentData(firebase, "articles", articleWithPresets));

	await runBatch(firebase, (batch) => {
		batch.set(article.ref, article);

		if (article.work) {
			batch.update(article.work.ref, {
				articleIds: firebase.firestore.FieldValue.arrayUnion(article.id),
			});
		}
	});

	return article;
}
