import { Image } from "semantic-ui-react";

import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import useOrganizationRoleTranslations from "../../../hooks/useOrganizationRoleTranslations";

export default function usePublisherUsersTableColumns(publisher) {
	const { t } = useTranslation();

	const organizationRolesTranslations = useOrganizationRoleTranslations();

	return useMemo(
		() => [
			{
				id: "name",
				filter: "text",
				Header: t("name"),
				accessor: ({ firstName, lastName }) => `${firstName} ${lastName}`,
				Cell: ({ row, value }) => (
					<>
						<Image
							src={row.original.img || "/img/avatar.png"}
							avatar
							size="mini"
							style={{ marginRight: "1em" }}
						/>
						<span>{value}</span>
					</>
				),
			},
			{
				id: "email",
				filter: "text",
				Header: t("email"),
			},
			{
				id: "roles",
				Header: t("roles", "Roles"),
				accessor: ({ permissions }) =>
					(permissions?.publisher?.[publisher.id] || [])
						.filter((role) => role !== "any")
						.map((role) => organizationRolesTranslations[role])
						.join(", "),
			},
		],
		[organizationRolesTranslations, publisher.id, t],
	);
}
