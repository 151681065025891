import { useTranslation } from "react-i18next";

import useSearchInput from "../../../ui/hooks/useSearchInput";

import AdminHeader from "../../../admin/components/AdminHeader/AdminHeader";

import ArtifactsInstantSearchDataTable from "../../components/ArtifactsInstantSearchDataTable/ArtifactsInstantSearchDataTable";
import CreateArtifactButton from "../../components/CreateArtifactForm/CreateArtifactButton";

export default function ArtifactsView({ organization }) {
	const { t } = useTranslation();

	const [searchInput, searchQuery] = useSearchInput();

	return (
		<>
			<AdminHeader text={t("files", "Files")}>
				{searchInput}

				<CreateArtifactButton basic secondary publisher={organization} />
			</AdminHeader>

			<ArtifactsInstantSearchDataTable searchQuery={searchQuery} organization={organization} />
		</>
	);
}
