import { useMemo } from "react";
import { useTranslation } from "react-i18next";

export default function useOrganizationsTableColumns() {
	const { t } = useTranslation();

	return useMemo(
		() => [
			{
				id: "name",
				filter: "text",
				Header: t("name"),
				collapsing: true,
			},
		],
		[t],
	);
}
