import DropdownMenu from "../components/DropdownMenu/DropdownMenu";

export function dropdownMenuColumn(getDropdownMenuOptions, ...props) {
	return {
		id: "menu",
		sticky: true,
		collapsing: true,
		disableSortBy: true,
		onClick: () => {
			// Just so that the row click is disabled
		},
		Cell: ({ row, rows }) => {
			const upward = row.index > rows.length / 2;

			return (
				<DropdownMenu
					upward={upward}
					direction={false}
					pointing="right"
					options={getDropdownMenuOptions(row.original)}
				/>
			);
		},
		...props,
	};
}

export function timestampColumn({ id, Header, time = true, ...props }) {
	return {
		id,
		Header,
		noWrap: true,
		accessor: (data) => data[id]?.toDate?.().getTime() || "",
		Cell: ({ value }) =>
			value ? (time ? new Date(value).toLocaleString() : new Date(value).toLocaleDateString()) : null,
		...props,
	};
}
