import { useTranslation } from "react-i18next";

import OpenButton from "../../../ui/components/OpenButton/OpenButton";

import SyncWorkMetadataForm from "./SyncWorkMetadataForm";

export default function SyncWorkMetaDataButton({ work, text, articles, onSuccess, ...props }) {
	const { t } = useTranslation();

	text = text || t("syncMetadata", "Sync metadata");

	return (
		<OpenButton text={text} disabled={articles.length === 0} {...props}>
			{({ setOpen }) => (
				<SyncWorkMetadataForm
					text={text}
					work={work}
					articles={articles}
					onSuccess={onSuccess}
					onClose={() => setOpen(false)}
				/>
			)}
		</OpenButton>
	);
}
