import { useTranslation } from "react-i18next";

import OpenButton from "../../../ui/components/OpenButton/OpenButton";

import CreateWorkForm from "./CreateWorkForm";

export default function CreateWorkButton({ publisher, ...props }) {
	const { t } = useTranslation();

	return (
		<OpenButton text={t("createWork", "Create work")} primary {...props}>
			{({ setOpen }) => <CreateWorkForm publisher={publisher} onClose={() => setOpen(false)} />}
		</OpenButton>
	);
}
