import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { firebase } from "astrid-firebase";
import { createImprintData } from "astrid-firestore/src/api/imprints/createImprintData";
import { createSerieData } from "astrid-firestore/src/api/series/createSerieData";
import { Season } from "astrid-firestore/src/api/series/types/Season";
import { Work } from "astrid-firestore/src/api/works/types/Work";
import updateWork from "astrid-firestore/src/api/works/updateWork";

import api from "../../../../../api";
import AdminForm from "../../../../admin/components/AdminForm/AdminForm";
import useArticleAbridgedOptions from "../../../../articles/hooks/useArticleAbridgedOptions";
import useArticleContributorRolesOptions from "../../../../articles/hooks/useArticleContributorRolesOptions";
import useSeasonOptions from "../../../../articles/hooks/useSeasonOptions";
import Form from "../../../../forms/components/Form/Form";
import useSimpleForm from "../../../../forms/hooks/useSimpleForm";
import ThemaModal from "../../../../thema/components/ThemaModal";
import useNumberOptions from "../../../../ui/hooks/useNumberOptions";

import WorkTags from "../../../components/Worktags/WorkTags";

export default function WorkMetadataForm({ work, locked, ...props }) {
	const { t } = useTranslation();

	const { publisher } = work;

	const numberOptions = useNumberOptions();

	const abridgedOptions = useArticleAbridgedOptions();

	const contributorRolesOptions = useArticleContributorRolesOptions();

	const [seriesOptions] = api.series.useOptions({ publisher });
	const [imprintOptions] = api.imprints.useOptions({ publisher });

	const [openModal, setOpenModal] = useState(false);

	const form = useSimpleForm({
		schema: Work,
		values: {
			ref: work.ref,
			abridged: work.abridged || false,
			additionalLanguages: work.additionalLanguages || null,
			contributors: work.contributors || [],
			imprint: work.imprint || null,
			language: work.language || null,
			name: work.name || null,
			subtitle: work.subtitle || null,
			originalLanguage: work.originalLanguage || null,
			originalName: work.originalName || null,
			serie: work.serie || null,
			season: work.season || null,
			serieNumber: work.serieNumber || null,
			synopsis: work.synopsis || null,
			tags: work.tags || [],
			themaCodes: work.themaCodes || null,
		},
		onSubmit: (data) => {
			return updateWork(firebase, data);
		},
		...props,
	});

	const { watch, setValue } = form;

	const [themaCodes, language] = watch(["themaCodes", "language"]);

	const serie = watch("serie");

	const seasonOptions = useSeasonOptions({ serie, seriesOptions });

	return (
		<AdminForm header={t("metaData", "Meta data")} {...form}>
			<Form.Group widths="equal">
				<Form.Input name="name" label={t("title", "Title")} />
				<Form.Input name="subtitle" label={t("subtitle", "Subtitle")} />
				<Form.Input name="originalName" label={t("originalTitle", "Original title")} />
			</Form.Group>

			<Form.Group widths="equal">
				<Form.DocumentSelect
					name="imprint"
					label={t("imprint", "Imprint")}
					search
					allowEmpty
					allowAdditions
					options={imprintOptions}
					onAddDocument={(name) => {
						return createImprintData(firebase, { name, publisher });
					}}
				/>

				<Form.Select name="abridged" label={t("abridged", "Abridged")} options={abridgedOptions} />
			</Form.Group>

			<Form.TextArea name="synopsis" label={t("synopsis", "Description")} />

			<Form.Group widths="equal">
				<Form.DocumentSelect
					name="serie"
					label={t("serie", "Series")}
					search
					allowEmpty
					allowAdditions
					options={seriesOptions}
					onChangeOption={() => {
						setValue("season", null);
					}}
					onAddDocument={(name) => {
						return createSerieData(firebase, { name, publisher });
					}}
				/>

				<Form.DocumentSelect
					name="season"
					label={t("season", "Season")}
					disabled={!serie}
					search
					allowEmpty
					allowAdditions
					options={seasonOptions}
					onAddDocument={(name) => {
						return Season.parse({ name });
					}}
				/>

				<Form.Select
					name="serieNumber"
					disabled={!serie}
					search
					label={t("part", "Part")}
					allowEmpty
					options={numberOptions}
				/>
			</Form.Group>

			<Form.Group widths="equal">
				<Form.LanguageSelect name="language" label={t("language", "Language")} clearable />

				<Form.LanguageSelect
					name="originalLanguage"
					label={t("originalLanguage", "Original language")}
					clearable
				/>

				<Form.LanguageSelect
					name="additionalLanguages"
					label={t("otherLanguages", "Other occuring languages")}
					filterOptions={(option) => option.value !== language}
					multiple
					clearable
				/>
			</Form.Group>

			<Form.Group widths="equal">
				<Form.Select
					multiple
					name="themaCodes"
					label={t("themaCodes", "Thema codes")}
					options={themaCodes?.map((code) => ({
						key: code,
						text: code,
						value: code,
					}))}
					allowEmpty
					onClick={() => !locked && setOpenModal(true)}
				/>

				<Form.Field name="tags">
					<WorkTags work={work} />
				</Form.Field>
			</Form.Group>

			<Form.FormFieldArrayTable name="contributors" header={t("contributors", "Contributors")}>
				{({ role, firstName, lastName }, index) => (
					<Form.Group widths="equal" style={{ margin: 0 }}>
						<Form.Select
							name={`contributors.${index}.role`}
							value={role}
							placeholder={t("role", "Role")}
							options={contributorRolesOptions}
						/>
						<Form.Input
							name={`contributors.${index}.firstName`}
							value={firstName}
							placeholder={t("firstName", "First name")}
						/>
						<Form.Input
							name={`contributors.${index}.lastName`}
							value={lastName}
							placeholder={t("lastName", "Last name")}
						/>
					</Form.Group>
				)}
			</Form.FormFieldArrayTable>

			{openModal && (
				<ThemaModal
					value={themaCodes}
					onClose={({ themaCodes }) => {
						setValue("themaCodes", themaCodes, { shouldDirty: true });
						setOpenModal(false);
					}}
				/>
			)}

			<Form.Blocker />
		</AdminForm>
	);
}
