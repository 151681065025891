import { Route } from "react-router-dom";

import ForwardContext from "../../../layouts/ForwardContext";

import Offer from "../pages/Offer";

export const offers = (
	<Route path="offers" element={<ForwardContext />}>
		<Route path=":id" element={<Offer />} />
	</Route>
);
