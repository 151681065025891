import { Card, Label } from "semantic-ui-react";

import { useTranslation } from "react-i18next";

import useCostTypes from "../../../../costs/hooks/useCostTypes";
import TeamPrice from "../../../../team/components/TeamPrice/TeamPrice";

export default function OfferCard({ offer }) {
	const { t } = useTranslation();

	const costTypes = useCostTypes();

	return (
		<Card fluid>
			<Card.Content>
				<Card.Header>{offer.name}</Card.Header>

				<Card.Description>
					{costTypes[offer.type]}{" "}
					{offer.employee && <Label pointing="left">{t("employee", "Employee")}</Label>}
				</Card.Description>
			</Card.Content>

			<Card.Content>
				{t("price", "Price")}

				<Card.Meta>
					<TeamPrice {...offer} />
				</Card.Meta>
			</Card.Content>

			<Card.Content>
				{t("currency", "Currency")}

				<Card.Meta>{offer.currency}</Card.Meta>
			</Card.Content>

			<Card.Content>
				{t("VAT", "VAT")}

				<Card.Meta>{offer.vat}%</Card.Meta>
			</Card.Content>
		</Card>
	);
}
