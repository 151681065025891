import { StorageFile } from "../../../types/StorageFile";
import { defaultObject, enumValues, nullable, timestamp } from "../../../types/_types";

import { DistributionRef } from "../../distribution/types/DistributionRef";

import articleDeliveryStatuses from "../constants/articleDeliveryStatuses";

export const articleDeliveryStatus = enumValues(articleDeliveryStatuses).default(
	articleDeliveryStatuses.DELIVERY_NOT_STARTED,
);
export const ArticleDelivery = defaultObject({
	status: articleDeliveryStatus,
	artifact: defaultObject({
		status: articleDeliveryStatus,
		sent: nullable(timestamp()),
		distribution: nullable(DistributionRef),
	}),
	metadata: defaultObject({
		status: articleDeliveryStatus,
		sent: nullable(timestamp()),
		distribution: nullable(DistributionRef),
	}),
	zip: nullable(StorageFile),
});
