import { useNavigate } from "react-router-dom";

import CollectionDataTable from "../../../ui/components/CollectionDataTable/CollectionDataTable";

import AdminHeader from "../../../admin/components/AdminHeader/AdminHeader";

import CostsActions from "../../components/CostsActions/CostsActions";
import useCostsQuery from "../../hooks/useCostsQuery";

import useAdminCostsColumns from "./hooks/useCostsTableColumns";

export default function CostsView({ header, expense, orderId, processed = false, firstPartyId, ...props }) {
	const navigate = useNavigate();

	const query = useCostsQuery({
		expense,
		orderId,
		processed,
		firstPartyId,
	});

	const columns = useAdminCostsColumns();

	return (
		<>
			<AdminHeader>{header}</AdminHeader>

			<CollectionDataTable
				persist
				selectable
				query={query.limit(999)}
				columns={columns}
				onClick={({ id }) => navigate(id)}
				sortBy={{
					id: "created",
					desc: true,
				}}
				footer={<CostsActions expense={expense} />}
				{...props}
			/>
		</>
	);
}
