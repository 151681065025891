import equal from "fast-deep-equal";

import { ArticleMetaData } from "../types/ArticleMetaData";

export default function getUpdatedArticleDeliveryTypes(article, data, artifactIsUpdated) {
	const deliveryTypes = [];

	const metaDataBefore = ArticleMetaData.partial().parse(article);
	const metaDataAfter = ArticleMetaData.partial().parse(data);

	const updatedKeys = Object.keys(metaDataAfter || {}).filter(
		(key) => !equal(metaDataBefore[key], metaDataAfter[key]),
	);

	if (updatedKeys.length > 0) {
		deliveryTypes.push("metadata");
	}

	if (artifactIsUpdated || (typeof data.artifact !== "undefined" && article.artifact?.id !== data.artifact?.id)) {
		deliveryTypes.push("artifact");
	}

	return { deliveryTypes, updatedKeys };
}
