import React, { useContext } from "react";
import { useOutletContext } from "react-router-dom";

import ProductionTab from "../../../../components/production/parts/ProductionTab";

import ProductionViewContext from "../../views/ProductionView/context/ProductionViewContext";

export default function ProductionAudiobook({ tab }) {
	const outletContext = useOutletContext();
	const context = useContext(ProductionViewContext);

	return (
		<ProductionTab
			{...outletContext}
			{...context}
			match={{
				params: {
					tab,
					productionId: outletContext.production.id,
				},
			}}
		/>
	);
}
