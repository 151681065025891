import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useOutletContext } from "react-router-dom";

import { Studio } from "astrid-firestore/src/api/studios/types/Studio";

import api from "../../../../../api";
import AdminForm from "../../../../admin/components/AdminForm/AdminForm";
import Form from "../../../../forms/components/Form/Form";
import useSimpleForm from "../../../../forms/hooks/useSimpleForm";

import useStudioGroupTagOptions from "../../../hooks/useStudioGroupTagOptions";

export default function StudioDataForm({ studio }) {
	const { t } = useTranslation();
	const { organization } = useOutletContext();

	const { form, watch, onSubmit } = useSimpleForm({
		schema: Studio,
		defaultValues: {
			ref: studio.ref,
			name: studio.name || "",
			color: studio.color || "",
			active: studio.active || false,
			address: studio.address || "",
			groupTags: studio.groupTags || [],
		},
		onSubmit: (data) => {
			return api.studios.update(data);
		},
	});

	const { group, groupTags } = watch();

	const localGroupTags = useMemo(() => [...groupTags, group].filter((t) => t), [groupTags, group]);

	const groupTagOptions = useStudioGroupTagOptions(organization.id, localGroupTags);

	return (
		<AdminForm header={t("studioData", "Studio data")} form={form} onSubmit={onSubmit}>
			<Form.Group widths="equal">
				<Form.Input name="name" label={t("name")} />
				<Form.Input name="address" label={t("address")} />
			</Form.Group>

			<Form.Group widths="equal">
				<Form.Select
					name="groupTags"
					label={t("groupTagsForBooking")}
					search
					options={groupTagOptions}
					multiple
					placeholder={t("studioGroup")}
					additionLabel={t("createGroupLabel")}
					allowAdditions
					rules={{ required: false }}
					noResultsMessage={t("groupIsMissing")}
				/>

				<div className="field">
					<label>{t("calendarColor")}</label>

					<Form.Input name="color" type="color">
						<input style={{ width: 30, padding: 0, flex: 1 }} />
					</Form.Input>
				</div>
			</Form.Group>

			<Form.Checkbox name="active" slider label={t("studioIsActive")} rules={{ required: false }} />
		</AdminForm>
	);
}
