import { Navigate, useOutletContext } from "react-router-dom";

import organizationTypes from "astrid-firestore/src/api/organizations/constants/organizationTypes";

import NotFound from "../../../components/NotFound";

import { useProfile } from "../../authentication/state/profile";
import useUserOrganizations from "../../organizations/hooks/useUserOrganizations";

export default function HomeRedirect() {
	const user = useProfile();
	const organizations = useUserOrganizations(user);

	const { organization } = useOutletContext();

	if (organization?.type === organizationTypes.ADMIN) {
		return <Navigate to={`/${organizationTypes.ADMIN}`} />;
	}

	if (organization?.id) {
		return <Navigate to={`/${organization.type}/${organization.id}`} />;
	}

	if (organizations.length) {
		const organization = organizations[0];

		return <Navigate to={`/${organization.type}/${organization.id}`} />;
	}

	return <NotFound />;
}
