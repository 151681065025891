import firebase from "firebase/app";

import { db } from "astrid-firebase";
import createArtifact from "astrid-firestore/src/api/artifacts/createArtifact";
import { Artifact } from "astrid-firestore/src/api/artifacts/types/Artifact";
import useBucket from "astrid-hooks/src/useBucket";

import { useProfile } from "../../authentication/state/profile";
import useSimpleForm from "../../forms/hooks/useSimpleForm";

export default function useArtifactForm({
	article,
	work,
	publisher,
	production,
	defaultName = "",
	onClose,
	setShowUploadModal,
	...props
}) {
	const user = useProfile();
	const bucket = useBucket("earselect-artifacts");

	return useSimpleForm({
		schema: Artifact.passthrough(),
		defaultValues: {
			name: defaultName,
			article: article || null,
			work: work || article?.work || null,
			publisher: publisher || null,
			production: production || null,
			isDefault: !!article,
			targetFiles: [],
		},
		onSubmit: async ({ targetFiles, ...data }) => {
			setShowUploadModal(true);
			const { id } = db.collection("artifacts").doc();

			const files = await bucket.putFiles(id, targetFiles);
			try {
				await createArtifact(firebase, { id, files, createdBy: user, ...data });
				setShowUploadModal(false);
			} catch (error) {
				await bucket.deleteFiles(files);
				setShowUploadModal(false);
				throw error;
			}
		},
		onSuccess: onClose,
		...props,
	});
}
