import { Button } from "semantic-ui-react";

import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import agreementTypes from "astrid-firestore/src/api/agreements/constants/agreementTypes";
import isApproved from "astrid-firestore/src/api/approval/isApproved";

import AdminCard from "../../../../admin/components/AdminCard/AdminCard";
import useHasRight from "../../../../authorization/hooks/useHasRight";
import CollectionDataTable from "../../../../ui/components/CollectionDataTable/CollectionDataTable";

import useAgreementColumns from "../../../hooks/useAgreementColumns";
import useAgreementsQuery from "../../../hooks/useAgreementsQuery";

export default function TemplateAgreements({ template }) {
	const { t } = useTranslation();

	const hasRight = useHasRight();
	const navigate = useNavigate();

	const query = useAgreementsQuery({ templateId: template.id });
	const columns = useAgreementColumns();

	const disabled = !isApproved(template) || template.deleted || template.type !== agreementTypes.FRAMEWORK;

	return (
		<AdminCard
			header={t("agreements", "Agreements")}
			extra={
				hasRight("agreements.send") && (
					<Button color="blue" disabled={disabled} onClick={() => navigate(`../prepare/${template.id}`)}>
						{t("prepareAgreements", "Prepare agreements")}
					</Button>
				)
			}
		>
			<CollectionDataTable
				query={query}
				columns={columns}
				onClick={({ id }) => navigate(`../${id}`)}
				sortBy={{
					id: "expires",
					desc: false,
				}}
			/>
		</AdminCard>
	);
}
