import firebase from "firebase/app";

import { useTranslation } from "react-i18next";

import updateArticle from "astrid-firestore/src/api/articles/updateArticle";
import softDeleteDocument from "astrid-firestore/src/api/documents/softDeleteDocument";

import { useProfile } from "../../../../authentication/state/profile";
import { getOrganizationPath } from "../../../../routing/utils/getOrganizationPath";
import useFileUpload from "../../../../ui/hooks/useFileUpload";

export default function useArticleDropdownMenuOptions({ article }) {
	const user = useProfile();

	const { t } = useTranslation();

	const [{ uploadFile, input, error, loading }] = useFileUpload({
		bucketName: "earselect-uploads",
		getDestination: () => `covers/${article.id}`,
		onSuccess: (cover) => {
			return updateArticle(firebase, { ref: article.ref, cover });
		},
	});

	const options = [
		{
			text: t("viewWork", "View work"),
			disabled: !article.work?.id,
			linkTo: getOrganizationPath(`/works/${article.work?.id}`),
		},
		// {
		// 	text: t("viewPresentation", "View presentation"),
		// 	linkTo: `../../../articles/${article.id}`,
		// },
		{
			text: t("uploadCover", "Upload cover"),
			onClick: uploadFile,
		},
		{
			text: t("delete", "Delete"),
			action: () => {
				return softDeleteDocument(firebase, article, user);
			},
		},
	];

	return { options, input, loading, error };
}
