import { Card } from "semantic-ui-react";

import db from "astrid-firebase/src/db";
import useDocumentData from "astrid-firestore/src/hooks/useDocumentData";

import PreviewAgreementPdfButton from "../../../../components/PreviewAgreementPdf/PreviewAgreementPdfButton";
import LoadingContext from "../../../ui/components/LoadingContext/LoadingContext";

import AdminActions from "../../../admin/components/AdminActions/AdminActions";

import AgreementAppendixes from "../../components/AgreementAppendixes/AgreementAppendixes";
import AgreementTemplateCard from "../../components/AgreementTemplateCard/AgreementTemplateCard";

import TemplateAgreements from "./components/TemplateAgreements";
import TemplatePrices from "./components/TemplatePrices";

export default function AgreementTemplateView({ id }) {
	const [template, loading, error] = useDocumentData(id && db.collection("agreementTemplates").doc(id));

	return (
		<LoadingContext loading={loading} error={error} data={template}>
			{template && (
				<>
					<AgreementTemplateCard template={template} />

					<AgreementAppendixes appendixes={template.appendixes} />

					<TemplatePrices template={template} />

					<TemplateAgreements template={template} />

					<AdminActions data={template}>
						<Card.Content extra>
							<PreviewAgreementPdfButton request={() => template} />
						</Card.Content>
						<AdminActions.ApprovalAction right="agreements.approve" />
						<AdminActions.DeletionAction right="agreements.delete" />
					</AdminActions>
				</>
			)}
		</LoadingContext>
	);
}
