import { useEffect } from "react";
import { useInstantSearch } from "react-instantsearch";

export default function InstantSearchRefresh() {
	const { refresh } = useInstantSearch();

	useEffect(() => {
		refresh();
	}, [refresh]);

	return null;
}
