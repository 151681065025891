import setDocument from "../../utils/setDocument";
import updateDocumentData from "../../utils/updateDocumentData";

import { Work } from "./types/Work";

export default async function updateWork(firebase, data) {
	const work = Work.partial().parse(updateDocumentData(firebase, data));

	return setDocument(work);
}
