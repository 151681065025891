import { Icon } from "semantic-ui-react";

import { useTranslation } from "react-i18next";

import { languageNames } from "astrid-helpers/src/languages";

import { dropdownMenuColumn } from "../../ui/utils/columns";

import useArticleTypeTranslations from "../../articles/hooks/useArticleTypeTranslations";
import displaySelectedCountries from "../../articles/views/ArticleView/utils/displaySelectedCountries";

import useDistributionPresetDropdownOptions from "./useDistributionPresetDropdownOptions";

export default function useDistributionPresetsTableColumns({ publisher }) {
	const { t } = useTranslation();

	const articleTypeTranslations = useArticleTypeTranslations();

	return [
		{
			id: "channel",
			Header: t("channel", "Channel"),
			accessor: ({ channel }) => channel?.name,
		},
		{
			id: "articleType",
			Header: t("articleType", "Article type"),
			accessor: ({ articleType }) => articleTypeTranslations[articleType],
		},
		{
			id: "languages",
			Header: t("languages", "Languages"),
			accessor: ({ languages }) => (languages.includes("all") ? t("all", "All") : languageNames(languages)),
		},
		{
			id: "targetData.countries",
			Header: t("countries", "Countries"),
			accessor: ({ targetData }) => {
				return displaySelectedCountries(targetData?.countries);
			},
		},
		{
			id: "targetData.prices",
			Header: t("prices", "Prices"),
			Cell: ({ value }) => {
				return value?.length > 0 && <Icon name="circle dollar" />;
			},
		},
		{
			id: "disabled",
			Header: t("enabled", "Enabled"),
			accessor: ({ disabled }) => {
				return disabled ? t("disabled", "Disabled") : t("enabled", "Enabled");
			},
		},
		dropdownMenuColumn(useDistributionPresetDropdownOptions({ publisher })),
	];
}
