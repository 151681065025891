import { useOutletContext } from "react-router-dom";

import { useDocumentData } from "astrid-firestore/src/hooks/index";

import LoadingContext from "../../ui/components/LoadingContext/LoadingContext";

import ArticleMetaDataView from "../views/ArticleView/ArticleMetaDataView";

export default function ArticleMetaData() {
	const context = useOutletContext();
	const [work, loading, error] = useDocumentData(context.article?.work?.ref);

	return (
		<LoadingContext data={work} loading={loading} error={error}>
			<ArticleMetaDataView {...context} work={work} />
		</LoadingContext>
	);
}
