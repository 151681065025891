import { Route } from "react-router-dom";

import ForwardContext from "../../../layouts/ForwardContext";

import Cost from "../pages/Cost";
import Expenses from "../pages/Expenses";
import Revenues from "../pages/Revenues";

export const costs = (
	<Route path="costs" element={<ForwardContext />} handle={{ crumb: ({ t }) => t("costs", "Costs") }}>
		<Route path="expenses" element={<Expenses />} />
		<Route path=":id" element={<Cost />} />
	</Route>
);

export const expenses = (
	<Route path="expenses" element={<ForwardContext />} handle={{ crumb: ({ t }) => t("expenses", "Expenses") }}>
		<Route index element={<Expenses />} />
		<Route path=":id" element={<Cost />} handle={{ crumb: ({ id }) => id }} />
	</Route>
);

export const revenues = (
	<Route path="revenues" element={<ForwardContext />} handle={{ crumb: ({ t }) => t("revenues", "Revenues") }}>
		<Route index element={<Revenues />} />
		<Route path=":id" element={<Cost />} handle={{ crumb: ({ id }) => id }} />
	</Route>
);
