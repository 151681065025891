import FirestoreDocument from "../../../types/FirestoreDocument";
import FirestoreLifecycle from "../../../types/FirestoreLifecycle";
import { array, enumValues, nullable, record, string, timestamp } from "../../../types/_types";

import { ArtifactRef } from "../../artifacts/types/ArtifactRef";
import { OrganizationRef } from "../../organizations/types/OrganizationRef";
import { WorkRef } from "../../works/types/WorkRef";

import articleTypes from "../constants/articleTypes";

import { ArticleBundleRef } from "./ArticleBundleRef";
import { ArticleChannel } from "./ArticleChannel";
import { ArticleDelivery } from "./ArticleDelivery";
import { ArticleMetaData } from "./ArticleMetaData";

export const articleType = enumValues(articleTypes);

export const Article = ArticleMetaData.extend({
	type: articleType,
	status: string(3),
	bundleId: nullable(string()),
	artifact: nullable(ArtifactRef),
	work: nullable(WorkRef),
	bundle: nullable(ArticleBundleRef),
	channels: record(string(20), ArticleChannel.partial()).default({}),
	channelIds: nullable(array(string())),
	delivery: ArticleDelivery,
	deliveryDate: nullable(timestamp()),
	producer: nullable(OrganizationRef),
	publisher: OrganizationRef,
})
	.merge(FirestoreDocument)
	.merge(FirestoreLifecycle);
