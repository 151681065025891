import { useTranslation } from "react-i18next";

import { languageName } from "astrid-helpers/src/languages";

export default function useWorksRefinements({ organization }) {
	const { t } = useTranslation();

	return [
		{
			header: t("publisher", "Publisher"),
			attribute: "publisher.name",
			searchable: true,
			collapsed: true,
			hidden: organization,
		},
		{
			header: t("imprint", "Imprint"),
			attribute: "imprint.name",
			searchable: true,
			collapsed: true,
		},
		{
			header: t("serie", "Series"),
			attribute: "serie.name",
			searchable: true,
			collapsed: true,
		},
		{
			header: t("tags", "Tags"),
			attribute: "tags",
			searchable: true,
			collapsed: true,
		},
		{
			header: t("author", "Author"),
			attribute: "authors",
			searchable: true,
			collapsed: true,
		},
		{
			header: t("translator", "Translator"),
			attribute: "translators",
			searchable: true,
			collapsed: true,
		},
		{
			header: t("created", "Created"),
			attribute: "created._seconds",
			type: "dateRange",
			collapsed: true,
		},
		{
			header: t("language", "Language"),
			attribute: "language",
			searchable: true,
			collapsed: true,
			renderItem: ({ label }) => languageName(label),
		},
	];
}
