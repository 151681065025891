import { useTranslation } from "react-i18next";
import { useOutletContext } from "react-router-dom";

import Unauthorized from "../../ui/components/Unauthorized/Unauthorized";

import useHasRight from "../../authorization/hooks/useHasRight";

import OrdersView from "../views/OrdersView/OrdersView";

export default function SalesOrders() {
	const { t } = useTranslation();
	const { organization } = useOutletContext();

	const hasRight = useHasRight();

	if (!hasRight("orders.view")) {
		return <Unauthorized />;
	}

	return <OrdersView header={t("salesOrders", "Sales orders")} expense={false} firstPartyId={organization.id} />;
}
