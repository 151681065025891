import { useOutletContext, useSearchParams } from "react-router-dom";

import CreateProductionPublisherView from "../views/CreateProductionPublisherView/CreateProductionPublisherView";

export default function CreateProductionPublisher() {
	const [searchParams, setSearchParams] = useSearchParams();
	const { organization } = useOutletContext();

	const articleId = searchParams.get("article");
	const workId = searchParams.get("work");

	return <CreateProductionPublisherView organization={organization} articleId={articleId} workId={workId} />;
}
