import articleStatuses from "astrid-firestore/src/api/articles/constants/articleStatuses";
import contributorRoles from "astrid-firestore/src/api/articles/constants/contributorRoles";
import { ProductionRef } from "astrid-firestore/src/api/productions/types/Production";
import { teamRoles } from "astrid-firestore/src/constants/teamRoles";
import { getCollectionData } from "astrid-firestore/src/helpers";
import arrayChunk from "astrid-helpers/src/arrayChunk";

import { hasMulti } from "./migrateProductionToFlattening";

async function mapAuthors({ api, env }, { production }) {
	let allAuthors = [];
	for (const authorChunk of arrayChunk(production.author || [], 10)) {
		const authors = await getCollectionData(
			api[env].db.collection("people").where(api.admin.firestore.FieldPath.documentId(), "in", authorChunk),
		);
		allAuthors = allAuthors.concat(authors);
	}

	return allAuthors.map((author) => {
		return {
			firstName: author.firstName,
			lastName: author.lastName,
			name: `${author.firstName} ${author.lastName}`,
			role: contributorRoles.AUTHOR,
		};
	});
}

async function mapTranslators({ api, env }, { production }) {
	let allTranslators = [];
	for (const translatorChunk of arrayChunk(production.translator || [], 10)) {
		const translators = await getCollectionData(
			api[env].db.collection("people").where(api.admin.firestore.FieldPath.documentId(), "in", translatorChunk),
		);
		allTranslators = allTranslators.concat(translators);
	}

	return allTranslators.map((translator) => {
		return {
			firstName: translator.firstName,
			lastName: translator.lastName,
			name: `${translator.firstName} ${translator.lastName}`,
			role: contributorRoles.TRANSLATOR,
		};
	});
}

function mapNarrators({ production }) {
	return Object.values(production.team || {})
		?.filter((teamMember) => teamMember?.role === teamRoles.READER)
		.map((narrator) => {
			return {
				firstName: narrator.user.firstName,
				lastName: narrator.user.lastName,
				name: `${narrator.user.firstName} ${narrator.user.lastName}`,
				role: contributorRoles.NARRATOR,
			};
		});
}

async function mapContributors({ api, env }, { production }) {
	const authors = await mapAuthors({ api, env }, { production });
	const translators = await mapTranslators({ api, env }, { production });
	const narrators = mapNarrators({ production });
	return [...authors, ...narrators, ...translators];
}

export default async function createCommonArticleData({ api, env }, { production, producer, publisher, season }) {
	const contributors = await mapContributors({ api, env }, { production });
	return {
		status: articleStatuses.READY,
		production: ProductionRef.parse(production),
		contributors,
		publisher,
		producer,
		language: production.language,
		originalLanguage: production.languageOriginal,
		additionalLanguages: production.languageExtra || [],
		originalName: "", // does not exist in old productions
		themaCodes: production.genreEditeur || [],
		tags: production.tags,
		abridged: Boolean(production.abridged),
		deliveryDate: production.deliveryDate ? api.Timestamp(production.deliveryDate) : null,
		distribution: {
			announcementDate: null,
			releaseDate: null,
			takedownDate: null,
		},
		title: false,
		titleSync: false,
		season,
		serieNumber: hasMulti(production) ? null : production.part || null,
	};
}
