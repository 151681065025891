import { useOutletContext } from "react-router-dom";

import { useProfile } from "../../authentication/state/profile";

export default function useFeatureFlag(featureFlag, organization) {
	const profile = useProfile();
	const context = useOutletContext();
	const org = organization || context?.organization || {};

	if (!featureFlag) {
		return true;
	}

	return profile?.featureFlags?.includes(featureFlag) || org?.featureFlags?.includes(featureFlag);
}
