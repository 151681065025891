import React from "react";

import Dashboard from "../../../dashboard/components/Dashboard/Dashboard";

import useProducerDashboardStatistics from "../../hooks/useProducerDashboardStatistics";

export default function ProducerDashboardView({ organization }) {
	const statistics = useProducerDashboardStatistics({ organization });

	return <Dashboard statistics={statistics} />;
}
