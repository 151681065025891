import { useDocumentData, useDocumentDataOnce, useLoadingValues } from "astrid-firestore/src/hooks";

import LoadingContext from "../../../ui/components/LoadingContext/LoadingContext";

import OfferContent from "../../../offers/views/OfferView/components/OfferContent";

export default function MyProductionsOfferView({ offer: staticOffer }) {
	const [[offer, production], loading, error] = useLoadingValues(
		useDocumentData(staticOffer.ref),
		useDocumentDataOnce(staticOffer.production.ref),
	);

	return (
		<>
			<LoadingContext data={production} loading={loading} error={error}>
				{offer && <OfferContent offer={offer} production={production} />}
			</LoadingContext>
		</>
	);
}
