import { orderBy } from "lodash";

import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import organizationTypes from "astrid-firestore/src/api/organizations/constants/organizationTypes";

import { sortProductionStatus } from "../helpers/sortItems";

export default function useRefinements(type) {
	const { t } = useTranslation();
	return useMemo(
		() =>
			[
				{
					header: t("language"),
					attribute: "language",
					type: "checkbox",
					scrollable: true,
				},
				{
					header: t("columns"),
					type: "columns",
				},
				{
					header: t("statusNoColon"),
					attribute: "status",
					type: "checkbox",
					sortItems: (items) => sortProductionStatus(items),
				},
				{
					header: t("productionTypeNoSemi"),
					attribute: "productionType",
					type: "checkbox",
					sortItems: (items) => orderBy(items, "label", "asc"),
				},
				{
					header: t("productionTags"),
					attribute: "productionTags",
					type: "checkbox",
					sortItems: (items) => orderBy(items, "label", "asc"),
				},
				{
					header: t("articles"),
					attribute: "articles",
					type: "checkbox",
					sortItems: (items) => orderBy(items, "label", "asc"),
				},
				(type === organizationTypes.PRODUCER || type === organizationTypes.ADMIN) && {
					header: t("publisher"),
					attribute: "publisherName",
					type: "checkbox",
					searchable: true,
				},
				(type === organizationTypes.PUBLISHER || type === organizationTypes.ADMIN) && {
					header: t("prodComp"),
					attribute: "producerName",
					type: "checkbox",
					searchable: true,
				},
				{
					header: t("publisherManager"),
					attribute: "publisherManagerNames",
					type: "checkbox",
					searchable: true,
				},
				{
					header: t("narrator"),
					attribute: "readerNames",
					type: "checkbox",
					searchable: true,
				},
				{
					header: t("voices", "Voices"),
					attribute: "voiceNames",
					type: "checkbox",
					searchable: true,
				},
				type === organizationTypes.PRODUCER && {
					header: t("editor"),
					attribute: "editorNames",
					type: "checkbox",
					searchable: true,
				},
				type === organizationTypes.PRODUCER && {
					header: t("proofer"),
					attribute: "prooferNames",
					type: "checkbox",
					searchable: true,
				},
				{
					header: t("productionManager"),
					attribute: "managerNames",
					type: "checkbox",
					searchable: true,
				},
				{
					header: t("subcontractor"),
					attribute: "subcontractorName",
					type: "checkbox",
					searchable: true,
				},
				{
					header: t("author"),
					attribute: "author",
					type: "checkbox",
					searchable: true,
				},
				{
					header: t("seriesPart", "Series (part)"),
					attribute: "series",
					type: "checkbox",
					searchable: true,
				},
				{
					header: t("prodDate"),
					type: "dates",
					attribute: "productionDate_timestamp",
				},
				{
					header: t("deliveryDate"),
					type: "dates",
					attribute: "deliveryDate_timestamp",
				},
				{
					header: t("doneDate"),
					type: "dates",
					attribute: "firstDoneDate_timestamp",
				},
				{
					header: t("created"),
					type: "dates",
					attribute: "created_timestamp",
				},
				{
					header: t("script"),
					type: "dates",
					attribute: "scriptDate.date_timestamp",
				},
			]
				.filter((v) => v)
				.map((item, index) => ({ index, ...item })),
		[t, type],
	);
}
