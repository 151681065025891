import { useTranslation } from "react-i18next";

import useSearchInput from "../../../ui/hooks/useSearchInput";

import AdminHeader from "../../../admin/components/AdminHeader/AdminHeader";

import ProductionsInstantSearchDataTable from "../../components/ProductionsInstantSearchDataTable/ProductionsInstantSearchDataTable";

export default function ProductionsUnfinalizedView({ organization }) {
	const { t } = useTranslation();

	const [searchInput, searchQuery] = useSearchInput();

	return (
		<>
			<AdminHeader text={t("unfinalized", "Unfinalized")}>{searchInput}</AdminHeader>

			<ProductionsInstantSearchDataTable
				producer={organization}
				searchQuery={searchQuery}
				unfinalized
				visibleColumns={["title", "productionType", "publisherName", "doneDate", "created", "status", "menu"]}
				// footer={false}
			/>
		</>
	);
}
