import { useTranslation } from "react-i18next";

import OpenButton from "../../../ui/components/OpenButton/OpenButton";

import AddArticleForm from "./AddArticleForm";

export default function AddArticleButton({ work, ...props }) {
	const { t } = useTranslation();

	return (
		<OpenButton text={t("addArticle", "Add article")} {...props}>
			{({ setOpen }) => <AddArticleForm work={work} onClose={() => setOpen(false)} />}
		</OpenButton>
	);
}
