import { useParams } from "react-router-dom";

import Unauthorized from "../../ui/components/Unauthorized/Unauthorized";

import AdminPageView from "../../admin/components/AdminPage/AdminPageView";
import useHasRight from "../../authorization/hooks/useHasRight";

import AgreementTemplateView from "../views/AgreementTemplateView/AgreementTemplateView";

export default function AgreementTemplate() {
	const { id } = useParams();

	const hasRight = useHasRight();

	if (!hasRight("agreements.view")) {
		return <Unauthorized />;
	}

	return (
		<AdminPageView id={id}>
			<AgreementTemplateView />
		</AdminPageView>
	);
}
