import { Card } from "semantic-ui-react";

import db from "astrid-firebase/src/db";
import useDocumentData from "astrid-firestore/src/hooks/useDocumentData";

import LoadingContext from "../../../ui/components/LoadingContext/LoadingContext";

import AdminActions from "../../../admin/components/AdminActions/AdminActions";

import OfferCard from "./components/OfferCard";

export default function OfferView({ id }) {
	const [offer, loading, error] = useDocumentData(id && db.collection("offers").doc(id));

	return (
		<LoadingContext data={offer} loading={loading} error={error}>
			{offer && (
				<Card.Group>
					<OfferCard offer={offer} />

					<AdminActions data={offer}>
						<AdminActions.ApprovalAction right="offers.approve" />
						<AdminActions.DeletionAction right="offers.delete" />
					</AdminActions>
				</Card.Group>
			)}
		</LoadingContext>
	);
}
