import React from "react";

import LoadingContext from "../../ui/components/LoadingContext/LoadingContext";
import Section from "../../ui/components/Section/Section";

import featureFlags from "../../authorization/constants/featureFlags";
import useFeatureFlag from "../../authorization/hooks/useFeatureFlag";
import Dashboard from "../../dashboard/components/Dashboard/Dashboard";
import usePublisherDashboardStatistics from "../../dashboard/hooks/usePublisherDashboardStatistics";

import AlertsWidget from "../components/AlertsWidget/AlertsWidget";
import FavoriteProductionsWidget from "../components/FavoriteProductionsWidget/FavoriteProductionsWidget";
import MyProductionsWidget from "../components/MyProductionsWidget/MyProductionsWidget";
import NotificationsWidget from "../components/NotificationsWidget/NotificationsWidget";
import TodosWidget from "../components/TodosWidget/TodosWidget";
import UpcomingDeliveriesWidget from "../components/UpcomingDeliveriesWidget/UpcomingDeliveriesWidget";
import HomeLayout from "../layouts/HomeLayout/HomeLayout";

const maxWidth = 1300;

export default function HomePublisherView({ organization }) {
	const hasFlatteningFeature = useFeatureFlag(featureFlags.FLATTENING);

	const [statistics, loading, error] = usePublisherDashboardStatistics({ organization });

	return (
		<>
			{hasFlatteningFeature && (
				<Section maxWidth={maxWidth}>
					<LoadingContext data={statistics} loading={loading} error={error}>
						<Dashboard statistics={statistics} />
					</LoadingContext>
				</Section>
			)}

			<Section maxWidth={maxWidth}>
				<HomeLayout>
					<HomeLayout.LeftColumn>
						<NotificationsWidget organization={organization} />
						<TodosWidget organization={organization} />
					</HomeLayout.LeftColumn>

					<HomeLayout.RightColumn>
						<AlertsWidget organization={organization} />
						<FavoriteProductionsWidget organization={organization} />
						<MyProductionsWidget organization={organization} />
						<UpcomingDeliveriesWidget organization={organization} />
					</HomeLayout.RightColumn>
				</HomeLayout>
			</Section>
		</>
	);
}
