import React from "react";
import { useTranslation } from "react-i18next";

import useSearchInput from "../../../ui/hooks/useSearchInput";
import PageView from "../../../ui/views/PageView/PageView";

import CreateImprintButton from "../../components/CreateImprintForm/CreateImprintButton";
import ImprintsCollectionDataTable from "../../components/ImprintsCollectionDataTable/ImprintsCollectionDataTable";

export default function ImprintsView({ organization }) {
	const { t } = useTranslation();

	const [searchInput, searchQuery] = useSearchInput();

	return (
		<PageView
			header={t("imprints", "Imprints")}
			extra={
				<>
					{searchInput}

					<CreateImprintButton organization={organization} />
				</>
			}
		>
			<ImprintsCollectionDataTable publisher={organization} searchQuery={searchQuery} />
		</PageView>
	);
}
