import { Label } from "semantic-ui-react";

import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import money from "astrid-helpers/src/money";

import Flex from "../../../../../components/Flex/Flex";

import useCostTypes from "../../../hooks/useCostTypes";
import { costPriceAccessor, estimationAccessor, totalAccessor } from "../../../utils/costAccessors";

import OpenCostDropdownMenu from "../../OpenCostDropdownMenu/OpenCostDropdownMenu";

import ApprovePriceAction from "../components/ApprovePriceAction";
import CostSessionPopup from "../components/CostSessionPopup";

export default function useOpenCostsTableColumns({ partyId, production }) {
	const { t } = useTranslation();

	const costTypes = useCostTypes();

	return useMemo(
		() => [
			{
				id: "secondParty.name",
				filter: "text",
				Header: t("secondParty", "Second party"),
			},
			{
				id: "price.type",
				filter: "select",
				Header: t("type", "Type"),
				accessor: ({ price }) => costTypes[price?.type],
				Cell: ({ value, row }) => (
					<Flex justifyContent="flex-start" gap={4}>
						{value} {row.original.session && <CostSessionPopup cost={row.original} />}
					</Flex>
				),
			},
			{
				id: "price.price",
				filter: "text",
				Header: t("price", "Price"),
				accessor: (cost) =>
					cost?.price?.price === null ? t("hiddenPrice", "Hidden price") : costPriceAccessor(cost),
			},
			{
				id: "estimation",
				Header: t("estimated", "Estimated"),
				textAlign: "right",
				accessor: estimationAccessor,
				Cell: ({ value, row }) => (value ? money(value, row.original.price?.currency) : null),
			},
			{
				id: "amount",
				Header: t("open", "Open"),
				textAlign: "right",
				accessor: totalAccessor,
				Cell: ({ value, row }) =>
					value ? <Label color="blue">{money(value, row.original.price?.currency)}</Label> : null,
			},
			{
				id: "actions",
				textAlign: "right",
				collapsing: true,
				Cell: ({ row }) => (
					<div style={{ whiteSpace: "nowrap" }}>
						<ApprovePriceAction price={row.original.price} />

						<OpenCostDropdownMenu
							partyId={partyId}
							openCost={row.original}
							production={production}
							isExpanded={row.isExpanded}
							expandedProps={row.getToggleRowExpandedProps({
								disabled: !row.canExpand,
							})}
						/>
					</div>
				),
			},
		],
		[costTypes, partyId, production, t],
	);
}
