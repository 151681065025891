import React from "react";
import { useTranslation } from "react-i18next";

import Flex from "../../../components/Flex/Flex";
import CoverImage from "../../ui/components/CoverImage/CoverImage";
import themeColors from "../../ui/constants/themeColors";
import { dropdownMenuColumn, timestampColumn } from "../../ui/utils/columns";

import useHasRight from "../../authorization/hooks/useHasRight";
import useHasRole from "../../authorization/hooks/useHasRole";
import { useOrganizationType } from "../../organizations/state/organizationType";

import ArticleDeliveryStatus from "../components/ArticleDeliveryStatus/ArticleDeliveryStatus";

import useArticleDropdownMenuOptions from "./useArticleDropdownMenuOptions";
import useArticleTypeTranslations from "./useArticleTypeTranslations";

export default function useArticlesTableColumns({ publisher } = {}) {
	const { t } = useTranslation();
	const hasRight = useHasRight();
	const organizationType = useOrganizationType();
	const hasRole = useHasRole();

	const dropdownMenuOptions = useArticleDropdownMenuOptions();

	const articleTypeTranslations = useArticleTypeTranslations();

	return [
		{
			id: "name",
			collapsing: true,
			Header: t("Name", "Name"),
			Cell: ({ value, row }) => {
				return (
					<Flex justifyContent="flex-start">
						<CoverImage
							thumb
							rounded
							data={row.original}
							style={{ marginRight: "1em", height: 45, width: 45 }}
						/>

						<div>
							{value} {row.original.episodeName && ` - ${row.original.episodeName}`}
							<div style={{ color: themeColors.GREY }}>{row.original.subtitle}</div>
						</div>
					</Flex>
				);
			},
		},
		{
			id: "type",
			filter: "select",
			Header: t("type", "Type"),
			collapsing: true,
			accessor: ({ type }) => articleTypeTranslations[type],
		},
		{
			id: "isbn",
			Header: t("isbn", "ISBN"),
			collapsing: true,
		},
		{
			id: "status",
			collapsing: true,
			disableSortBy: true,
			Header: t("status", "Status"),
			Cell: ({ row }) => {
				return <ArticleDeliveryStatus article={row.original} />;
			},
		},
		!publisher && { id: "publisher", Header: t("publisher", "Publisher"), accessor: "publisher.name" },
		{
			id: "serie",
			Header: t("serie", "Serie"),
			collapsing: true,
			accessor: ({ serie }) => serie?.name,
			Cell: ({ row }) => {
				const { original } = row;
				const { serie, season, serieNumber } = original;

				return (
					<>
						{serie && `${serie.name}`}
						{season && `, ${season.name}`}
						{serieNumber && `, ${t("part", "Part")} ${serieNumber}`}
					</>
				);
			},
		},
		timestampColumn({ id: "deliveryDate", Header: t("deliveryDate", "Delivery date"), time: false }),
		timestampColumn({ id: "updated", Header: t("updated", "Updated"), time: false }),
		timestampColumn({ id: "created", Header: t("created", "Created"), time: false }),
		hasRight("articles.view") && dropdownMenuColumn(dropdownMenuOptions),
	];
}
