import { Outlet, useOutletContext } from "react-router-dom";

import LoadingContext from "../../../ui/components/LoadingContext/LoadingContext";

import { useProfile } from "../../../authentication/state/profile";
import useHasRight from "../../../authorization/hooks/useHasRight";

export default function OrganizationContext() {
	const hasRight = useHasRight();

	const { organization } = useOutletContext();

	const user = useProfile();

	const isAuthorized = !!user?.organizations?.[organization?.id] || hasRight("super");

	return (
		<LoadingContext data={organization} authorized={isAuthorized}>
			{organization && <Outlet context={{ organization }} />}
		</LoadingContext>
	);
}
