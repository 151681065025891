import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import organizationTypes from "astrid-firestore/src/api/organizations/constants/organizationTypes";

import { useProfile } from "../../../../authentication/state/profile";
import featureFlags from "../../../../authorization/constants/featureFlags";
import useFeatureFlag from "../../../../authorization/hooks/useFeatureFlag";
import useHasRight from "../../../../authorization/hooks/useHasRight";

export default function useMainMenuOptions({ organization = {} }) {
	const { t } = useTranslation();

	const user = useProfile();

	const { id, type } = organization;

	const hasRight = useHasRight();
	const location = useLocation();
	const hasFlatteningFeature = useFeatureFlag(featureFlags.FLATTENING, organization);

	const { pathname } = location;

	if (pathname.startsWith("/account")) {
		return [
			{
				to: `/account/${user?.id}/settings`,
				text: t("settings", "Settings"),
				active: pathname.includes("settings"),
			},
			{
				to: `/account/${user?.id}/agreements`,
				text: t("agreements", "Agreements"),
				active: pathname.includes("agreements"),
			},
			{
				to: `/account/${user?.id}/revenues`,
				text: t("revenues", "Revenues"),
				active: pathname.includes("revenues"),
			},
			{
				to: `/account/${user?.id}/orders`,
				text: t("invoiceData", "Invoice data"),
				active: pathname.includes("orders"),
			},
		];
	}

	if (type === organizationTypes.ADMIN) {
		return [
			{
				to: "/admin",
				text: t("overview", "Overview"),
				active: pathname === "/admin",
			},
			{
				to: "/admin/approve/costs",
				text: t("needsApproval", "Needs approval"),
				active: pathname.startsWith("/admin/approve"),
			},
			{
				to: "/admin/producers",
				text: t("producers", "Producers"),
				active: pathname.startsWith("/admin/producers"),
			},
			{
				to: "/admin/publishers",
				text: t("publishers", "Publishers"),
				active: pathname.startsWith("/admin/publishers"),
			},
			{
				to: `/${id}/works`,
				text: t("works", "Works"),
				active: pathname.includes("/works"),
				hidden: !hasFlatteningFeature,
			},
			{
				to: "/admin/articles",
				text: t("articles", "Articles"),
				active: pathname.startsWith("/admin/articles"),
				hidden: !hasFlatteningFeature,
			},
			{
				to: "/admin/list-productions",
				text: t("productions", "Productions"),
				active: pathname.startsWith("/admin/list-productions") || pathname.startsWith("/production/"),
			},
			{
				to: `/admin/distributions`,
				text: t("distributions", "Distributions"),
				active: pathname.startsWith("/admin/distributions"),
				hidden: !hasFlatteningFeature,
			},
			{
				to: "/admin/users",
				text: t("users", "Users"),
				active: pathname.startsWith("/admin/users"),
			},
			{
				to: "/admin/settings/channels",
				text: t("settings", "Settings"),
				active: pathname.startsWith("/admin/settings"),
			},
		];
	}

	if (type === organizationTypes.PRODUCER) {
		return [
			{
				to: `/${type}/${id}`,
				text: t("overview", "Overview"),
				active: pathname.endsWith(`/${type}/${id}`),
			},
			{
				to: `/${type}/${id}/create-production`,
				text: t("createProduction", "Create production"),
				active: pathname.includes("create-production"),
				hidden: !hasRight("createProduction"),
			},
			{
				to: `/${type}/${id}/my-productions/current`,
				text: t("myProductions", "My productions"),
				active: pathname.includes("my-productions"),
			},
			{
				to: `/${type}/${id}/list-productions`,
				text: t("productions", "Productions"),
				active: pathname.includes("list-productions") || pathname.startsWith("/production/"),
				hidden: !hasRight("list-productions.beta"),
			},
			{
				to: `/${type}/${id}/calendar`,
				text: t("calendar", "Calendar"),
				active: pathname.includes("/calendar"),
			},
			{
				to: `/${type}/${id}/documents`,
				text: t("documents", "Documents"),
				active: pathname.includes("/documents"),
			},
			{
				to: `/${type}/${id}/statistics`,
				text: t("statistics"),
				active: pathname.includes("/statistics"),
				hidden: !hasRight("viewProductionStatistics"),
			},
			{
				to: `/${type}/${id}/studios`,
				text: t("studios", "Studios"),
				active: pathname.includes("/studios"),
			},
			{
				to: `/${type}/${id}/users`,
				text: t("users", "Users"),
				active: pathname.includes("/users"),
			},
			{
				to: `/${type}/${id}/admin/settings/vendors`,
				text: t("admin", "Admin"),
				hidden: !hasRight("admin"),
				active: pathname.includes("admin"),
			},
		];
	}

	if (type === organizationTypes.PUBLISHER) {
		return [
			{
				to: `/${type}/${id}`,
				text: t("overview", "Overview"),
				active: pathname.endsWith(`/${type}/${id}`),
			},
			{
				to: `/${type}/${id}/create-production`,
				text: t("createProduction", "Create production"),
				active: pathname.includes("create-production"),
			},
			{
				to: `/${type}/${id}/my-productions/current`,
				text: t("myProductions", "My productions"),
				active: pathname.includes("my-productions"),
			},
			{
				to: `/${type}/${id}/works`,
				text: t("works", "Works"),
				active: pathname.includes(`/${type}/${id}/works`),
				hidden: !hasFlatteningFeature,
			},
			{
				to: `/${type}/${id}/articles`,
				text: t("articles", "Articles"),
				active: pathname.includes(`/${type}/${id}/articles`),
				hidden: !hasFlatteningFeature,
			},
			{
				to: `/${type}/${id}/list-productions`,
				text: t("productions", "Productions"),
				active: pathname.includes("list-productions") || pathname.startsWith("/production/"),
			},
			{
				to: `/${type}/${id}/imprints`,
				text: t("imprints", "Imprints"),
				active: pathname.startsWith(`/${type}/${id}/imprints`),
				hidden: !hasFlatteningFeature,
			},
			{
				to: `/${type}/${id}/series`,
				text: t("series", "Series"),
				active: pathname.startsWith(`/${type}/${id}/series`),
				hidden: !hasFlatteningFeature,
			},
			{
				to: `/${type}/${id}/users`,
				text: t("users", "Users"),
				active: pathname.includes("/users"),
			},
			{
				to: `/${type}/${id}/settings/vendors`,
				text: t("settings", "Settings"),
				hidden: !hasRight("admin"),
				active: pathname.startsWith(`/${type}/${id}/settings`),
			},
		];
	}

	if (type === organizationTypes.NARRATOR) {
		return [
			{
				to: `/${type}/${id}`,
				text: t("overview", "Overview"),
				active: pathname.endsWith(`/${type}/${id}`),
			},
			{
				to: `/${type}/${id}/my-productions/current`,
				text: t("myProductions", "My productions"),
				active: pathname.includes("my-productions"),
			},
			{
				to: `/${type}/${id}/calendar`,
				text: t("calendar", "Calendar"),
				active: pathname.includes("/calendar"),
			},
		];
	}

	return [];
}
