import { useEffect, useState } from "react";
import { useRefinementList } from "react-instantsearch";

import Select from "../Select/Select";

import InstantSearchData from "./InstantSearchData";

function RegularSearchSelect({ data, getOption, setSearchQuery, ...props }) {
	const onSearchChange = (e, { searchQuery }) => setSearchQuery(searchQuery);
	return (
		<Select
			search={(data) => {
				return data;
			}}
			onSearchChange={onSearchChange}
			options={data.map(getOption)}
			disabled={false}
			{...props}
		/>
	);
}

function RefinementSearchSelect({ getOption, searchQuery, setSearchQuery, refinement, ...props }) {
	const { items, searchForItems } = useRefinementList(refinement);

	const refinementSearch = (e, { searchQuery }) => {
		searchForItems(searchQuery);
		setSearchQuery(searchQuery);
	};

	return (
		<Select
			search={(data) => {
				return data;
			}}
			onSearchChange={refinementSearch}
			options={items.map(getOption)}
			disabled={false}
			value={searchQuery}
			{...props}
		/>
	);
}

export default function InstantSearchSelect({ configure, value, getOption, refinement, ...props }) {
	const [searchQuery, setSearchQuery] = useState("");

	useEffect(() => {
		setSearchQuery("");
	}, [value]);

	return (
		<InstantSearchData
			value={value}
			searchQuery={searchQuery}
			configure={{
				hitsPerPage: 10,
				...configure,
			}}
			{...props}
		>
			{refinement ? (
				<RefinementSearchSelect
					refinement={refinement}
					getOption={getOption}
					searchQuery={searchQuery}
					setSearchQuery={setSearchQuery}
				/>
			) : (
				<RegularSearchSelect getOption={getOption} setSearchQuery={setSearchQuery} />
			)}
		</InstantSearchData>
	);
}
