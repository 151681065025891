import { Loader, Image as SemanticImage } from "semantic-ui-react";

import React, { useEffect, useState } from "react";

export default function CoverImage({ data, thumb, style, loading, ...props }) {
	const [internalSrc, setInternalSrc] = useState("/img/thumb.jpg");

	useEffect(() => {
		const src = thumb ? data.cover?.thumbUrl : data.cover?.url;

		if (src) {
			const img = new Image();
			img.onload = () => setInternalSrc(src);
			img.src = src;
		}
	}, [data, thumb]);

	const children = loading || (data.cover && !data.cover?.thumbUrl) ? <Loader active /> : undefined;

	return (
		<SemanticImage src={internalSrc} style={{ width: "100%", ...style }} {...props}>
			{children}
		</SemanticImage>
	);
}
