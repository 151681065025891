import { useTranslation } from "react-i18next";

import productionStatuses from "astrid-firestore/src/api/productions/constants/productionStatuses";

import useSearchInput from "../../../ui/hooks/useSearchInput";

import AdminHeader from "../../../admin/components/AdminHeader/AdminHeader";

import ProductionsInstantSearchDataTable from "../../components/ProductionsInstantSearchDataTable/ProductionsInstantSearchDataTable";

export default function ProductionsDraftsView({ organization }) {
	const { t } = useTranslation();

	const [searchInput, searchQuery] = useSearchInput();

	return (
		<>
			<AdminHeader text={t("drafts", "Drafts")}>{searchInput}</AdminHeader>

			<ProductionsInstantSearchDataTable
				producer={organization}
				searchQuery={searchQuery}
				statuses={[productionStatuses.DRAFT]}
				visibleColumns={["title", "productionType", "publisherName", "created", "menu"]}
			/>
		</>
	);
}
