import { Button, Checkbox, Message, Modal } from "semantic-ui-react";

import React, { useState } from "react";

import { db, firebase } from "astrid-firebase";
import storage from "astrid-firebase/src/storage";
import distributeMigratedArticles from "astrid-firestore/scripts/migrateProductionToFlattening/distributeMigratedArticles";
import { migrateProductionToFlattening } from "astrid-firestore/scripts/migrateProductionToFlattening/migrateProductionToFlattening";
import revertMigratedProduction from "astrid-firestore/scripts/migrateProductionToFlattening/revertMigratedProduction";
import artifactStatuses from "astrid-firestore/src/api/artifacts/constants/artifactStatuses";
import { useCollectionData } from "astrid-firestore/src/hooks/index";
import { isLive } from "astrid-helpers/src/env";
import stopPropagation from "astrid-helpers/src/stopPropagation";

import ArtifactStatusLabel from "../../../features/artifacts/components/ArtifactStatus/ArtifactStatusLabel";
import featureFlags from "../../../features/authorization/constants/featureFlags";
import useFeatureFlag from "../../../features/authorization/hooks/useFeatureFlag";
import PrimaryButton from "../../../features/ui/components/Buttons/PrimaryButton";
import SecondaryButton from "../../../features/ui/components/Buttons/SecondaryButton";
import ErrorMessage from "../../../features/ui/components/Messages/ErrorMessage";
import useLoadingFunction from "../../../features/ui/hooks/useLoadingFunction";

import FlexColumn from "../../Flex/FlexColumn";

const env = isLive ? "live" : "stage";
export const migrationApi = {
	admin: firebase,
	[env]: {
		db,
		getStorageMetadata: async (bucket, file) => {
			const metadata = await storage.refFromURL(`gs://${bucket}`).child(file).getMetadata();
			return [metadata];
		},
		getStorageJsonFile: async (bucket, file) => {
			const url = await storage.refFromURL(`gs://${bucket}`).child(file).getDownloadURL();
			const response = await fetch(url);
			if (!response.ok) {
				throw new Error(`Failed to download file: ${response.statusText}`);
			}
			const arrayBuffer = await response.arrayBuffer();
			const jsonString = new TextDecoder("utf-8").decode(arrayBuffer);
			return JSON.parse(jsonString);
		},
	},
	Timestamp: (ts) => {
		return new firebase.firestore.Timestamp(ts.seconds, ts.nanoseconds);
	},
};

function MigrationMessages({ messages }) {
	return (
		<>
			{messages.map((message) => {
				return (
					<Message
						color={message.created ? "green" : "blue"}
						key={message.id}
						success={message.success}
						header={message.header}
						content={message.message}
					/>
				);
			})}
		</>
	);
}

function RevertMigration({ production, setMessages, onClose }) {
	const [revertMigration, revertLoading, error] = useLoadingFunction(async () => {
		const deletedDocuments = await revertMigratedProduction({ api: migrationApi, env }, { production });
		setMessages(
			deletedDocuments.map((doc) => ({
				id: doc.id,
				header: `Deleted ${doc.collection} ${doc.id}`,
				deleted: true,
			})),
		);
	});

	return (
		<>
			<Modal.Content>
				<p>{`Are you sure you wish to revert the migration of ${production.title}?`}</p>
				{error && <ErrorMessage error={error} />}
			</Modal.Content>
			<Modal.Actions>
				<SecondaryButton content="Cancel" onClick={onClose} />
				<Button color="red" content="Revert migration" loading={revertLoading} onClick={revertMigration} />
			</Modal.Actions>
		</>
	);
}

function Distribute({ production, createdDocuments, setMessages, onClose }) {
	const [distributeSuccess, setDistributeSuccess] = useState(false);
	const [distribute, loading, error] = useLoadingFunction(async () => {
		const createdDistributions = await distributeMigratedArticles(
			{ api: migrationApi, env },
			{
				articles: createdDocuments
					.filter(({ collection }) => collection === "articles")
					.map(({ data }) => data),
			},
		);
		setMessages(
			createdDistributions.map((doc) => {
				return {
					id: doc.id,
					header: `Distributing ${doc.article.type} ${doc.article.name} to ${doc.channel.name}.`,
					message: `Artifact: ${doc.delivery.artifact}, Metadata: ${doc.delivery.metadata}, Distribution: ${doc.id}, Article: ${doc.article.id}`,
					created: true,
				};
			}),
		);
		setDistributeSuccess(true);
	});

	const [artifacts, loadingArtifacts, artifactError] = useCollectionData(
		migrationApi[env].db.collection("artifacts").where("production.id", "==", production.id),
	);

	const allArtifactsValid = artifacts
		?.map((artifact) => artifact?.status)
		?.every((status) => status === artifactStatuses.VALID);

	return (
		<>
			<Modal.Content>
				{!allArtifactsValid ? (
					<>
						<p>
							We are currently validating the files associated with the production. Distribution will be
							available soon. Please wait..
						</p>
						<FlexColumn style={{ gap: 10, maxWidth: "300px" }}>
							{artifacts?.map((artifact) => {
								return <ArtifactStatusLabel key={artifact.id} artifact={artifact} />;
							})}
						</FlexColumn>
					</>
				) : (
					!distributeSuccess && <p>Ready for distribution. Do you wish to distribute?</p>
				)}
				{(error || artifactError) && <ErrorMessage error={error || artifactError} />}
			</Modal.Content>
			<Modal.Actions>
				<SecondaryButton content={distributeSuccess ? "Done" : "Cancel"} onClick={onClose} />
				{!distributeSuccess && (
					<PrimaryButton
						content="Distribute"
						disabled={loadingArtifacts || !allArtifactsValid}
						loading={loading}
						onClick={distribute}
					/>
				)}
			</Modal.Actions>
		</>
	);
}

function Migrate({ production, setMessages, setCreatedDocuments, updateProductionStatusCallback, onClose }) {
	const [convertToAnotherPublisher, setConvertToAnotherPublisher] = useState(false);
	const [doMigration, loading, error] = useLoadingFunction(async () => {
		const updatedApi = convertToAnotherPublisher ? { ...migrationApi, convertToAnotherPublisher } : migrationApi;
		const createdDocuments = await migrateProductionToFlattening({ api: updatedApi, env }, { production });
		setCreatedDocuments(createdDocuments);
		setMessages(
			createdDocuments.map((doc) => {
				return {
					id: doc.data.id,
					header: `Created ${doc.collection} ${doc.data.id}`,
					created: true,
				};
			}),
		);
		updateProductionStatusCallback();
	});

	return (
		<>
			<Modal.Content>
				<p>{`Are you sure you wish to migrate ${production.title}?`}</p>
				<Checkbox
					label={"Convert to astrid publishing"}
					checked={convertToAnotherPublisher}
					onClick={() => {
						if (convertToAnotherPublisher) {
							setConvertToAnotherPublisher(false);
						} else {
							setConvertToAnotherPublisher(isLive ? "Ls9xocYGv4I37r7MVTEV" : "5wGBEakYMREKlmufsjLs");
						}
					}}
				/>
				{error && <ErrorMessage error={error} />}
			</Modal.Content>
			<Modal.Actions>
				<SecondaryButton content="Cancel" onClick={onClose} />
				<PrimaryButton content="Migrate" loading={loading} onClick={doMigration} />
			</Modal.Actions>
		</>
	);
}

export default function MigrateProduction({
	production,
	setMigrateProductionOpen,
	updateProductionStatusCallback = () => {},
}) {
	const [createdDocuments, setCreatedDocuments] = useState([]);
	const [messages, setMessages] = useState([]);
	const hasFlatteningFeature = useFeatureFlag(featureFlags.FLATTENING);

	if (!hasFlatteningFeature) {
		return null;
	}

	const isMigrated = production.migrations?.flattening?.migrated;

	const onClose = () => {
		setMigrateProductionOpen(false);
	};

	return (
		<Modal open onClose={onClose} onKeyDown={stopPropagation} onClick={stopPropagation}>
			<Modal.Header content={isMigrated ? "Migrate production" : "Revert migration"} />
			{messages?.length > 0 && (
				<Modal.Content>
					<MigrationMessages messages={messages} />
				</Modal.Content>
			)}
			{isMigrated && !createdDocuments.length ? (
				<RevertMigration production={production} setMessages={setMessages} onClose={onClose} />
			) : createdDocuments.length ? (
				<Distribute
					production={production}
					createdDocuments={createdDocuments}
					setMessages={setMessages}
					onClose={onClose}
				/>
			) : (
				<Migrate
					production={production}
					setCreatedDocuments={setCreatedDocuments}
					setMessages={setMessages}
					updateProductionStatusCallback={updateProductionStatusCallback}
					onClose={onClose}
				/>
			)}
		</Modal>
	);
}
