import { Card, Header } from "semantic-ui-react";

import React from "react";

import ErrorMessage from "../../../ui/components/Messages/ErrorMessage";

export default function AdminCard({ header, error, children, transparent, extra }) {
	return (
		<>
			{header && <Header as="h3" content={header} />}

			{transparent && (
				<>
					{children}

					{error && <ErrorMessage error={error} />}

					{extra && <Card.Content>{extra}</Card.Content>}
				</>
			)}

			{!transparent && (
				<>
					<Card fluid>
						<Card.Content>
							{children}

							{error && <ErrorMessage error={error} />}
						</Card.Content>

						{extra && <Card.Content>{extra}</Card.Content>}
					</Card>
				</>
			)}
		</>
	);
}
