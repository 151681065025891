import { useTranslation } from "react-i18next";
import { useOutletContext } from "react-router-dom";

import Unauthorized from "../../ui/components/Unauthorized/Unauthorized";

import useHasRight from "../../authorization/hooks/useHasRight";

import CostsView from "../views/CostsView/CostsView";

export default function Revenues() {
	const { t } = useTranslation();
	const { organization } = useOutletContext();

	const hasRight = useHasRight();

	if (!hasRight("costs.view")) {
		return <Unauthorized />;
	}

	return (
		<CostsView
			header={t("unprocessedRevenues", "Unprocessed revenues")}
			expense={false}
			orderId={null}
			firstPartyId={organization.id}
		/>
	);
}
