import { Button } from "semantic-ui-react";

import React from "react";
import { useTranslation } from "react-i18next";

import { updateDocument } from "astrid-firestore/src/helpers";
import Form from "astrid-web/src/features/forms/components/Form/Form";

import AdminCard from "../../../../admin/components/AdminCard/AdminCard";
import useHasRight from "../../../../authorization/hooks/useHasRight";
import useOrganizationRoleOptions from "../../../../organizations/hooks/useOrganizationRoleOptions";

import useEmployeeOptions from "../../../hooks/useEmployeeOptions";

export default function UserPermissions({ user, organization }) {
	const { t } = useTranslation();
	const hasRight = useHasRight();

	const { type } = organization;
	const typePlural = `${type}s`;

	const organizationSettings = user[typePlural]?.[organization.id];

	const roleOptions = useOrganizationRoleOptions(type);
	const employeeOptions = useEmployeeOptions();

	const form = Form.useForm({
		defaultValues: {
			languageOptions: organizationSettings?.languageOptions || [],
			employee: organizationSettings?.employee || false,
			permissions: user.permissions?.[type]?.[organization.id] || ["any"],
		},
	});

	const { handleSubmit, formState, reset } = form;

	const { isSubmitting, isDirty, isValid } = formState;

	const onSubmit = async ({ languageOptions, employee, permissions }) => {
		try {
			await updateDocument(user.ref, {
				[`${typePlural}.${organization.id}.languageOptions`]: languageOptions,
				[`${typePlural}.${organization.id}.employee`]: employee,
				[`permissions.${type}.${organization.id}`]: permissions,
			});

			reset({ languageOptions, employee, permissions });
		} catch (error) {
			console.log(error);
		}
	};

	return (
		<AdminCard
			header={t("permissions", "Permissions")}
			extra={
				<Button primary loading={isSubmitting} disabled={!isDirty || !isValid} onClick={handleSubmit(onSubmit)}>
					{t("save", "Save")}
				</Button>
			}
		>
			<Form form={form}>
				<Form.Group widths="equal">
					{type === "producer" && (
						<Form.Select
							name="employee"
							options={employeeOptions}
							label={t("employee", "Employee")}
							rules={{ required: false }}
						/>
					)}
					<Form.Select
						multiple
						name="permissions"
						options={roleOptions.filter(
							// Only superadmins can assign producer admins
							({ value }) => hasRight("superadmin") || value !== "producerAdmin",
						)}
						label={t("roles", "Roles")}
						getMissingOption={(option) => {
							if (option.value === "any") {
								return false;
							}
							return option;
						}}
					/>
					<Form.LanguageSelect
						name="languageOptions"
						label={t("languageOptions")}
						limitedOptions={organization.languageOptions}
						multiple
					/>
				</Form.Group>
			</Form>
		</AdminCard>
	);
}
