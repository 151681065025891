import { Checkbox } from "semantic-ui-react";

import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { canDeliverArticlesToChannels } from "astrid-firestore/src/api/distribution/utils/deliverArticlesToChannels";

import FlexColumn from "../../../../../components/Flex/FlexColumn";
import Form from "../../../../forms/components/Form/Form";
import Popup from "../../../../ui/components/Popup/Popup";

function isArtifactSelected({ form, channel }) {
	const delivery = form.getValues("delivery");
	return delivery?.[channel?.id]?.artifact;
}

function selectOrDeselectAll({ form, articles, channels, deliveryType, checked }) {
	const allSelectedOrDeselected = Object.entries(form.getValues("delivery")).reduce((acc, [channelId, delivery]) => {
		const channel = channels.find((channel) => channel.id === channelId);

		if (!channel) {
			// sub set of channels selected, only auto-select channels from the subset.
			return acc;
		}

		const isSelectable = canDeliverArticlesToChannels({ articles, deliveryType, channel });
		const metaRequired = channel?.disableArtifactDeliveryWithoutMetadata;

		if (deliveryType === "artifact") {
			return {
				...acc,
				[channelId]: {
					...delivery,
					artifact: isSelectable ? checked : delivery[deliveryType],
					metadata: checked && metaRequired ? checked : delivery.metadata, // auto-select metadata if artifact is selected
				},
			};
		}

		if (deliveryType === "metadata") {
			return {
				...acc,
				[channelId]: {
					...delivery,
					metadata:
						isArtifactSelected({ form, channel }) && metaRequired
							? delivery.metadata
							: isSelectable
							? checked
							: delivery.metadata,
				},
			};
		}
	}, {});

	form.setValue("delivery", allSelectedOrDeselected);
}

function deliveryColumn({ form, articles, channels, deliveryType, Header }) {
	return {
		id: deliveryType,
		Header: (
			<FlexColumn style={{ alignItems: "center", gap: 5 }}>
				{Header}
				<Checkbox
					defaultChecked={true}
					slider
					onClick={(_, { checked }) => {
						selectOrDeselectAll({ form, articles, channels, deliveryType, checked });
					}}
				/>
			</FlexColumn>
		),
		textAlign: "center",
		Cell: ({ row }) => {
			const { t } = useTranslation();
			const { id, disableArtifactDeliveryWithoutMetadata } = row.original;
			const metadataRequired = disableArtifactDeliveryWithoutMetadata;

			const disabled = !canDeliverArticlesToChannels({
				articles,
				deliveryType,
				channel: row.original,
			});

			const onChange = (_, { checked }) => {
				if (deliveryType === "artifact") {
					form.setValue(`delivery.${id}.artifact`, checked);

					// If metadata is required and artifact is enabled, update metadata too
					if (checked && metadataRequired) {
						form.setValue(`delivery.${id}.metadata`, checked);
					}
					return;
				}

				if (deliveryType === "metadata") {
					const artifactSelected = isArtifactSelected({ form, channel: row.original });

					// Prevent setting metadata if artifact is selected and metadata is required
					if (artifactSelected && metadataRequired) {
						return;
					}
					form.setValue(`delivery.${id}.metadata`, checked);
				}
			};

			if (deliveryType === "metadata" && metadataRequired) {
				return (
					<Popup
						content={t("mandatory", "Mandatory")}
						trigger={
							<Form.Checkbox
								shouldUnregister={false}
								name={`delivery.${row.original.id}.${deliveryType}`}
								slider
								disabled={disabled}
								onChange={onChange}
							/>
						}
					/>
				);
			}

			return (
				<Form.Checkbox
					shouldUnregister={false}
					name={`delivery.${row.original.id}.${deliveryType}`}
					slider
					disabled={disabled}
					onChange={onChange}
				/>
			);
		},
	};
}

export default function useDistributeArticlesChannelsTableColumns({ form, articles, channels }) {
	const { t } = useTranslation();

	return useMemo(
		() => [
			{
				id: "name",
				Header: t("channel", "Channel"),
			},
			deliveryColumn({
				form,
				articles,
				channels,
				deliveryType: "metadata",
				Header: t("meta", "Meta"),
			}),
			deliveryColumn({
				form,
				articles,
				channels,
				deliveryType: "artifact",
				Header: t("files", "Files"),
			}),
		],
		[articles, channels, t],
	);
}
