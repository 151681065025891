import firebase from "firebase/app";

import { Header } from "semantic-ui-react";

import { useContext } from "react";
import { useTranslation } from "react-i18next";

import { db } from "astrid-firebase";
import useCollectionDataByIdsOnce from "astrid-firestore/src/hooks/useCollectionDataByIdsOnce";

import DataTable from "../../../ui/components/DataTable/DataTable";

import useArticlesTableColumns from "../../../articles/hooks/useArticlesTableColumns";
import ArtifactsCollectionDataTable from "../../../artifacts/components/ArtifactsCollectionDataTable/ArtifactsCollectionDataTable";

import ProductionViewContext from "../ProductionView/context/ProductionViewContext";

import ProductionArticlesAddArticleButton from "./components/ProductionArticlesAddArticleButton";

export default function ProductionArticlesView({ production }) {
	const { t } = useTranslation();

	const { publisher } = useContext(ProductionViewContext);

	const [articles, loading, error] = useCollectionDataByIdsOnce(
		firebase,
		db.collection("articles"),
		production.articleIds,
	);

	const columns = useArticlesTableColumns({ production });

	return (
		<>
			<Header as="h4" content={t("articles", "Articles")} />
			<DataTable
				allowEmpty
				data={articles}
				columns={columns}
				loading={loading}
				error={error}
				footer={
					<>
						<ProductionArticlesAddArticleButton production={production} publisher={publisher} />
						{/*<ProductionArticlesEditArticlesButton production={production} />*/}
					</>
				}
			/>

			{/*<Header as="h4" content={t("scripts", "Scripts")} />*/}
			{/*<ProductionScriptsDataTable*/}
			{/*	allowEmpty*/}
			{/*	production={production}*/}
			{/*	// footer={<CreateArticleButton text={t("addArticle", "Add article")} />}*/}
			{/*	footer={<ProductionArticlesAddScriptButton production={production} publisher={publisher} />}*/}
			{/*/>*/}

			<Header as="h4" content={t("files", "Files")} />
			<ArtifactsCollectionDataTable
				allowEmpty
				production={production}
				// footer={
				// 	<>
				// 		<CreateArtifactButton
				// 			publisher={publisher}
				// 			production={production}
				// 			articles={production?.articles}
				// 			disabled={!production?.articles}
				// 		/>
				// 	</>
				// }
			/>
		</>
	);
}
