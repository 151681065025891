import { teamRoles } from "../../constants/teamRoles";
import runTransaction from "../../helpers/runTransaction";
// import getCollectionData from "../../utils/getCollectionData";
import { getPriceRef, getUserRef } from "../../utils/referenceMappers";

// import { DiscussionChannel } from "../discussion/types/DiscussionChannel";
import createProductionPriceData from "../productions/prices/createProductionPriceData";
import createTeamMemberData from "../productions/team/createTeamMemberData";
import createOfferStatusChangedTodoData from "../todos/data/offers/createOfferStatusChangedTodoData";

import offerStatuses from "./constants/offerStatuses";
import getOfferAgreement from "./utils/getOfferAgreement";
import getOfferPrice from "./utils/getOfferPrice";

export const ALREADY_ACCEPTED_ERROR = "Already accepted!";

export default async function acceptOffer(firebase, { offer, user }) {
	const db = firebase.firestore();

	const price = getOfferPrice({ offer, user });
	const agreement = getOfferAgreement({ offer, user });

	/*	const comments = await getCollectionData(
		db.collection("comments").where("channels", "array-contains", `offers/${offer.id}`),
	);*/

	return runTransaction(db, [offer.ref], (transaction, [transactionOffer]) => {
		if (transactionOffer.status === offerStatuses.ACCEPTED) {
			throw ALREADY_ACCEPTED_ERROR;
		}

		/* if (comments.length > 0) {
			for (const comment of comments) {
				transaction.update(comment.ref, {
					channels: [...comment.channels, `productions/${offer.production.id}/${offer.secondParty.id}`],
				});
			}

			const discussionChannel = DiscussionChannel.parse({
				subject: {
					id: offer.production.id,
					name: offer.secondParty.name
						.split(" ")
						.map((name, index) => (index === 0 ? name : name[0] + "."))
						.join(" "),
					ref: `/productions/${offer.production.id}`,
				},
				name: offer.secondParty.name
					.split(" ")
					.map((name, index) => (index === 0 ? name : name[0] + "."))
					.join(" "),
				participantIds: [offer.secondParty.id],
			});

			transaction.set(discussionChannel.ref, discussionChannel);
		}*/

		transaction.update(offer.ref, {
			status: offerStatuses.ACCEPTED,
			secondParty: offer.secondParty || getUserRef(user),
		});

		const firstParty = agreement?.firstParty || offer.firstParty;
		const secondParty = agreement?.secondParty || offer.secondParty;

		const productionPrice = price
			? createProductionPriceData(firebase, {
					...price,
					expense: true,
					approved: true,
					producer: offer.producer,
					production: offer.production,
					agreement,
					firstParty,
					secondParty,
					referencePrice: price,
			  })
			: null;

		const member = createTeamMemberData(firebase, {
			user,
			offer,
			agreement,
			firstParty,
		});

		if (productionPrice?.ref && offer.role !== teamRoles.RECORDER) {
			transaction.set(productionPrice.ref, productionPrice);
		}

		transaction.update(offer.production.ref, {
			[`team.${member.id}`]: { ...member, price: getPriceRef(productionPrice) },
		});

		const todo = createOfferStatusChangedTodoData(firebase, {
			status: offerStatuses.ACCEPTED,
			offer: { ...offer, secondParty: offer.secondParty || getUserRef(user) },
		});

		transaction.set(todo.ref, todo);
	});
}
