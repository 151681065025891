import firebase from "firebase/app";

import { useMemo } from "react";

import getDistributionChannelsQuery from "astrid-firestore/src/api/distribution/getDistributionChannelsQuery";
import { filterDistributionChannelsByArticles } from "astrid-firestore/src/api/distribution/utils/filterDistributionChannels";
import useCollectionData from "astrid-firestore/src/hooks/useCollectionData";
import useDocumentData from "astrid-firestore/src/hooks/useDocumentData";
import useLoadingValues from "astrid-firestore/src/hooks/useLoadingValues";

export default function useArticlesDistributionChannels({ articles }) {
	const [[publisher, channels], loading, error] = useLoadingValues(
		useDocumentData(articles[0].publisher.ref),
		useCollectionData(getDistributionChannelsQuery(firebase)),
	);

	const filteredData = useMemo(
		() => filterDistributionChannelsByArticles({ publisher, articles, channels }),
		[articles, channels, publisher],
	);

	const filteredChannelIds = useMemo(
		() => [...new Set(filteredData.flatMap(({ filteredChannels }) => filteredChannels).map(({ id }) => id))],
		[filteredData],
	);

	const filteredChannels = useMemo(
		() => channels?.filter(({ id }) => filteredChannelIds.includes(id)) || [],
		[channels, filteredChannelIds],
	);

	return [filteredChannels, loading, error];
}
