import equal from "fast-deep-equal";
import firebase from "firebase/app";

import React from "react";
import { useTranslation } from "react-i18next";

import syncWorkMetadataToArticles from "astrid-firestore/src/api/works/syncWorkMetadataToArticles";
import { WorkMetaData } from "astrid-firestore/src/api/works/types/WorkMetaData";

import AlertModal from "../../../ui/components/AlertModal/AlertModal";

import Form from "../../../forms/components/Form/Form";
import useSimpleForm from "../../../forms/hooks/useSimpleForm";

import { useSyncWorkMetadataColumns } from "./hooks/useSyncWorkMetadataColumns";

export default function SyncWorkMetadataForm({ text, work, articles, onClose, onSuccess }) {
	const { t } = useTranslation();

	const columns = useSyncWorkMetadataColumns();

	const { form, formState, onSubmit, error } = useSimpleForm({
		defaultValues: {
			metadata: [],
		},
		onSubmit: ({ metadata }) => {
			return syncWorkMetadataToArticles(firebase, { work, articles, metadata });
		},
	});

	const data = Object.keys(WorkMetaData.shape)
		.map((id) => ({
			id,
			value: work[id],
		}))
		.filter(({ id }) => !articles.every((article) => equal(article[id], work[id])));

	if (formState.isSubmitSuccessful) {
		return (
			<AlertModal
				text={t("successfullySyncedMetadataMessage", "Successfully synced metadata to {{amount}} articles!", {
					amount: articles.length,
				})}
				onClose={() => {
					onSuccess?.();
					onClose();
				}}
			/>
		);
	}

	return (
		<Form.Modal
			header={text}
			size="small"
			form={form}
			error={error}
			onSubmit={onSubmit}
			disabled={!formState.isValid}
			submitText={t("sync", "Sync")}
			onClose={onClose}
		>
			<Form.FormCheckboxTable name="metadata" columns={columns} data={data} />
		</Form.Modal>
	);
}
