import firebase from "firebase";

import { Dropdown } from "semantic-ui-react";

import React, { useCallback, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";

import { deleteDocument } from "astrid-firestore/src/helpers";
import updateDocument from "astrid-firestore/src/helpers/updateDocument";
import MasterSoundSetting from "astrid-web/src/components/MasterSoundSetting";

import useResetPassword from "../../../../authentication/hooks/useResetPassword";
import { setActAsUser } from "../../../../authentication/state/actAsUser";
import { useProfile } from "../../../../authentication/state/profile";
import useHasRight from "../../../../authorization/hooks/useHasRight";
import useHasRole from "../../../../authorization/hooks/useHasRole";
import useUserOrganizations from "../../../../organizations/hooks/useUserOrganizations";
import ConfirmationModal from "../../../../ui/components/ConfirmationModal/ConfirmationModal";
import DropdownMenu from "../../../../ui/components/DropdownMenu/DropdownMenu";
import ErrorMessage from "../../../../ui/components/Messages/ErrorMessage";
import Message from "../../../../ui/components/Messages/Message";
import useConfirm from "../../../../ui/hooks/useConfirm";
import useFileUpload from "../../../../ui/hooks/useFileUpload";

export default function UserDropdownMenu({ user, organization }) {
	const hasRole = useHasRole();
	const hasRight = useHasRight();
	const profile = useProfile();
	const navigate = useNavigate();
	const imageRef = useRef();

	const { t } = useTranslation();

	const [confirmResetPassword, setConfirmResetPassword] = useState(false);
	const [confirmDeleteUser, setConfirmDeleteUser] = useState(false);
	const [confirmRemoveUser, setConfirmRemoveUser] = useState(false);
	const [openAudioSettingModal, setOpenAudioSettingModal] = useState(false);

	const userOrganizations = useUserOrganizations(user);

	const onActAsUser = useCallback(() => {
		setActAsUser(user.id);

		if (userOrganizations[0]) {
			const { id, type } = userOrganizations[0];
			navigate(`/${type}/${id}`);
		}
	}, [navigate, user.id, userOrganizations]);

	const [{ loading: uploadLoading, error: uploadError }, onUploadFile] = useFileUpload({
		bucketName: "earselect-uploads",
		getDestination: () => `profileImages/${user.id}`,
	});

	const onRemoveUser = useConfirm(t("removeFromOrganizationConfirmation", "Remove from organization?"), () =>
		updateDocument(user.ref, {
			[`permissions.${organization.type}.${organization.id}`]: firebase.firestore.FieldValue.delete(),
			[`permissions.${organization.type}Index`]: Object.keys(user.permissions[organization.type]).length > 1,
		}),
	);

	const onDeleteUser = async () => {
		await deleteDocument(user.ref);
		navigate("../list");
	};

	const { resetPassword, loading: passwordLoading, error: passwordError, message } = useResetPassword(user);

	const disableActAsUser = !hasRole("owner", "admin") || ["owner", "admin"].includes(user.role);

	const error = uploadError || passwordError;
	const loading = uploadLoading || passwordLoading;

	return (
		<>
			<DropdownMenu loading={loading}>
				{() => (
					<>
						<Dropdown.Item as={Link} to={`/profile/${user.id}`} text={t("profile", "Profile")} />

						<Dropdown.Item
							text={t("actAsUser", "Act as user")}
							disabled={disableActAsUser}
							onClick={onActAsUser}
						/>

						{user.permissions.reader && (
							<Dropdown.Item
								text={t("soundSetting", "Audio setting")}
								onClick={() => {
									setOpenAudioSettingModal(true);
								}}
							/>
						)}

						<Dropdown.Item
							text={t("uploadNewProfilePicture", "Upload new profile picture")}
							onClick={() => {
								imageRef.current.click();
							}}
						/>

						<Dropdown.Item
							text={t("setNewPassword", "Set new password")}
							disabled={!hasRight("users.passwordReset")}
							onClick={() => {
								setConfirmResetPassword(true);
							}}
						/>

						{organization && (
							<>
								<Dropdown.Divider style={{ margin: 0 }} />
								<Dropdown.Item
									text={t("removeFromOrganization", "Remove from organization")}
									onClick={() => {
										setConfirmRemoveUser(true);
									}}
								/>
							</>
						)}

						{hasRole("owner") && (
							<>
								<Dropdown.Divider style={{ margin: 0 }} />
								<Dropdown.Item
									text={t("deleteUser", "Delete user")}
									onClick={() => {
										setConfirmDeleteUser(true);
									}}
								/>
							</>
						)}
					</>
				)}
			</DropdownMenu>

			<input style={{ display: "none" }} ref={imageRef} type="file" onChange={onUploadFile} />

			{confirmResetPassword && (
				<ConfirmationModal
					text={t("resetPasswordConfirmation", "Are you sure you want to reset the password?")}
					onConfirm={resetPassword}
					onClose={() => setConfirmResetPassword(false)}
				/>
			)}

			{confirmDeleteUser && (
				<ConfirmationModal
					text={t("deleteConfirmMessage", "Are you sure you want to delete?")}
					onConfirm={onDeleteUser}
					onClose={() => setConfirmDeleteUser(false)}
				/>
			)}

			{confirmRemoveUser && (
				<ConfirmationModal
					text={t("removeFromOrganizationConfirmation", "Remove from organization?")}
					onConfirm={onRemoveUser}
					onClose={() => setConfirmRemoveUser(false)}
				/>
			)}

			{message && <Message dismissible>{message}</Message>}

			{error && <ErrorMessage error={error} />}

			{openAudioSettingModal && (
				<MasterSoundSetting
					onClose={() => setOpenAudioSettingModal(false)}
					open
					location="users"
					locationId={user.id}
					locationName={user.firstName + " " + user.lastName}
					userId={profile.id}
					value={{ list: user?.reaperTemplates?.list }}
				/>
			)}
		</>
	);
}
