import { useOutletContext, useParams } from "react-router-dom";

import Unauthorized from "../../ui/components/Unauthorized/Unauthorized";

import AdminPageView from "../../admin/components/AdminPage/AdminPageView";
import useHasRight from "../../authorization/hooks/useHasRight";

import PriceView from "../views/PriceView/PriceView";

export default function Price() {
	const { id } = useParams();
	const hasRight = useHasRight();
	const { organization } = useOutletContext();

	if (!hasRight("prices.view")) {
		return <Unauthorized />;
	}

	return (
		<AdminPageView id={id} organization={organization}>
			<PriceView />
		</AdminPageView>
	);
}
