import { FormField } from "semantic-ui-react";

import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import FileUploadTable from "../../../ui/components/FileUploadTable/FileUploadTable";

import Form from "../../../forms/components/Form/Form";

import useArtifactForm from "../../hooks/useArtifactForm";

import UploadingArtifactModal from "../UploadingArtifactModal/UploadingArtifactModal";

export default function CreateArtifactForm({ work, article, articles, publisher, production, defaultName, onClose }) {
	const { t } = useTranslation();
	const [showUploadModal, setShowUploadModal] = useState(false);

	const { form, setValue, onSubmit, error } = useArtifactForm({
		article,
		work,
		publisher,
		production,
		defaultName,
		setShowUploadModal,
		onClose,
	});

	// hämta artiklar som har produktions id och passera in som articles istället för att skicka in artiklar

	return (
		<>
			<Form.Modal
				size="tiny"
				header={t("uploadFiles", "Upload files")}
				form={form}
				error={error}
				onSubmit={onSubmit}
				onClose={onClose}
			>
				{articles && (
					<Form.DocumentSelect
						name="article"
						label={t("article", "Article")}
						options={articles.map((article) => ({
							key: article.id,
							value: article.id,
							text: article.name,
							data: article,
						}))}
					/>
				)}

				<Form.Input name="name" />

				<FormField>
					<FileUploadTable onChange={(files) => setValue("targetFiles", files)} />
				</FormField>

				<Form.Checkbox name="isDefault" label={t("setAsDefault", "Set as default")} />
			</Form.Modal>

			{showUploadModal && <UploadingArtifactModal />}
		</>
	);
}
