import createDocumentData from "../../utils/createDocumentData";
import runBatch from "../../utils/runBatch";

import getArticleDeliveryNeededData from "../articles/data/getArticleDeliveryNeededData";

import { Artifact } from "./types/Artifact";
import { ArtifactRef } from "./types/ArtifactRef";

export default function createArtifact(firebase, data) {
	return runBatch(firebase, (batch) => {
		const artifact = Artifact.parse(createDocumentData(firebase, "artifacts", data));
		batch.set(artifact.ref, artifact);

		if (data.isDefault && artifact.article?.ref) {
			batch.update(artifact.article.ref, { artifact: ArtifactRef.parse(artifact) });
			const duration = data?.files?.reduce((acc, file) => acc + (file.duration || 0), 0);

			batch.update(artifact.article.ref, getArticleDeliveryNeededData(data.article, { duration }, true));
		}
	});
}
