import firebase from "firebase";

import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import updateArtifact from "astrid-firestore/src/api/artifacts/updateArtifact";
import useBucket from "astrid-hooks/src/useBucket";

import api from "../../../../../api";
import { useProfile } from "../../../../authentication/state/profile";
import Form from "../../../../forms/components/Form/Form";
import AsyncButton from "../../../../ui/components/AsyncButton/AsyncButton";
import PrimaryButton from "../../../../ui/components/Buttons/PrimaryButton";
import SecondaryButton from "../../../../ui/components/Buttons/SecondaryButton";
import ConfirmationModal from "../../../../ui/components/ConfirmationModal/ConfirmationModal";
import useFormFileUpload from "../../../../ui/hooks/useFormFileUpload";

import sortArtifactFiles from "../../../utils/sortArtifactFiles";

export default function ArtifactFilesDataTableFooter({ artifact, onSubmit, selectedFlatRows }) {
	const user = useProfile();
	const { t } = useTranslation();
	const bucket = useBucket("earselect-artifacts");
	const [confirmDeleteFiles, setConfirmDeleteFiles] = useState(false);
	const { formState, reset, watch } = Form.useFormContext();
	const { prepend, replace } = Form.useFieldArray({ name: "files" });
	const { files } = watch();

	const { input, onClick } = useFormFileUpload({
		onChange: (files) => {
			prepend(files);
		},
	});

	const selectedFiles = selectedFlatRows.map(({ original }) => original);
	const disabled = selectedFiles.length === 0;

	const onDownload = async () => {
		await bucket.downloadFiles(selectedFiles);
	};

	const onDelete = async () => {
		const toBeRemovedFromBucket = selectedFiles.filter((file) => file.url);
		const notStoredInBucket = selectedFiles.filter((file) => !file.url);

		const newFilesState = files.filter(
			(file) =>
				!toBeRemovedFromBucket.find((f) => f.url === file.url) &&
				!notStoredInBucket.find((f) => f.name === file.name),
		);

		await updateArtifact(firebase, {
			...artifact,
			files: newFilesState.filter((file) => file.url),
			updatedBy: user,
		});

		if (toBeRemovedFromBucket.length) {
			await bucket.deleteFiles(toBeRemovedFromBucket);
		}

		reset({ files: newFilesState }, { keepDirty: true });
	};

	return (
		<>
			<AsyncButton disabled={disabled} onClick={onDownload} content={t("download", "Download")} />

			<AsyncButton onClick={onClick} content={t("addFiles", "Add files")} />

			<SecondaryButton onClick={() => replace(sortArtifactFiles(files))} content={t("sortFiles", "Sort files")} />

			<AsyncButton
				negative
				disabled={disabled}
				onClick={() => setConfirmDeleteFiles(true)}
				content={t("deleteFiles", "Delete files")}
			/>

			<PrimaryButton
				disabled={formState.isSubmitting || !formState.isDirty}
				loading={formState.isSubmitting}
				onClick={onSubmit}
				content={t("saveFileChanges", "Save file changes")}
			/>

			{confirmDeleteFiles && (
				<ConfirmationModal
					header={t("deleteFiles", "Delete files")}
					text={`${t("wantToDelete")} :\n ${selectedFiles?.map((artifact) => artifact.name).join("\n")}`}
					onClose={() => setConfirmDeleteFiles(false)}
					onConfirm={onDelete}
				/>
			)}

			{input}
		</>
	);
}
