import { Route } from "react-router-dom";

import Artifact from "../pages/Artifact/Artifact";
import ArtifactFiles from "../pages/Artifact/ArtifactFiles";

export const artifact = (
	<Route path=":artifact" element={<Artifact />} handle={{ crumb: ({ artifact }) => artifact?.name }}>
		<Route index element={<ArtifactFiles />} />
	</Route>
);
