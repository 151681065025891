import { Route } from "react-router-dom";

import ForwardContext from "../../../layouts/ForwardContext";

import Studio from "../pages/Studio";
import Studios from "../pages/Studios";

export const studios = (
	<Route path="studios" element={<ForwardContext />}>
		<Route index element={<Studios />} />
		<Route path=":id" element={<Studio />} />
	</Route>
);
