import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import PrimaryButton from "../../../ui/components/Buttons/PrimaryButton";

import AdminCard from "../../../admin/components/AdminCard/AdminCard";
import ProductionsCollectionDataTable from "../../../productions/components/ProductionsCollectionDataTable/ProductionsCollectionDataTable";
import { getOrganizationPath } from "../../../routing/utils/getOrganizationPath";

export default function ArticleProductionsView({ article }) {
	const { t } = useTranslation();

	return (
		<AdminCard
			transparent
			header={t("productions", "Productions")}
			extra={
				<PrimaryButton
					as={Link}
					to={getOrganizationPath(`/create-production?article=${article.id}&work=${article.work?.id}`)}
				>
					{t("createProduction", "Create production")}
				</PrimaryButton>
			}
		>
			<ProductionsCollectionDataTable
				getQuery={(query) => query.where("articleIds", "array-contains", article.id)}
			/>
		</AdminCard>
	);
}
