import createCover from "./createCover";
import createDocumentIdAndRef from "./createDocumentIdAndRef";

export default async function createWork({ api, env }, { production, commonArticleData }) {
	const { id, ref } = createDocumentIdAndRef({ api, env }, { collection: "works" });
	const work = {
		id,
		ref,
		name: production.title,
		synopsis: production.synopsis || production.deliveryEbook?.synopsis || null,
		...commonArticleData,
	};
	const cover = await createCover({ api, env }, { img: production.img, imgThumb: production.imgThumb });

	return {
		...work,
		cover,
	};
}
