import getCollectionQuery from "../../utils/getCollectionQuery";

export default function getArtifactsQuery(firebase, { publisher, article, production, limit }) {
	return getCollectionQuery(firebase.firestore().collection("artifacts"), {
		fields: {
			publisher,
			article,
			production,
		},
		limit,
		softDeletes: true,
	});
}
