import { useOutletContext } from "react-router-dom";

import Unauthorized from "../../ui/components/Unauthorized/Unauthorized";

import useHasRight from "../../authorization/hooks/useHasRight";

import StudiosView from "../views/StudiosView/StudiosView";

export default function Studios() {
	const hasRight = useHasRight();

	const { organization } = useOutletContext();

	if (!hasRight("studios.view")) {
		return <Unauthorized />;
	}

	return <StudiosView organization={organization} />;
}
