import { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { updateDocument } from "astrid-firestore/src/helpers";

import AdminForm from "../../../../admin/components/AdminForm/AdminForm";
import Form from "../../../../forms/components/Form/Form";
import useSimpleForm from "../../../../forms/hooks/useSimpleForm";

export default function OrganizationLanguageSettings({ organization }) {
	const { t } = useTranslation();

	const form = useSimpleForm({
		defaultValues: {
			languageDefault: organization.languageDefault || "",
			languageOptions: organization.languageOptions || [],
		},
		onSubmit: (data) => {
			return updateDocument(organization.ref, data);
		},
	});

	const { watch, setValue } = form;

	const data = watch();

	useEffect(() => {
		if (!data.languageOptions.includes(data.languageDefault)) {
			setValue("languageDefault", data.languageOptions[0] || "");
		}
	}, [data.languageDefault, data.languageOptions, setValue]);

	return (
		<AdminForm header={t("languageSettings", "Language settings")} {...form}>
			<Form.Group widths="equal">
				<Form.LanguageSelect
					name="languageOptions"
					label={t("languageOptions")}
					multiple
					rules={{ required: false }}
				/>

				<Form.LanguageSelect
					name="languageDefault"
					label={t("languageDefault")}
					limitedOptions={data.languageOptions}
					rules={{ required: false }}
				/>
			</Form.Group>
		</AdminForm>
	);
}
