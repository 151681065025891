import useArticlesDistributionChannels from "../../hooks/useArticlesDistributionChannels";

import DistributeArticlesForm from "./DistributeArticlesForm";

export default function DistributeArticles({ articles, selectedChannels, onClose }) {
	const [filteredChannels, loading] = useArticlesDistributionChannels({ articles });

	const selectedChannelIds = selectedChannels.map(({ id }) => id);

	if (loading) {
		return null;
	}

	return (
		<DistributeArticlesForm
			articles={articles}
			onClose={onClose}
			selectedChannelIds={selectedChannelIds}
			channels={filteredChannels}
		/>
	);
}
