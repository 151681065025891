import { Button, Input, List, Loader, Message, Segment } from "semantic-ui-react";

import { useState } from "react";
import { useTranslation } from "react-i18next";

import db from "astrid-firebase/src/db";
import useCollectionData from "astrid-firestore/src/hooks/useCollectionData";

import useHasRight from "../../../authorization/hooks/useHasRight";

import Document from "./components/Document";
import UploadDocumentModal from "./components/UploadDocumentModal";

export default function DocumentsView({ profile, organization }) {
	const { t } = useTranslation();
	const [openModal, setOpenModal] = useState(false);
	const [search, setSearch] = useState("");
	const hasRight = useHasRight();

	const [documents, loading, error] = useCollectionData(
		profile.permissions?.producer?.[organization.id] &&
			db
				.collection("organizations")
				.doc(organization.id)
				.collection("documents")
				.where("roleAccess", "array-contains-any", profile.permissions.producer[organization.id]),
	);

	const filteredDocuments = documents?.filter((doc) => doc.title.includes(search));

	return (
		<Segment>
			<Input
				size="small"
				icon="search"
				placeholder={t("search")}
				value={search}
				onChange={(e) => setSearch(e.target.value)}
			/>

			{hasRight("documents.create") && (
				<Button
					onClick={(e) => {
						setOpenModal(true);
					}}
					floated="right"
					primary
				>
					{t("upload", "Upload")}
				</Button>
			)}

			{loading && <Loader active size="large" />}

			{error && <Message error>{t("errorMessageHeader")}</Message>}

			{openModal && <UploadDocumentModal organization={organization} setOpenModal={setOpenModal} />}

			<List divided relaxed style={{ marginTop: "3%" }}>
				{filteredDocuments?.length ? (
					filteredDocuments?.map((document, index) => (
						<Document key={index} document={document} organization={organization} />
					))
				) : (
					<p>{t("noDocumentsFound", "No documents found")}</p>
				)}
			</List>
		</Segment>
	);
}
