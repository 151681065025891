import { Route } from "react-router-dom";

import ForwardContext from "../../../layouts/ForwardContext";

import Invoices from "../pages/Invoices";

export const invoices = (
	<Route path="invoices" element={<ForwardContext />}>
		<Route index element={<Invoices />} />
	</Route>
);
