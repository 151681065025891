export function getPipelineProgressPercentage(progress) {
	const steps = Object.values(progress || {});

	const total = steps.reduce((acc, step) => acc + (step.total || 0), 0);
	const done = steps.reduce((acc, step) => acc + (step.processed || 0) + (step.processing || 0), 0);

	const percentage = Math.round((done / total) * 100);

	return percentage;
}
