import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import useWindowSize from "react-use/lib/useWindowSize";

import { db } from "astrid-firebase";
import { useCollectionData, useDocumentData, useLoadingValues } from "astrid-firestore/src/hooks";

import DocTitle from "../../../../components/DocTitle";
import Flex from "../../../../components/Flex/Flex";
import AlertToggle from "../../../../components/production/AlertToggle";
import StarToggle from "../../../../components/production/StarToggle";
import MigrateProduction from "../../../../components/production/parts/MigrateProduction";
import HistoryModal from "../../../../components/production/parts/ProductionHistory";
import ProductionInformationEdit from "../../../../components/production/parts/ProductionInformationEdit";
import DropdownMenu from "../../../ui/components/DropdownMenu/DropdownMenu";
import LoadingContext from "../../../ui/components/LoadingContext/LoadingContext";
import VerticalMenu from "../../../ui/components/VerticalMenu/VerticalMenu";
import PageView from "../../../ui/views/PageView/PageView";

import { useProfile } from "../../../authentication/state/profile";
import useOffersQuery from "../../../offers/hooks/useOffersQuery";

import useProductionPermissions from "../../hooks/useProductionPermissions";
import updateProduction from "../../utils/updateProduction";

import ProductionDiscussionOld from "./components/ProductionDiscussionOld";
import ProductionViewContext from "./context/ProductionViewContext";
import useProductionDropdownMenuOptions from "./hooks/useProductionDropdownMenuOptions";
import useProductionMenuOptions from "./hooks/useProductionMenuOptions";
import useProductionMetaGuide from "./hooks/useProductionMetaGuide";

export default function ProductionView({ modal, history, production, children }) {
	const user = useProfile();
	const navigate = useNavigate();

	const { width } = useWindowSize();

	const [historyOpen, setHistoryOpen] = useState(false);
	const [migrateProductionOpen, setMigrateProductionOpen] = useState(false);

	const isDesktop = width > 1000;

	const [[producer, publisher, offers, discussionChannels], loading, error] = useLoadingValues(
		useDocumentData(production?.producer && db.collection("organizations").doc(production.producer)),
		useDocumentData(production?.publisher && db.collection("organizations").doc(production.publisher)),
		useCollectionData(useOffersQuery({ production })),
		useCollectionData(
			db.collection("discussionChannels").where("subject.id", "==", production.id).where("deleted", "==", false),
		),
	);

	const permissions = useProductionPermissions(production);

	const handleChange = (e, data, options) => updateProduction(e, production, data, options);

	const alerts = useProductionMetaGuide({ history, production });
	const menuOptions = useProductionMenuOptions({ production, offers, alerts, discussionChannels });
	const dropdownMenuOptions = useProductionDropdownMenuOptions({
		production,
		setHistoryOpen,
		setMigrateProductionOpen,
	});

	return (
		<LoadingContext data={production} loading={loading} error={error}>
			{production && (
				<PageView
					header={production.title}
					subheader={`${production.publisherName} + ${production.producerName}`}
					extra={
						<>
							<StarToggle production={production.id} profile={user} uid={user?.id} />

							<AlertToggle production={production} profile={user} uid={user?.id} />

							<DropdownMenu
								style={{ marginRight: "-10px" }}
								loading={loading}
								options={dropdownMenuOptions}
							/>
						</>
					}
					sidebar={<VerticalMenu options={menuOptions} />}
					maxWidth={1600}
				>
					<DocTitle title={production.title} />

					<div style={{ maxWidth: 1200, margin: "0 auto" }}>
						<Flex alignItems="flex-start" gap={10}>
							<div style={{ flex: 1 }}>
								<ProductionViewContext.Provider
									value={{
										alerts,
										offers,
										producer,
										publisher,
										isDesktop,
										permissions,
										handleChange,
										fullProduction: production,
									}}
								>
									{children}
								</ProductionViewContext.Provider>
							</div>

							{modal === "edit" && (
								<ProductionInformationEdit
									alerts={alerts}
									producer={producer}
									publisher={publisher}
									production={production}
									permissions={permissions}
									handleChange={handleChange}
									onClose={() => navigate(`/production/${production.id}`)}
								/>
							)}

							{isDesktop && !history?.location?.pathname?.endsWith("discussions") && (
								<div style={{ width: 400, margin: 0 }}>
									<ProductionDiscussionOld production={production} permissions={permissions} />
								</div>
							)}
						</Flex>
					</div>
				</PageView>
			)}

			{historyOpen && (
				<HistoryModal
					productionId={production.id}
					onClose={() => {
						setHistoryOpen(false);
					}}
				/>
			)}
			{migrateProductionOpen && (
				<MigrateProduction production={production} setMigrateProductionOpen={setMigrateProductionOpen} />
			)}
		</LoadingContext>
	);
}
