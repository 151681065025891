import firebase from "firebase";

import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import getArticlesQuery from "astrid-firestore/src/api/articles/getArticlesQuery";
import getArtifactsQuery from "astrid-firestore/src/api/artifacts/getArtifactsQuery";
import { useCollectionCount } from "astrid-firestore/src/hooks";

import api from "../../../../../api";

export default function useArticleMenuOptions({ article }) {
	const { t } = useTranslation();

	const location = useLocation();

	const { pathname } = location;

	const [artifactsCount, artifactsCountLoading] = useCollectionCount(getArtifactsQuery(firebase, { article }));

	const [bundleCount, bundleCountLoading] = useCollectionCount(
		article.work && getArticlesQuery(firebase, { work: article.work }),
	);

	const [productionsCount, productionsCountLoading] = api.productions.useCount((query) =>
		query.where("articleIds", "array-contains", article.id),
	);

	return [
		{
			to: "",
			active: pathname.endsWith(article.id),
			text: t("metaData", "Metadata"),
		},
		{
			to: "artifacts",
			active: pathname.endsWith("/artifacts"),
			text: t("files", "Files"),
			label: artifactsCount || article.artifactIds?.length,
			loading: artifactsCountLoading,
		},
		{
			to: "bundle",
			active: pathname.endsWith("/bundle"),
			text: t("relatedArticles", "Related articles"),
			label: bundleCount || undefined,
			loading: bundleCountLoading,
			hidden: !article.work,
		},
		{
			to: "distributions",
			active: pathname.endsWith("/distributions"),
			text: t("distributions", "Distributions"),
		},
		{
			to: "productions",
			active: pathname.endsWith("/productions"),
			text: t("productions", "Productions"),
			label: productionsCount || undefined,
			loading: productionsCountLoading,
		},
		{
			to: "versions",
			active: pathname.endsWith("/versions"),
			text: t("versions", "Versions"),
		},
		{
			to: "exports",
			active: pathname.endsWith("/exports"),
			text: t("exports", "Exports"),
		},
	];
}
