import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

export default function useArticleVersionMenuOptions() {
	const { t } = useTranslation();

	const location = useLocation();

	const { pathname } = location;

	return [
		{
			to: "after",
			active: pathname.endsWith("after"),
			text: t("after", "After"),
		},
		{
			to: "before",
			active: pathname.endsWith("before"),
			text: t("before", "Before"),
		},
		{
			to: "revert",
			active: pathname.endsWith("revert"),
			text: t("active", "Active"),
		},
	];
}
