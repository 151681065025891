import firebase from "firebase/app";

import { Modal, Popup } from "semantic-ui-react";

import React from "react";
import { useTranslation } from "react-i18next";

import articleDeliveryStatuses from "astrid-firestore/src/api/articles/constants/articleDeliveryStatuses";
import distributeArticles from "astrid-firestore/src/api/distribution/distributeArticles";
import { canDeliverArticlesToChannels } from "astrid-firestore/src/api/distribution/utils/deliverArticlesToChannels";
import { array, boolean, object, record, string } from "astrid-firestore/src/types/_types";
import stopPropagation from "astrid-helpers/src/stopPropagation";

import Flex from "../../../../components/Flex/Flex";
import PrimaryButton from "../../../ui/components/Buttons/PrimaryButton";
import SecondaryButton from "../../../ui/components/Buttons/SecondaryButton";
import CoverImage from "../../../ui/components/CoverImage/CoverImage";
import DataTable from "../../../ui/components/DataTable/DataTable";
import ErrorMessage from "../../../ui/components/Messages/ErrorMessage";

import Form from "../../../forms/components/Form/Form";
import useSimpleForm from "../../../forms/hooks/useSimpleForm";

import useDistributeArticlesChannelsTableColumns from "./hooks/useDistributeArticlesChannelsTableColumns";

const schema = object({
	channels: array(object()),
	delivery: record(string(), object({ artifact: boolean(), metadata: boolean() })),
}).refine(({ delivery }) => {
	return Object.values(delivery)
		.flatMap((obj) => Object.values(obj))
		.some(Boolean);
});

function autoSelectDelivery({ articles, channel, selectedChannelIds, deliveryType }) {
	return (
		selectedChannelIds.length === 0 &&
		articles?.length === 1 &&
		([
			articleDeliveryStatuses.DELIVERY_NOT_STARTED,
			articleDeliveryStatuses.DELIVERY_NEEDED,
			articleDeliveryStatuses.DELIVERY_FAILED,
		].includes(articles[0].channels[channel.id].delivery?.[deliveryType]?.status) ||
			!articles[0].channels[channel.id].delivery)
	);
}

export default function DistributeArticlesForm({ articles, channels, selectedChannelIds, onClose }) {
	const { t } = useTranslation();

	const { form, formState, onSubmit, error } = useSimpleForm({
		schema,
		defaultValues: {
			delivery: channels.reduce((acc, channel) => {
				return {
					...acc,
					[channel.id]: {
						artifact:
							(selectedChannelIds.includes(channel.id) ||
								autoSelectDelivery({
									articles,
									channel,
									selectedChannelIds,
									deliveryType: "artifact",
								})) &&
							canDeliverArticlesToChannels({
								channel,
								articles,
								deliveryType: "artifact",
							}),
						metadata:
							(selectedChannelIds.includes(channel.id) ||
								autoSelectDelivery({
									articles,
									channel,
									selectedChannelIds,
									deliveryType: "metadata",
								})) &&
							canDeliverArticlesToChannels({
								channel,
								articles,
								deliveryType: "metadata",
							}),
					},
				};
			}, {}),
		},
		transform: ({ delivery }) => {
			const channelIds = Object.keys(delivery);

			return {
				delivery,
				channels: channels.filter(({ id }) => channelIds.includes(id)),
			};
		},
		onSubmit: ({ channels, delivery }) => {
			return distributeArticles(firebase, { articles, channels, delivery });
		},
		onSuccess: onClose,
	});

	const columns = useDistributeArticlesChannelsTableColumns({ form, articles, channels: channels });

	return (
		<Modal open padded onClose={onClose} onKeyDown={stopPropagation} onClick={stopPropagation}>
			<Modal.Header>{t("distribute", "Distribute")}</Modal.Header>

			<Modal.Content>
				<Form form={form}>
					<DataTable data={channels} columns={columns} />

					{error && <ErrorMessage error={error} />}
				</Form>
			</Modal.Content>

			<Modal.Actions>
				<Flex gap={3}>
					{articles?.slice(0, 10).map((article) => {
						return (
							<Popup
								key={article.id}
								content={article.name}
								trigger={<CoverImage thumb rounded data={article} style={{ height: 35, width: 35 }} />}
							/>
						);
					})}

					<strong style={{ marginLeft: 5 }}>{articles.length > 10 && ` (+${articles.length - 10})`}</strong>

					<Flex gap={5} justifyContent="flex-end">
						<SecondaryButton content={t("cancel", "Cancel")} onClick={onClose} />

						<PrimaryButton
							disabled={!formState.isValid || formState.isSubmitting}
							loading={formState.isSubmitting}
							onClick={onSubmit}
						>
							{t("distribute", "Distribute")}
						</PrimaryButton>
					</Flex>
				</Flex>
			</Modal.Actions>
		</Modal>
	);
}
