import { intMinMax, literal, nullable, number, object, string } from "./_types";

export const StorageFile = object({
	bucket: string(),
	contentType: nullable(string()),
	fullPath: string(),
	generation: nullable(string()),
	md5Hash: nullable(string()),
	name: string(),
	size: nullable(number()),
	type: nullable(string()),
	url: string(),
	thumbUrl: nullable(string()),
});

export const AudioStorageFile = StorageFile.extend({
	duration: number().default(0),
	format: nullable(object().passthrough()),
});

export const AudioWavStorageFile = AudioStorageFile.extend({
	contentType: literal("audio/wav"),
});

export const AudioMp3StorageFile = AudioStorageFile.extend({
	contentType: literal("audio/mp3"),
});

// Non-audio files, like .epub, do not need duration
export const EpubStorageFile = StorageFile.extend({
	contentType: literal("application/epub+zip"),
	pages: nullable(intMinMax(1)),
});
