import articleDeliveryStatuses from "../constants/articleDeliveryStatuses";

const articleDeliveryStatusPriority = [
	articleDeliveryStatuses.DELIVERY_IN_PROGRESS,
	articleDeliveryStatuses.DELIVERY_FAILED,
	articleDeliveryStatuses.DELIVERY_NEEDED,
	articleDeliveryStatuses.DELIVERY_PENDING,
	articleDeliveryStatuses.DELIVERY_COMPLETED,
	articleDeliveryStatuses.DELIVERY_NOT_STARTED,
	articleDeliveryStatuses.DELIVERY_UNSUPPORTED,
];

export function getDeliveryStatusByPriority(statuses) {
	return articleDeliveryStatusPriority.find((status) => statuses.includes(status));
}

export function getArticleDeliveryStatus({ channelDeliveryStatus, article, distribution, deliveryType }) {
	const otherChannelDeliveryStatuses = Object.entries(article.channels || {})
		.filter(([channelId, _]) => {
			return channelId !== distribution.channel.id;
		})
		.map(([_, { delivery }]) => delivery?.[deliveryType]?.status);

	return getDeliveryStatusByPriority([channelDeliveryStatus, ...otherChannelDeliveryStatuses]);
}
