import { ArticleMetaData } from "../../articles/types/ArticleMetaData";

export const WorkMetaData = ArticleMetaData.pick({
	abridged: true,
	additionalLanguages: true,
	contributors: true,
	cover: true,
	imprint: true,
	language: true,
	name: true,
	subtitle: true,
	originalLanguage: true,
	originalName: true,
	serie: true,
	season: true,
	serieNumber: true,
	synopsis: true,
	tags: true,
	themaCodes: true,
});
