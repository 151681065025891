import { useConfigure } from "react-instantsearch";
import { useParams } from "react-router-dom";

import organizationTypes from "astrid-firestore/src/api/organizations/constants/organizationTypes";

import useRefinements from "../../hooks/useRefinements";

import RefinementAccordion from "./RefinementAccordion";

export default function FiltersAndRefinements({ type, selectedColumns, selectColumn }) {
	const refinements = useRefinements(type);
	const { orgId } = useParams();
	useConfigure({ filters: type !== organizationTypes.ADMIN ? `${type}:${orgId}` : "" });

	return (
		<RefinementAccordion refinements={refinements} selectedColumns={selectedColumns} selectColumn={selectColumn} />
	);
}
