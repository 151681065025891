import articleTypes from "astrid-firestore/src/api/articles/constants/articleTypes";

import getDocumentData from "../../src/utils/getDocumentData";

import createArticleDelivery from "./createArticleDelivery";
import createAudioArtifact from "./createAudioArtifact";
import createChannels from "./createChannels";
import createCover from "./createCover";
import createDocumentIdAndRef from "./createDocumentIdAndRef";

export default async function createAudiobooksFromMulti({ api, env }, { production, publisher, commonArticleData }) {
	const fullPublisherDoc = await getDocumentData(api[env].db.collection("organizations").doc(publisher.id));
	return await Promise.all(
		production.deliveryParts.map(async (part, index) => {
			const { id, ref } = createDocumentIdAndRef({ api, env }, { collection: "articles" });
			const isNotASeries = !production.deliveryPartsTitle && !production.series && !production.part;
			const article = {
				...commonArticleData,
				id,
				ref,
				type: articleTypes.AUDIOBOOK,
				isbn: part.isbn,
				synopsis: part.synopsis || production.synopsis || null,
				name: isNotASeries ? production.title : part.title,
				subtitle: isNotASeries ? part.title : null,
				serieNumber: isNotASeries ? null : index + 1,
			};
			const cover = await createCover(
				{ api, env },
				{
					img: production.deliveryPartsCover?.[index]?.img,
					imgThumb: production.deliveryPartsCover?.[index]?.imgThumb,
				},
			);
			const artifact = await createAudioArtifact(
				{ api, env },
				{
					production,
					article,
					articleType: `part_${index}`,
				},
			);
			const duration = artifact?.files?.reduce?.((sum, file) => sum + file.duration, 0) || 0;
			const channels = await createChannels(
				{ api, env },
				{
					article,
					fullPublisherDoc,
					production,
					articleType: `part_${index}`,
				},
			);
			const delivery = createArticleDelivery({ channels });
			return {
				...article,
				artifact,
				cover,
				duration,
				channels,
				channelIds: Object.keys(channels),
				delivery,
			};
		}),
	);
}
