import React from "react";
import { useOutletContext, useParams } from "react-router-dom";

import db from "astrid-firebase/src/db";
import useDocumentData from "astrid-firestore/src/hooks/useDocumentData";

import LoadingContext from "../../ui/components/LoadingContext/LoadingContext";

import useHasRight from "../../authorization/hooks/useHasRight";

import UserView from "../views/UserView/UserView";

export default function User() {
	const { id } = useParams();
	const { organization } = useOutletContext();

	const [user, loading, error] = useDocumentData(id && db.collection("users").doc(id));

	const hasRight = useHasRight();

	const inOrganization =
		user?.permissions?.[organization.type]?.[organization.id] || user?.organizationIds?.includes(organization.id);

	return (
		<LoadingContext
			data={inOrganization && user}
			loading={loading}
			error={error}
			authorized={hasRight("users.view")}
		>
			<UserView user={user} organization={organization} />
		</LoadingContext>
	);
}
