import VerticalMenu from "../../../ui/components/VerticalMenu/VerticalMenu";
import useBreadcrumbs from "../../../ui/hooks/useBreadcrumbs";
import PageView from "../../../ui/views/PageView/PageView";

import useSerieMenuOptions from "./hooks/useSerieMenuOptions";

export default function SerieView({ serie, children }) {
	const breadcrumbs = useBreadcrumbs({ serie });

	const menuOptions = useSerieMenuOptions({ serie });

	return (
		<PageView breadcrumbs={breadcrumbs} header={serie.name} sidebar={<VerticalMenu options={menuOptions} />}>
			{children}
		</PageView>
	);
}
