import money from "astrid-helpers/src/money";

import i18n from "../../../i18n";

export function formatPrice({ unit, price, currency, percentage }, t = (...args) => i18n.t(...args)) {
	const units = {
		time: `/${t("unitTime", "min")}`,
		hour: `/${t("unitHour", "hour")}`,
		activity: `/${t("unitActivity", "w.min")}`,
	};

	return unit === "percentage"
		? `${percentage || price}% of costs`
		: `${money(price, currency, 2)}${units[unit] || ""}`;
}

export function formatNamedPrice(price) {
	return `${price.name && `${price.name} (`}${formatPrice(price)}${price.name && ")"}`;
}
