import arrayChunk from "astrid-helpers/src/arrayChunk";

import getCollectionData from "./getCollectionData";

export default async function getCollectionDataByArray(firebase, collection, key, array = [], operator = "in") {
	const uniqueArray = [...new Set(array)];

	if (uniqueArray.length === 0) {
		return [];
	}

	const chunks = await Promise.all(
		arrayChunk(uniqueArray, 10).map((chunk) => getCollectionData(collection.where(key, operator, chunk))),
	);

	return chunks.flat();
}
