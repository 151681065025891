import organizationTypes from "astrid-firestore/src/api/organizations/constants/organizationTypes";

import { localStorage } from "../../../helpers/fnc";

import { setOrganizationId } from "../state/organizationId";
import { setOrganizationType } from "../state/organizationType";

export default function selectOrganization({ id, type, user, legacySetProducerId }) {
	setOrganizationId(id);
	setOrganizationType(type);

	switch (type) {
		case organizationTypes.PRODUCER:
			legacySetProducerId(id);
			break;
		case organizationTypes.PUBLISHER:
		case organizationTypes.NARRATOR:
		case organizationTypes.ADMIN:
			legacySetProducerId(undefined);
			break;
		default:
			break;
	}

	localStorage("organizations", user.id, {
		id: id,
		type: type,
	});
}
