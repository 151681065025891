import { useTranslation } from "react-i18next";
import { useLocation, useMatches } from "react-router-dom";

import truncateString from "../utils/truncateString";

export default function useBreadcrumbs(props) {
	const { t } = useTranslation();

	const matches = useMatches();
	const { state } = useLocation();

	return matches
		.filter((match) => match.handle?.crumb)
		.map((match, index, crumbs) => ({
			linkTo: index < crumbs.length - 1 ? match.pathname : undefined,
			text: truncateString(match.handle.crumb({ t, match, state, ...props }), 20),
		}));
}
