import React from "react";
import { useTranslation } from "react-i18next";

import { dropdownMenuColumn, timestampColumn } from "../../ui/utils/columns";

import ArtifactDefaultArticleArtifactIcon from "../components/ArtifactIcons/ArtifactDefaultArticleArtifactIcon";
import ArtifactStatusLabel from "../components/ArtifactStatus/ArtifactStatusLabel";

import useArtifactDropdownMenuOptions from "./useArtifactDropdownMenuOptions";

export default function useArtifactsTableColumns({ article } = {}) {
	const { t } = useTranslation();

	return [
		article && {
			id: "default",
			collapsing: true,
			Cell: ({ row }) => {
				return <ArtifactDefaultArticleArtifactIcon article={article} artifact={row.original} />;
			},
		},
		{
			id: "status",
			Header: t("status", "Status"),
			collapsing: true,
			Cell: ({ row }) => {
				return <ArtifactStatusLabel artifact={row.original} />;
			},
		},
		{
			id: "name",
			Header: t("Name", "Name"),
		},
		{
			id: "contentType",
			Header: t("contentType", "Content-Type"),
			accessor: ({ files }) => files[0]?.contentType,
		},
		{
			id: "files",
			Header: t("quantity", "Quantity"),
			accessor: ({ files }) => files.length,
		},
		timestampColumn({ id: "created", Header: t("created", "Created") }),
		dropdownMenuColumn(useArtifactDropdownMenuOptions({ article })),
	];
}
