import equal from "fast-deep-equal";

import { Icon } from "semantic-ui-react";

import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { firebase } from "astrid-firebase";
import articleTypes from "astrid-firestore/src/api/articles/constants/articleTypes";
import linkedArticleTypes from "astrid-firestore/src/api/articles/constants/linkedArticleTypes";
import { Article } from "astrid-firestore/src/api/articles/types/Article";
import updateArticle from "astrid-firestore/src/api/articles/updateArticle";
import { createImprintData } from "astrid-firestore/src/api/imprints/createImprintData";
import { createSerieData } from "astrid-firestore/src/api/series/createSerieData";
import { Season } from "astrid-firestore/src/api/series/types/Season";

import api from "../../../../../api";
import Flex from "../../../../../components/Flex/Flex";
import FlexRow from "../../../../../components/Flex/FlexRow";
import AdminCard from "../../../../admin/components/AdminCard/AdminCard";
import Form from "../../../../forms/components/Form/Form";
import useSimpleForm from "../../../../forms/hooks/useSimpleForm";
import ThemaModal from "../../../../thema/components/ThemaModal";
import PrimaryButton from "../../../../ui/components/Buttons/PrimaryButton";
import Message from "../../../../ui/components/Messages/Message";
import useNumberOptions from "../../../../ui/hooks/useNumberOptions";
import SyncWorkMetaDataButton from "../../../../works/components/SyncWorkMetadataForm/SyncWorkMetaDataButton";

import ArticleTags from "../../../components/ArticleTags/ArticleTags";
import ArticlesInstantSearchSelect from "../../../components/ArticlesInstantSearchSelect/ArticlesInstantSearchSelect";
import useArticleAbridgedOptions from "../../../hooks/useArticleAbridgedOptions";
import useArticleContributorRolesOptions from "../../../hooks/useArticleContributorRolesOptions";
import useSeasonOptions from "../../../hooks/useSeasonOptions";

const MetadataLabel = ({ text, condition }) => {
	return (
		<label>
			<Flex justifyContent="flex-start">
				{text}
				<div style={{ position: "relative" }}>
					{condition && (
						<Icon
							size="small"
							color="blue"
							name="asterisk"
							style={{ fontSize: 8, position: "absolute", bottom: 0 }}
						/>
					)}
				</div>
			</Flex>
		</label>
	);
};

export default function ArticleMetaDataForm({ work, article, locked, ...props }) {
	const { publisher } = article;

	const { t } = useTranslation();

	const numberOptions = useNumberOptions();
	const abridgedOptions = useArticleAbridgedOptions();
	const contributorRolesOptions = useArticleContributorRolesOptions();

	const [seriesOptions] = api.series.useOptions({ publisher });
	const [imprintOptions] = api.imprints.useOptions({ publisher });

	const [openModal, setOpenModal] = useState(false);

	const { form, watch, formState, setValue, onSubmit } = useSimpleForm({
		schema: Article,
		values: {
			ref: article.ref,
			abridged: article.abridged || false,
			additionalLanguages: article.additionalLanguages || null,
			contributors: article.contributors || [],
			duration: article.duration || 0,
			imprint: article.imprint || null,
			isbn: article.isbn || null,
			language: article.language || null,
			linkedArticle: article.linkedArticle || null,
			name: article.name || null,
			subtitle: article.subtitle || null,
			originalLanguage: article.originalLanguage || null,
			originalName: article.originalName || null,
			serie: article.serie || null,
			season: article.season || null,
			serieNumber: article.serieNumber || null,
			synopsis: article.synopsis || null,
			tags: article.tags || [],
			themaCodes: article.themaCodes || null,
		},
		onSubmit: (data) => {
			return updateArticle(firebase, data);
		},
		...props,
	});

	const [themaCodes, language] = watch(["themaCodes", "language"]);
	const serie = watch("serie");

	const seasonOptions = useSeasonOptions({ serie, seriesOptions });

	return (
		<AdminCard
			header={t("metaData", "Meta data")}
			extra={
				<FlexRow style={{ gap: 10 }}>
					<PrimaryButton
						disabled={formState.isSubmitting || !formState.isDirty}
						loading={formState.isSubmitting}
						onClick={onSubmit}
					>
						{t("save", "Save")}
					</PrimaryButton>
					{work && (
						<SyncWorkMetaDataButton
							work={work}
							articles={[article]}
							text={t("syncFromWork", "Sync from work")}
						/>
					)}
				</FlexRow>
			}
		>
			<Form form={form}>
				<Form.Group widths="equal">
					<Form.Input name="isbn" label={t("isbn", "ISBN")} />

					<Form.Input
						name="name"
						label={
							<MetadataLabel
								condition={work && !equal(article.name, work.name)}
								text={t("title", "Title")}
							/>
						}
					/>
					<Form.Input
						name="subtitle"
						label={
							<MetadataLabel
								condition={work && !equal(article.subtitle, work.subtitle)}
								text={t("subtitle", "Subtitle")}
							/>
						}
					/>
					<Form.Input
						name="originalName"
						label={
							<MetadataLabel
								condition={work && !equal(article.originalName, work.originalName)}
								text={t("titleOriginal", "Original title")}
							/>
						}
					/>
				</Form.Group>

				<Form.Group widths="equal">
					<Form.DocumentSelect
						name="imprint"
						label={
							<MetadataLabel
								condition={work && !equal(article.imprint, work.imprint)}
								text={t("imprint", "Imprint")}
							/>
						}
						search
						allowEmpty
						allowAdditions
						options={imprintOptions}
						onAddDocument={(name) => {
							return createImprintData(firebase, { name, publisher });
						}}
					/>

					<ArticlesInstantSearchSelect
						name="linkedArticle"
						label={t("linkedArticle", "Linked article")}
						fluid
						allowEmpty
						clearable
						publisher={publisher}
						disabled={article.type === articleTypes.EBOOK}
						type={linkedArticleTypes[article.type]}
					/>

					<Form.Select
						name="abridged"
						label={
							<MetadataLabel
								condition={work && !equal(article.abridged, work.abridged)}
								text={t("abridged", "Abridged")}
							/>
						}
						options={abridgedOptions}
					/>
				</Form.Group>

				<Form.TextArea
					name="synopsis"
					label={
						<MetadataLabel
							condition={work && !equal(article.synopsis, work.synopsis)}
							text={t("synopsis", "Description")}
						/>
					}
				/>

				<Form.Group widths="equal">
					<Form.DocumentSelect
						name="serie"
						label={
							<MetadataLabel
								condition={work && !equal(article.serie, work.serie)}
								text={t("serie", "Series")}
							/>
						}
						search
						allowEmpty
						allowAdditions
						options={seriesOptions}
						onChangeOption={() => {
							setValue("season", null);
						}}
						onAddDocument={(name) => {
							return createSerieData(firebase, { name, publisher });
						}}
					/>

					<Form.DocumentSelect
						name="season"
						label={
							<MetadataLabel
								condition={work && !equal(article.season, work.season)}
								text={t("season", "Season")}
							/>
						}
						disabled={!serie}
						search
						allowEmpty
						allowAdditions
						options={seasonOptions}
						onAddDocument={(name) => {
							return Season.parse({ name });
						}}
					/>

					<Form.Select
						name="serieNumber"
						disabled={!serie}
						search
						label={
							<MetadataLabel
								condition={work && article.serieNumber !== work.serieNumber}
								text={t("part", "Part")}
							/>
						}
						allowEmpty
						options={numberOptions}
					/>
				</Form.Group>

				<Form.Group widths="equal">
					<Form.LanguageSelect
						name="language"
						label={
							<MetadataLabel
								condition={work && article.language !== work.language}
								text={t("language", "Language")}
							/>
						}
						clearable
					/>

					<Form.LanguageSelect
						name="originalLanguage"
						label={
							<MetadataLabel
								condition={work && article.originalLanguage !== work.originalLanguage}
								text={t("originalLanguage", "Original language")}
							/>
						}
						clearable
					/>

					<Form.LanguageSelect
						name="additionalLanguages"
						label={
							<MetadataLabel
								condition={work && !equal(article.additionalLanguages, work.additionalLanguages)}
								text={t("otherLanguages", "Other occuring languages")}
							/>
						}
						filterOptions={(option) => option.value !== language}
						multiple
						clearable
					/>
				</Form.Group>

				<Form.Group widths="equal">
					<Form.DurationInput
						fluid
						name="duration"
						label={t("duration", "Duration") + " (hhh:mm:ss)"}
						disabled={article.type === articleTypes.EBOOK}
					/>
					<Form.Select
						multiple
						name="themaCodes"
						label={
							<MetadataLabel
								condition={work && !equal(article.themaCodes, work.themaCodes)}
								text={t("themaCodes", "Thema codes")}
							/>
						}
						options={themaCodes?.map((code) => ({
							key: code,
							text: code,
							value: code,
						}))}
						allowEmpty
						onClick={() => !locked && setOpenModal(true)}
					/>

					<Form.Field name="tags">
						<ArticleTags
							article={article}
							label={
								<MetadataLabel
									condition={work && !equal(article.tags, work.tags)}
									text={t("tags", "Tags")}
								/>
							}
						/>
					</Form.Field>
				</Form.Group>

				<Form.FormFieldArrayTable
					name="contributors"
					header={
						<MetadataLabel
							condition={work && !equal(article.contributors, work.contributors)}
							text={t("contributors", "Contributors")}
						/>
					}
				>
					{({ role, firstName, lastName }, index) => (
						<Form.Group widths="equal" style={{ margin: 0 }}>
							<Form.Select
								name={`contributors.${index}.role`}
								value={role}
								placeholder={t("role", "Role")}
								options={contributorRolesOptions}
							/>
							<Form.Input
								name={`contributors.${index}.firstName`}
								value={firstName}
								placeholder={t("firstName", "First name")}
							/>
							<Form.Input
								name={`contributors.${index}.lastName`}
								value={lastName}
								placeholder={t("lastName", "Last name")}
							/>
						</Form.Group>
					)}
				</Form.FormFieldArrayTable>

				{work && (
					<Message>
						<Flex justifyContent="flex-start">
							<Icon size="small" color="blue" name="asterisk" />
							{t("dataDifferesFromWork", "Data differes from work")}
						</Flex>
					</Message>
				)}

				{openModal && (
					<ThemaModal
						value={themaCodes}
						onClose={({ themaCodes }) => {
							setValue("themaCodes", themaCodes, { shouldDirty: true });
							setOpenModal(false);
						}}
					/>
				)}

				<Form.Blocker />
			</Form>
		</AdminCard>
	);
}
