import { Navigate, Outlet } from "react-router-dom";

import Messages from "../../../../components/Messages/Messages";
import LoadingContext from "../../../ui/components/LoadingContext/LoadingContext";

import { useProfile } from "../../../authentication/state/profile";
import { useUser } from "../../../authentication/state/user";
import useCurrentOrganization from "../../../organizations/hooks/useCurrentOrganization";

import MainMenu from "../../components/MainMenu/MainMenu";

export default function SiteLayout() {
	const user = useUser();
	const profile = useProfile();

	const [organization, loading, error] = useCurrentOrganization();
	if (user === null) {
		return <Navigate to="/login" />;
	}

	return (
		<LoadingContext data={organization} loading={user === undefined || !profile || loading} error={error}>
			<MainMenu organization={organization} />

			<div style={{ padding: 15 }}>
				<Messages organization={organization} />

				<Outlet context={{ organization }} />
			</div>
		</LoadingContext>
	);
}
