import { useOutletContext, useParams } from "react-router-dom";

import useDocumentDataOnce from "astrid-firestore/src/hooks/useDocumentDataOnce";

import LoadingContext from "../../ui/components/LoadingContext/LoadingContext";
import Unauthorized from "../../ui/components/Unauthorized/Unauthorized";

import featureFlags from "../../authorization/constants/featureFlags";
import useFeatureFlag from "../../authorization/hooks/useFeatureFlag";

import ArticleVersionView from "../views/ArticleView/ArticleVersionView";

export default function ArticleVersion() {
	const params = useParams();
	const context = useOutletContext();
	const hasFlatteningFeature = useFeatureFlag(featureFlags.FLATTENING);

	const [version, loading, error] = useDocumentDataOnce(
		context.article.ref.collection("versions").doc(params.version),
	);

	if (!hasFlatteningFeature) {
		return <Unauthorized />;
	}

	return (
		<LoadingContext data={version} loading={loading} error={error}>
			<ArticleVersionView version={version} {...context} />
		</LoadingContext>
	);
}
