import { Button, Modal } from "semantic-ui-react";

import React from "react";
import { useTranslation } from "react-i18next";

export default function AlertModal({ onClose, header, text }) {
	const { t } = useTranslation();

	return (
		<Modal open onClose={onClose} closeOnDimmerClick size="tiny">
			{header && <Modal.Header>{header}</Modal.Header>}

			{text && (
				<Modal.Content>
					{text.split("\n").map((row, index) => (
						<p key={index}>{row}</p>
					))}
				</Modal.Content>
			)}

			<Modal.Actions>
				<Button primary content={t("ok", "Ok")} onClick={onClose} />
			</Modal.Actions>
		</Modal>
	);
}
