import { Outlet, useParams } from "react-router-dom";

import { db } from "astrid-firebase";
import { useDocumentData } from "astrid-firestore/src/hooks";

import NotFound from "../../../../components/NotFound";
import LoadingContext from "../../../ui/components/LoadingContext/LoadingContext";
import useHistory from "../../../ui/hooks/useHistory";

import { useProfile } from "../../../authentication/state/profile";
import { useUser } from "../../../authentication/state/user";

import ProductionView from "../../views/ProductionView/ProductionView";

export default function Production() {
	const history = useHistory();
	const { productionId, modal } = useParams();

	const user = useUser();
	const profile = useProfile();

	const [production, loading, error] = useDocumentData(db.collection("productions").doc(productionId));

	if (!loading && (!production || production?.deleted)) {
		return <NotFound />;
	}

	return (
		<LoadingContext data={production} loading={loading} error={error}>
			<ProductionView modal={modal} production={production} history={history}>
				<Outlet context={{ user, profile, history, production }} />
			</ProductionView>
		</LoadingContext>
	);
}
