import { useTranslation } from "react-i18next";

import LoadingContext from "../../../ui/components/LoadingContext/LoadingContext";

import AdminCard from "../../../admin/components/AdminCard/AdminCard";
import Pipeline from "../../../pipelines/components/Pipeline/Pipeline";

export default function ExportPipelineView({ export: theExport }) {
	const { t } = useTranslation();

	return (
		<AdminCard header={t("pipeline", "Pipeline")}>
			<LoadingContext data={theExport.pipeline?.steps}>
				<Pipeline document={theExport} />
			</LoadingContext>
		</AdminCard>
	);
}
