import { Card } from "semantic-ui-react";

import db from "astrid-firebase/src/db";
import agreementStatuses from "astrid-firestore/src/api/agreements/constants/agreementStatuses";
import isApproved from "astrid-firestore/src/api/approval/isApproved";
import useDocumentData from "astrid-firestore/src/hooks/useDocumentData";

import PreviewAgreementPdfButton from "../../../../components/PreviewAgreementPdf/PreviewAgreementPdfButton";
import LoadingContext from "../../../ui/components/LoadingContext/LoadingContext";

import AdminActions from "../../../admin/components/AdminActions/AdminActions";

import TerminationAction from "../../components/AgreementActions/TerminationAction";
import AgreementAppendixes from "../../components/AgreementAppendixes/AgreementAppendixes";

import AgreementInfo from "./components/AgreementInfo";
import AgreementParties from "./components/AgreementParties";
import AgreementPrices from "./components/AgreementPrices";
import AgreementSigning from "./components/AgreementSigning";

export default function AgreementView({ id }) {
	const [agreement, loading, error] = useDocumentData(id && db.collection("agreements").doc(id));

	return (
		<LoadingContext loading={loading} error={error} data={agreement}>
			{agreement && (
				<>
					<AgreementInfo agreement={agreement} />
					<AgreementParties agreement={agreement} />
					<AgreementAppendixes appendixes={agreement.appendixes} />
					{Object.keys(agreement || {}).length > 0 && <AgreementPrices agreement={agreement} />}
					{isApproved(agreement) && <AgreementSigning agreement={agreement} />}
					<AdminActions data={agreement}>
						{!agreement.signedAgreementFile?.url && (
							<Card.Content extra>
								<PreviewAgreementPdfButton request={() => agreement} />
							</Card.Content>
						)}
						<AdminActions.ApprovalAction
							right="approval"
							getData={(action) => {
								return {
									status:
										action === "reset"
											? agreementStatuses.PENDING_APPROVAL
											: action === "approve"
											? agreementStatuses.APPROVED
											: agreementStatuses.APPROVAL_DENIED,
								};
							}}
						/>
						<TerminationAction agreement={agreement} />
						<AdminActions.DeletionAction
							right="agreements.delete"
							disabled={agreement.state === "SIGNED"}
						/>
					</AdminActions>
				</>
			)}
		</LoadingContext>
	);
}
