import { Route } from "react-router-dom";

import organizationTypes from "astrid-firestore/src/api/organizations/constants/organizationTypes";

import CompletedProductions from "../pages/CompletedProductions";
import MyProductionOffers from "../pages/MyProductionOffers";
import MyProductions from "../pages/MyProductions";
import OngoingProductions from "../pages/OngoingProductions";
import ProductionRequests from "../pages/ProductionRequests";
import SentOffers from "../pages/SentOffers";

export const myProductionsPublisher = (
	<Route path="my-productions" element={<MyProductions type={organizationTypes.PUBLISHER} />}>
		<Route path="current" element={<OngoingProductions />} />
		<Route path="completed" element={<CompletedProductions />} />
	</Route>
);

export const myProductionsNarrator = (
	<Route path="my-productions" element={<MyProductions type={organizationTypes.NARRATOR} />}>
		<Route path="current" element={<OngoingProductions />} />
		<Route path="completed" element={<CompletedProductions />} />
		<Route path="offers" element={<MyProductionOffers />} />
		<Route path="offers/:id" element={<MyProductionOffers />} />
	</Route>
);

export const myProductionsProducer = (
	<Route path="my-productions" element={<MyProductions type={organizationTypes.PRODUCER} />}>
		<Route path="current" element={<OngoingProductions />} />
		<Route path="completed" element={<CompletedProductions />} />
		<Route path="offers" element={<MyProductionOffers />} />
		<Route path="sent" element={<SentOffers />} />
		<Route path="offers/:id" element={<MyProductionOffers />} />
		<Route path="requests" element={<ProductionRequests />} />
	</Route>
);
