import uniqid from "uniqid";

import { audioBufferFromURL } from "./audioBuffer";
import eventTarget from "./eventTarget";
import raf from "./raf";

const context = new (window.AudioContext || window.webkitAudioContext)({});

export default function createTimelinePlayer({ zero = 0.0001, fade = 0.0005, preload = 5 } = {}) {
	context.suspend();

	let unsubcribe;
	let stopped = true;

	const sounds = new Map();
	const events = eventTarget();

	const destination = context.createGain();

	const addSound = ({ position = 0, length = 0, volume = 1, getBuffer }) => {
		const id = uniqid();

		const sound = {
			position,
			volume,
			length,
			stop() {},
			async queue(position) {
				const startTime = context.currentTime + Math.max(0, this.position - position);
				const startFrom = Math.max(0, position - this.position);
				const length = Math.max(0, this.length - startFrom);

				const gain = context.createGain();
				const source = context.createBufferSource();

				gain.gain.value = 0;

				if (position >= this.position) {
					source.buffer = await getBuffer();
				} else {
					const onTime = async (time) => {
						if (preload + position + time >= this.position) {
							this.unsubscribe();

							source.buffer = await getBuffer();
						}
					};

					events.on("time", onTime);

					this.unsubscribe = () => events.off("time", onTime);
				}

				source.connect(gain);
				gain.connect(destination);

				gain.gain.setValueAtTime(zero, startTime);
				gain.gain.linearRampToValueAtTime(this.volume, startTime + fade);
				gain.gain.setValueAtTime(this.volume, startTime + length);
				gain.gain.linearRampToValueAtTime(zero, startTime + length);

				source.start(startTime, startFrom, length);

				this.stop = () => {
					source.stop();

					gain.disconnect();
					source.disconnect();

					this.unsubscribe?.();
					this.stop = () => {};
				};
			},
		};

		sounds.set(id, sound);
	};

	const addSoundFromUrl = ({ url, ...props }) => {
		return addSound({
			...props,
			getBuffer: () => audioBufferFromURL(url),
		});
	};

	const stop = () => {
		stopped = true;

		unsubcribe?.();

		destination.disconnect(context.destination);

		sounds.forEach((sound) => sound.stop());

		events.emit("stop");
	};

	const play = async (time = 0) => {
		stopped = false;

		context.suspend();
		destination.connect(context.destination);

		const startTime = context.currentTime || 0;

		const getTime = () => context.currentTime - startTime;

		unsubcribe = raf(() => {
			events.emit("time", getTime());
		}, 50);

		const filteredSounds = Array.from(sounds.values()).filter(({ position, length }) => position + length > time);

		await Promise.all(filteredSounds.map((sound) => sound.queue(time)));

		if (!stopped) {
			context.resume();
		}
	};

	return {
		play,
		stop,
		addSound,
		addSoundFromUrl,
		events,
	};
}
