import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";

import PrimaryButton from "../../../ui/components/Buttons/PrimaryButton";
import CollectionDataTable from "../../../ui/components/CollectionDataTable/CollectionDataTable";

import AdminHeader from "../../../admin/components/AdminHeader/AdminHeader";

import useAgreementTemplatesQuery from "../../hooks/useAgreementTemplatesQuery";

import useAgreementTemplatesTableColumns from "./hooks/useAgreementTemplatesTableColumns";

export default function AgreementTemplatesView({ firstPartyId }) {
	const { t } = useTranslation();
	const navigate = useNavigate();

	const query = useAgreementTemplatesQuery({ firstPartyId });
	const columns = useAgreementTemplatesTableColumns();

	return (
		<>
			<AdminHeader text={t("agreementTemplates", "Agreement templates")}>
				<PrimaryButton as={Link} to="../templates/create">
					{t("createAgreementTemplate", "Create agreement template")}
				</PrimaryButton>
			</AdminHeader>

			<CollectionDataTable
				persist
				query={query}
				columns={columns}
				onClick={({ id }) => navigate(id)}
				sortBy={{
					id: "created",
					desc: true,
				}}
			/>
		</>
	);
}
