import { useOutletContext } from "react-router-dom";

import { useProfile } from "../../authentication/state/profile";

import DocumentsView from "../views/DocumentsView/DocumentsView";

export default function Documents() {
	const profile = useProfile();

	const { organization } = useOutletContext();

	return <DocumentsView profile={profile} organization={organization} />;
}
