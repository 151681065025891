import React from "react";
import { useTranslation } from "react-i18next";

import db from "astrid-firebase/src/db";
import useLoadMore from "astrid-firestore/src/hooks/useLoadMore";

import LoadMore from "../../../../components/LoadMore/LoadMore";
import DataTable from "../../../ui/components/DataTable/DataTable";
import LoadingContext from "../../../ui/components/LoadingContext/LoadingContext";

import AdminHeader from "../../../admin/components/AdminHeader/AdminHeader";

import useInvoiceColumns from "./hooks/useInvoicesTableColumns";

export default function InvoicesView({ header, firstPartyId }) {
	const { t } = useTranslation();

	const [invoices = [], loading, error, loadMore] = useLoadMore(
		firstPartyId &&
			db.collection("invoices").where("organization.id", "==", firstPartyId).orderBy("created", "desc"),
		{ limit: 30 },
	);

	const columns = useInvoiceColumns();

	return (
		<>
			<AdminHeader>{header}</AdminHeader>

			<LoadingContext data={invoices} error={error}>
				<DataTable
					pagination={false}
					sortable
					filterable
					columns={columns}
					data={invoices}
					sortBy={{
						id: "invoiceNumber",
						desc: true,
					}}
				/>

				<LoadMore loading={loading} error={error} onClick={loadMore}>
					{t("showMore")}
				</LoadMore>
			</LoadingContext>
		</>
	);
}
