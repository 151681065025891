import { Header } from "semantic-ui-react";

import Flex from "../../../../../components/Flex/Flex";
import FlexRow from "../../../../../components/Flex/FlexRow";
import AdminPageHeader from "../../../../admin/components/AdminPage/AdminPageHeader";
import useExportDropdownMenuOptions from "../../../../articles/views/ArticleView/hooks/useExportDropdownMenuOptions";
import CloudRunStatusLabel from "../../../../distribution/components/CloudRunStatus/CloudRunStatusLabel";
import PipelineStatusLabel from "../../../../distribution/components/DistributionStatus/PipelineStatusLabel";
import RetryPipeline from "../../../../distribution/components/RetryDistribution/RetryPipeline";

export default function ExportHeader({ export: theExport }) {
	const dropdownMenuOptions = useExportDropdownMenuOptions();

	return (
		<AdminPageHeader error={theExport.error} dropdownMenuOptions={dropdownMenuOptions(theExport)}>
			<Flex>
				<Header style={{ margin: 0 }}>
					<Header.Subheader>{theExport.id}</Header.Subheader>

					{theExport.article.name}
				</Header>

				<FlexRow style={{ gap: 10 }}>
					<PipelineStatusLabel distribution={theExport} />
					<RetryPipeline document={theExport} collectionId="exports" />
					<CloudRunStatusLabel distribution={theExport} />
				</FlexRow>
			</Flex>
		</AdminPageHeader>
	);
}
