import { Loader } from "semantic-ui-react";

import { useEffect } from "react";

import ErrorMessage from "astrid-web/src/features/ui/components/Messages/ErrorMessage";

import ItemDeletedMessage from "../../../../components/ItemDeletedMessage";
import NoDataAvailable from "../../../../components/NoDataAvailable";
import NotFound from "../../../../components/NotFound";

import useFeatureFlag from "../../../authorization/hooks/useFeatureFlag";

import Unauthorized from "../Unauthorized/Unauthorized";

export default function LoadingContext({
	data,
	error = false,
	loading = false,
	loader = null,
	authorized = true,
	loaderMinHeight = "150px",
	featureFlag,
	children,
	onLoaded,
	noDataAvailableMessage,
}) {
	const hasFeatureFlag = useFeatureFlag(featureFlag);

	useEffect(() => {
		if (onLoaded && !loading) {
			onLoaded();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [loading]);

	if (loading) {
		return (
			loader || (
				<div style={{ position: "relative", minHeight: loaderMinHeight }}>
					<Loader active size="large" inline="centered" />
				</div>
			)
		);
	}

	if (error) {
		return <ErrorMessage error={error}>{error?.message || error}</ErrorMessage>;
	}

	if (data === false) {
		return <NotFound />;
	}

	if (!authorized || !hasFeatureFlag) {
		return <Unauthorized />;
	}

	if (data?.length === 0) {
		return <NoDataAvailable message={noDataAvailableMessage} />;
	}

	if (data?.deleted) {
		return <ItemDeletedMessage id={data.id} />;
	}

	return children || null;
}
