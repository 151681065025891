import firebase from "firebase/app";

import React, { useState } from "react";

import artifactStatuses from "astrid-firestore/src/api/artifacts/constants/artifactStatuses";
import updateArtifact from "astrid-firestore/src/api/artifacts/updateArtifact";
import useBucket from "astrid-hooks/src/useBucket";

import ErrorMessage from "../../../ui/components/Messages/ErrorMessage";

import { useProfile } from "../../../authentication/state/profile";
import Form from "../../../forms/components/Form/Form";
import useForm from "../../../forms/hooks/useForm";

import UploadingArtifactModal from "../UploadingArtifactModal/UploadingArtifactModal";

import ArtifactDataTableForm from "./components/ArtifactDataTableForm";

export default function ManageArtifactFiles({ artifact }) {
	const user = useProfile();
	const bucket = useBucket("earselect-artifacts");

	const form = useForm({
		values: {
			files: artifact?.files,
		},
	});

	const [showUploadModal, setShowUploadModal] = useState(false);

	const { error, handleSubmit, reset } = form;

	const onSubmit = async ({ files }) => {
		const toBeAddedToBucket = files.filter((file) => !file.url);

		if (toBeAddedToBucket?.length) {
			setShowUploadModal(true);

			const newlyAddedFiles = await bucket.putFiles(artifact.id, toBeAddedToBucket);

			const updatedFiles = files.map((file) => {
				if (!file.url) {
					const newFile = newlyAddedFiles.find((newFile) => newFile.name === file.name);
					return {
						...newFile,
						duration: file.duration,
					};
				}
				return file;
			});

			await updateArtifact(firebase, {
				...artifact,
				files: updatedFiles,
				status: artifactStatuses.PENDING,
				updatedBy: user,
			});

			reset({ files: updatedFiles });

			setShowUploadModal(false);
		} else {
			await updateArtifact(firebase, {
				...artifact,
				files,
				status: artifactStatuses.PENDING,
				updatedBy: user,
			});

			reset({ files });
		}
	};

	return (
		<>
			<Form form={form}>
				<ArtifactDataTableForm artifact={artifact} onSubmit={handleSubmit(onSubmit)} />

				{error && <ErrorMessage error={error} />}
			</Form>

			{showUploadModal && <UploadingArtifactModal />}
		</>
	);
}
