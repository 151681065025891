import firebase from "firebase/app";

import React, { useCallback, useMemo, useState } from "react";

import Timeline from "astrid-components/lib/components/Audio/Timeline";
import Divider from "astrid-components/lib/components/Layout/Divider";
import Panel from "astrid-components/lib/components/Layout/Panel";
import Theme from "astrid-components/lib/components/Theme";
import addArtifactMarker from "astrid-firestore/src/api/artifacts/addArtifactMarker";
import removeArtifactMarker from "astrid-firestore/src/api/artifacts/removeArtifactMarker";
import updateArtifactMarker from "astrid-firestore/src/api/artifacts/updateArtifactMarker";

import Flex from "../../../../components/Flex/Flex";

import ArtifactAudioPlayerChapters from "./components/ArtifactAudioPlayerChapters";
import ArtifactAudioPlayerTimeline from "./components/ArtifactAudioPlayerTimeline";
import ArtifactAudioPlayerToolbar from "./components/ArtifactAudioPlayerToolbar";
import useArtifactAudioPlayer from "./hooks/useArtifactAudioPlayer";
import useArtifactAudioPlayerAudioFiles from "./hooks/useArtifactAudioPlayerAudioFiles";

export default function ArtifactAudioPlayer({ artifact }) {
	const [activeTool, setActiveTool] = useState(null);

	const audioFiles = useArtifactAudioPlayerAudioFiles({ artifact });

	const markers = useMemo(() => artifact.markers.sort((a, b) => a.position - b.position), [artifact.markers]);

	const { play, pause, action } = useArtifactAudioPlayer({ audioFiles });

	const addMarker = useCallback(
		({ type, position }) => {
			return addArtifactMarker(firebase, { artifact, type, position });
		},
		[artifact],
	);

	const updateMarker = useCallback(
		({ marker, position }) => {
			return updateArtifactMarker(firebase, { artifact, marker, position });
		},
		[artifact],
	);

	const removeMarker = useCallback(
		({ marker }) => {
			return removeArtifactMarker(firebase, { artifact, marker });
		},
		[artifact],
	);

	return (
		<Theme mode="semantic">
			<Flex>
				<Panel style={{ height: 300, flex: 1, margin: -1 }} overflow="hidden" background="secondary">
					<ArtifactAudioPlayerToolbar
						play={play}
						pause={pause}
						action={action}
						activeTool={activeTool}
						setActiveTool={setActiveTool}
					/>

					<Divider />

					<ArtifactAudioPlayerTimeline
						play={play}
						action={action}
						markers={markers}
						addMarker={addMarker}
						updateMarker={updateMarker}
						audioFiles={audioFiles}
						activeTool={activeTool}
						setActiveTool={setActiveTool}
					/>

					<Divider />

					<Timeline.Navigation />
				</Panel>

				{markers.length > 0 && (
					<Panel style={{ height: 300, flex: 0.2, margin: -1 }} background="secondary">
						<ArtifactAudioPlayerChapters markers={markers} removeMarker={removeMarker} />
					</Panel>
				)}
			</Flex>
		</Theme>
	);
}
