import runBatch from "../../utils/runBatch";

export default function syncWorkMetadataToArticles(firebase, { work, articles, metadata }) {
	const data = metadata.reduce(
		(acc, key) => ({
			...acc,
			[key]: work[key],
		}),
		{},
	);

	return runBatch(firebase, (batch) => {
		for (const article of articles) {
			batch.update(article.ref, data);
		}
	});
}
