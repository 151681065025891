import { Route } from "react-router-dom";

import { db } from "astrid-firebase";

import ForwardContext from "../../../layouts/ForwardContext";

import { article } from "../../articles/routes/articles";
import { artifact } from "../../artifacts/routes/artifacts";
import FirestoreDocumentElement from "../../firebase/components/FirestoreDocumentElement";

import Work from "../pages/Work";
import WorkArticles from "../pages/WorkArticles";
import WorkArtifacts from "../pages/WorkArtifacts";
import WorkMetaData from "../pages/WorkMetaData";
import WorkProductions from "../pages/WorkProductions";
import Works from "../pages/Works";

const work = (
	<Route
		path=":work"
		element={<FirestoreDocumentElement param="work" collection={db.collection("works")} />}
		handle={{ crumb: ({ work }) => work?.name }}
	>
		<Route element={<Work />}>
			<Route index element={<WorkMetaData />} handle={{ crumb: ({ t }) => t("metaData", "Meta data") }} />

			<Route path="artifacts" handle={{ crumb: ({ t }) => t("files", "Files") }}>
				<Route index element={<WorkArtifacts />} />
			</Route>

			<Route path="articles" handle={{ crumb: ({ t }) => t("articles", "Articles") }}>
				<Route index element={<WorkArticles />} />
			</Route>

			<Route
				path="productions"
				element={<WorkProductions />}
				handle={{ crumb: ({ t }) => t("productions", "Productions") }}
			/>
		</Route>

		<Route path="artifacts" handle={{ crumb: ({ t }) => t("files", "Files") }}>
			{artifact}
		</Route>

		<Route path="articles" handle={{ crumb: ({ t }) => t("articles", "Articles") }}>
			{article}
		</Route>
	</Route>
);

export const works = (
	<Route path="works" element={<ForwardContext />} handle={{ crumb: ({ t }) => t("works", "Works") }}>
		<Route index element={<Works />} />

		{work}
	</Route>
);
