import { Checkbox, Input, Menu } from "semantic-ui-react";

import { useTranslation } from "react-i18next";
import { useRefinementList } from "react-instantsearch";

import Flex from "../../../../components/Flex/Flex";

export default function InstantSearchRefinementList({
	attribute,
	searchable,
	transformItems,
	renderItem,
	groupItems,
	limit = 5,
	showMore = true,
	showMoreLimit,
}) {
	const { t } = useTranslation();

	const { refine, items, canRefine, searchForItems, canToggleShowMore, toggleShowMore, isShowingMore } =
		useRefinementList({
			attribute,
			searchable,
			transformItems,
			limit,
			showMore,
			showMoreLimit,
		});

	const groupedItems = groupItems
		?.map(({ label, values }) => {
			const filteredItems = items.filter((item) => values.includes(item.value));

			const value = filteredItems.map((item) => item.value).join(",");
			const count = filteredItems.reduce((acc, item) => acc + item.count, 0);
			const isRefined = filteredItems.some((item) => item.isRefined);

			return { value, label, count, isRefined };
		})
		.filter(({ count }) => count > 0);

	const itemsToRender = groupedItems || items;

	console.log(itemsToRender);

	return (
		<>
			{searchable && (
				<Menu.Item>
					<Input
						fluid
						transparent
						icon="search"
						iconPosition="left"
						placeholder={t("search", "Search") + "..."}
						onChange={(e, { value }) => searchForItems(value)}
					/>
				</Menu.Item>
			)}

			{itemsToRender.map((item) => (
				<Menu.Item key={item.value} disabled={!canRefine} onClick={() => item.value.split(",").forEach(refine)}>
					<Flex>
						<Checkbox checked={item.isRefined} label={renderItem?.(item) || item.label} />

						{item.count}
					</Flex>
				</Menu.Item>
			))}

			{canToggleShowMore && (
				<Menu.Item onClick={() => toggleShowMore()}>
					{isShowingMore ? t("showLess", "Show less") : t("showMore", "Show more")}
				</Menu.Item>
			)}
		</>
	);
}
