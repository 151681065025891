import { Card } from "semantic-ui-react";

import React from "react";

import PageView from "../../../ui/views/PageView/PageView";

import StudioDropdownMenu from "../../components/StudioDropdownMenu/StudioDropdownMenu";

import StudioDataForm from "./components/StudioDataForm";
import StudioSettingsForm from "./components/StudioSettingsForm";

export default function StudioView({ studio }) {
	return (
		<PageView
			header={studio.name}
			subheader={studio.id}
			// sidebar={<VerticalMenu options={options} />}
			extra={<StudioDropdownMenu studio={studio} />}
		>
			<Card.Group>
				<StudioDataForm studio={studio} />
				<StudioSettingsForm studio={studio} />
			</Card.Group>
		</PageView>
	);
}
