import firebase from "firebase/app";

import { useState } from "react";
import { useTranslation } from "react-i18next";

import articleStatuses from "astrid-firestore/src/api/articles/constants/articleStatuses";
import createArticle from "astrid-firestore/src/api/articles/createArticle";
import addArticleToWork from "astrid-firestore/src/api/works/addArticleToWork";

import Menu from "../../../ui/components/Menu/Menu";

import Form from "../../../forms/components/Form/Form";
import useSimpleForm from "../../../forms/hooks/useSimpleForm";

import useArticleTypeOptions from "../../hooks/useArticleTypeOptions";

import ArticlesInstantSearchSelect from "../ArticlesInstantSearchSelect/ArticlesInstantSearchSelect";

export default function AddArticleForm({ work, publisher, onClose }) {
	const { t } = useTranslation();

	const [operation, setOperation] = useState("add");

	const articleTypeOptions = useArticleTypeOptions();

	const { form, onSubmit, error } = useSimpleForm({
		values: {
			article: null,
			name: work?.name || "",
			isbn: null,
			type: null,
			status: articleStatuses.DRAFT,
			work: work || null,
			publisher: work?.publisher || publisher || null,
		},
		onSubmit: (data) => {
			if (operation === "add") {
				return addArticleToWork(firebase, data);
			}

			if (operation === "create") {
				return createArticle(firebase, data);
			}
		},
	});

	return (
		<Form.Modal
			header={t("addArticle", "Add article")}
			size="tiny"
			form={form}
			error={error}
			onSubmit={onSubmit}
			onClose={onClose}
		>
			<Menu
				fluid
				widths={2}
				options={[
					{
						active: operation === "add",
						text: t("add", "Add"),
						onClick: () => setOperation("add"),
					},
					{
						active: operation === "create",
						text: t("create", "Create"),
						onClick: () => setOperation("create"),
					},
				]}
			/>

			<br />

			{operation === "add" && (
				<ArticlesInstantSearchSelect
					name="article"
					publisher={work?.publisher || publisher}
					notWork={work}
					placeholder={t("search", "Search")}
				/>
			)}

			{operation === "create" && (
				<>
					<Form.Input name="name" label={t("title", "Title")} />

					<Form.Select name="type" label={t("type", "Type")} options={articleTypeOptions} />

					<Form.Input name="isbn" label={t("isbn", "ISBN")} />
				</>
			)}
		</Form.Modal>
	);
}
