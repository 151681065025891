import { useTranslation } from "react-i18next";
import { useOutletContext } from "react-router-dom";

import Unauthorized from "../../ui/components/Unauthorized/Unauthorized";

import useHasRight from "../../authorization/hooks/useHasRight";

import InvoicesView from "../views/InvoicesView/InvoicesView";

export default function Invoices() {
	const { t } = useTranslation();
	const { organization } = useOutletContext();

	const hasRight = useHasRight();

	if (!hasRight("producerBilling.createPublisherInvoice")) {
		return <Unauthorized />;
	}

	return <InvoicesView header={t("invoices", "Invoices")} firstPartyId={organization.id} />;
}
