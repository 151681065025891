import { Button, Modal, Radio } from "semantic-ui-react";

import { useState } from "react";
import { useTranslation } from "react-i18next";

import { updateDocument } from "astrid-firestore/src/helpers";

import Flex from "../../Flex/Flex";
import FlexRow from "../../Flex/FlexRow";

function whenChapterSetToAccordingToUploadedFiles({ settings }) {
	return Object.entries(settings || {}).reduce((acc, [key, val]) => {
		if (val.disabled) {
			return {
				...acc,
				[key]: val,
			};
		} else {
			return {
				...acc,
				[key]: { ...val, part: !val.part || val.part === "chapter" ? "parts" : val.part },
			};
		}
	}, {});
}

async function updateSettings({ prodRef, distOptions }) {
	const totalAccordingToUploadedFiles = whenChapterSetToAccordingToUploadedFiles({
		settings: distOptions?.settings?.total,
	});
	const partAccordingToUploadedFiles = whenChapterSetToAccordingToUploadedFiles({
		settings: distOptions?.settings?.part,
	});

	const total = Object.keys(totalAccordingToUploadedFiles).length ? { total: totalAccordingToUploadedFiles } : {};
	const part = Object.keys(partAccordingToUploadedFiles).length ? { part: partAccordingToUploadedFiles } : {};

	const updatedSettings = {
		...(distOptions?.settings || {}),
		...total,
		...part,
	};

	await updateDocument(prodRef, {
		["distributionOptions.settings"]: updatedSettings,
	});

	return {
		...distOptions,
		settings: updatedSettings,
	};
}

export default function FinishProductionDistOptionsModal({ prodRef, distOptions, finish, setShowModal }) {
	const { t } = useTranslation();
	const [collapsed, setCollapsed] = useState(false);
	return (
		<Modal open={true} onClose={() => setShowModal(false)}>
			<Modal.Header>{t("finalizeProd")}</Modal.Header>
			<Modal.Content>
				<p>{`${t("overriddenDistMessage", "The production will be finalized using the setting")}: "${t(
					"exportPartparts",
				)}"`}</p>
				<i>
					{t(
						"overrideNoteMessage",
						"Note: The override of the setting will only apply to distribution channels that uses chapter points as their default, other channels will still use their own settings.",
					)}
				</i>
			</Modal.Content>
			<Modal.Actions>
				<Flex style={{ justifyContent: "space-between" }}>
					<Radio toggle onChange={() => setCollapsed((collapsed) => !collapsed)} />
					<FlexRow style={{ gap: 10 }}>
						{collapsed && (
							<Button color="yellow" onClick={() => finish()}>
								{t("finalizeWithOwnSettings", "Finanlize with your own settings")}
							</Button>
						)}
						<Button
							primary
							onClick={() => {
								updateSettings({ prodRef, distOptions })
									.then((distOptions) => {
										setShowModal(false);
										return distOptions;
									})
									.then((distOptions) => finish(distOptions));
							}}
						>
							{t("finalizeProd")}
						</Button>
					</FlexRow>
				</Flex>
			</Modal.Actions>
		</Modal>
	);
}
