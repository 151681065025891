import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import AdminCard from "../../../../admin/components/AdminCard/AdminCard";
import useAgreementColumns from "../../../../agreements/hooks/useAgreementColumns";
import useAgreementsQuery from "../../../../agreements/hooks/useAgreementsQuery";
import CollectionDataTable from "../../../../ui/components/CollectionDataTable/CollectionDataTable";

export default function PublisherAgreements({ publisher, organization }) {
	const { t } = useTranslation();
	const navigate = useNavigate();

	const query = useAgreementsQuery({ firstPartyId: organization.id, secondPartyId: publisher.id });
	const columns = useAgreementColumns();

	return (
		<AdminCard header={t("agreements", "Agreements")}>
			<CollectionDataTable
				query={query}
				columns={columns}
				hiddenColumns={["secondParty.name"]}
				onClick={({ id }) => navigate(`../../agreements/${id}`)}
				sortBy={{
					id: "created",
					desc: true,
				}}
			/>
		</AdminCard>
	);
}
