import React from "react";

import { useUser } from "../../features/authentication/state/user";

import VendorMissing from "./VendorMissing/VendorMissing";

export default function Messages({ organization }) {
	const user = useUser();

	if (!user) {
		return false;
	}

	return (
		<>
			<VendorMissing uid={user.uid} organization={organization} />
			{/* other messages */}
		</>
	);
}
