import DistributeArticlesButton from "../../DistributeArticles/DistributeArticlesButton";

import ArticleDistributionChannelsEditButton from "./ArticleDistributionChannelsEditButton";

export default function ArticleDistributionFooter({ article, channels, selectedFlatRows, toggleAllRowsSelected }) {
	const selectedChannels = selectedFlatRows?.map(({ original }) => original);

	const onSuccess = () => {
		toggleAllRowsSelected(false);
	};

	return (
		<>
			<ArticleDistributionChannelsEditButton article={article} channels={channels} />

			<DistributeArticlesButton articles={[article]} selectedChannels={selectedChannels} onSuccess={onSuccess} />
		</>
	);
}
