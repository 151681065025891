import firebase from "firebase";

import { useTranslation } from "react-i18next";

import { deleteProduction } from "../../../../../helpers/production";
import useHasRight from "../../../../authorization/hooks/useHasRight";
import useHasRole from "../../../../authorization/hooks/useHasRole";

export default function useProductionDropdownMenuOptions({ production, setHistoryOpen, setMigrateProductionOpen }) {
	const { t } = useTranslation();
	const hasRight = useHasRight();
	const hasRole = useHasRole();

	const isMigrated = production.migrations?.flattening?.migrated;

	return [
		{
			text: t("history", "History"),
			hidden: !hasRight("productions.admin"),
			onClick: () => {
				setHistoryOpen(true);
			},
		},
		{
			text: isMigrated ? "Revert migration" : "Migrate production",
			hidden: !hasRole("owner"),
			onClick: () => {
				setMigrateProductionOpen(true);
			},
		},
		{
			text: t("delete", "Delete"),
			confirmText: t("deleteProduction", "Are you sure you want to delete this production?"),
			hidden: !hasRight("productions.delete"),
			action: () => {
				return deleteProduction(production.id, firebase.auth().currentUser.uid);
			},
		},
	];
}
