import firebase from "firebase/app";

import React from "react";
import { useTranslation } from "react-i18next";

import { Article } from "astrid-firestore/src/api/articles/types/Article";
import updateArticle from "astrid-firestore/src/api/articles/updateArticle";

import AdminCard from "../../../admin/components/AdminCard/AdminCard";
import AdminForm from "../../../admin/components/AdminForm/AdminForm";
import useHasRole from "../../../authorization/hooks/useHasRole";
import DistributionsCollectionDataTable from "../../../distribution/components/DistributionsCollectionDataTable/DistributionsCollectionDataTable";
import Form from "../../../forms/components/Form/Form";
import useSimpleForm from "../../../forms/hooks/useSimpleForm";

import useArticleDistributionChannels from "../../hooks/useArticleDistributionChannels";
import useArticleStatusOptions from "../../hooks/useArticleStatusOptions";

import ArticleDistributionChannels from "./components/ArticleDistributionChannels";
import ArticleDistributionDatesForm from "./components/ArticleDistributionDatesForm";

export default function ArticleDistributionView({ article, publisher, ...props }) {
	const { t } = useTranslation();
	const hasRole = useHasRole();

	const statusOptions = useArticleStatusOptions();

	const [presetChannels, articleChannels, loading, error] = useArticleDistributionChannels({ article, publisher });

	const form = useSimpleForm({
		schema: Article,
		values: {
			ref: article.ref,
			status: article.status || null,
			deliveryDate: article.deliveryDate?.toDate() || null,
		},
		onSubmit: (data) => {
			return updateArticle(firebase, data);
		},
		...props,
	});

	return (
		<>
			<AdminForm header={t("general", "General")} {...form}>
				<Form.Group widths="equal">
					<Form.Select name="status" label={t("status", "Status")} options={statusOptions} />

					<Form.DateInput name="deliveryDate" label={t("deliveryDate", "Delivery date")} disableBeforeToday />
				</Form.Group>
			</AdminForm>

			<AdminCard header={t("channels", "Channels")} transparent>
				<ArticleDistributionChannels
					presetChannels={presetChannels}
					articleChannels={articleChannels}
					article={article}
					publisher={publisher}
					loading={loading}
					error={error}
				/>
			</AdminCard>

			<ArticleDistributionDatesForm article={article} channels={articleChannels} />

			{hasRole("admin") && (
				<AdminCard transparent header={t("distributions", "Distributions")}>
					<DistributionsCollectionDataTable article={article} />
				</AdminCard>
			)}
		</>
	);
}
