import { Route } from "react-router-dom";

import ForwardContext from "../../../layouts/ForwardContext";

import Users from "../../users/pages/Users";

import SuperAdminAgreements from "../pages/SuperAdminAgreements/SuperAdminAgreements";
import SuperAdminAppendixes from "../pages/SuperAdminAppendixes";
import SuperAdminApprove from "../pages/SuperAdminApprove/SuperAdminApprove";
import SuperAdminApproveAgreementTemplates from "../pages/SuperAdminApprove/SuperAdminApproveAgreementTemplates";
import SuperAdminApproveAgreements from "../pages/SuperAdminApprove/SuperAdminApproveAgreements";
import SuperAdminApproveOffers from "../pages/SuperAdminApprove/SuperAdminApproveOffers";
import SuperAdminApprovePrices from "../pages/SuperAdminApprove/SuperAdminApprovePrices";
import SuperAdminApproveProductionPrices from "../pages/SuperAdminApprove/SuperAdminApproveProductionPrices";
import SuperAdminBiblioCompletedTasks from "../pages/SuperAdminBiblioCompletedTasks";
import SuperAdminBiblioSettings from "../pages/SuperAdminBiblioSettings";
import SuperAdminBiblioTasks from "../pages/SuperAdminBiblioTasks";
import SuperAdminBiblioUncompletedTasks from "../pages/SuperAdminBiblioUncompletedTasks";
import SuperAdminDistributionChannels from "../pages/SuperAdminDistributionChannels";
import SuperAdminProducer from "../pages/SuperAdminProducer";
import SuperAdminProducers from "../pages/SuperAdminProducers";
import SuperAdminProductionPrice from "../pages/SuperAdminProductionPrice";
import SuperAdminPublisher from "../pages/SuperAdminPublisher";
import SuperAdminPublishers from "../pages/SuperAdminPublishers";
import SuperAdminSettings from "../pages/SuperAdminSettings";
import SuperAdminUser from "../pages/SuperAdminUser";

export const superAdminSettings = (
	<Route path="settings" element={<SuperAdminSettings />}>
		<Route path="channels" element={<SuperAdminDistributionChannels />} />
		<Route path="appendixes" element={<SuperAdminAppendixes />} />
	</Route>
);

export const superAdminApprove = (
	<Route path="approve" element={<SuperAdminApprove />}>
		<Route path="costs" element={<SuperAdminApproveProductionPrices />} />
		<Route path="prices" element={<SuperAdminApprovePrices />} />
		<Route path="offers" element={<SuperAdminApproveOffers />} />
		<Route path="agreements" element={<SuperAdminApproveAgreements />} />
		<Route path="templates" element={<SuperAdminApproveAgreementTemplates />} />
	</Route>
);

export const superAdminProducers = (
	<Route path="producers" element={<ForwardContext />} handle={{ crumb: ({ t }) => t("producers", "Producers") }}>
		<Route index element={<SuperAdminProducers />} />
		<Route
			path=":id"
			element={<SuperAdminProducer />}
			handle={{ crumb: ({ organization }) => organization?.name }}
		/>
	</Route>
);

export const superAdminPublishers = (
	<Route path="publishers" element={<ForwardContext />} handle={{ crumb: ({ t }) => t("publishers", "Publishers") }}>
		<Route index element={<SuperAdminPublishers />} />
		<Route
			path=":id"
			element={<SuperAdminPublisher />}
			handle={{ crumb: ({ organization }) => organization?.name }}
		/>
	</Route>
);

export const superAdminUsers = (
	<Route path="users" element={<ForwardContext />} handle={{ crumb: ({ t }) => t("users", "Users") }}>
		<Route index element={<Users />} />

		<Route
			path=":id"
			element={<SuperAdminUser />}
			handle={{ crumb: ({ user }) => user?.firstName + " " + user?.lastName }}
		/>
	</Route>
);

export const superAdminCosts = (
	<Route path="costs" element={<ForwardContext />}>
		<Route path=":productionId/:id" element={<SuperAdminProductionPrice />} />
	</Route>
);

export const superAdminAgreements = (
	<Route path="agreements" element={<SuperAdminAgreements />}>
		<Route path="appendixes" element={<SuperAdminAppendixes />} />
	</Route>
);

export const superAdminBiblio = (
	<Route path="biblio" element={<ForwardContext />}>
		<Route path="settings" element={<SuperAdminBiblioSettings />} />
		<Route path="tasks" element={<SuperAdminBiblioTasks />}>
			<Route index element={<SuperAdminBiblioUncompletedTasks />} />
			<Route path="completed" element={<SuperAdminBiblioCompletedTasks />} />
			<Route path="uncompleted" element={<SuperAdminBiblioUncompletedTasks />} />
		</Route>
	</Route>
);
