import articleTypes from "astrid-firestore/src/api/articles/constants/articleTypes";
import { ArticleRef } from "astrid-firestore/src/api/articles/types/ArticleRef";

import getDocumentData from "../../src/utils/getDocumentData";

import createArticleDelivery from "./createArticleDelivery";
import createAudioArtifact from "./createAudioArtifact";
import createChannels from "./createChannels";
import createCover from "./createCover";
import createDocumentIdAndRef from "./createDocumentIdAndRef";

export default async function createAudiobook(
	{ api, env },
	{ production, publisher, commonArticleData, ebookArticle },
) {
	const { id, ref } = createDocumentIdAndRef({ api, env }, { collection: "articles" });
	const article = {
		id,
		ref,
		type: articleTypes.AUDIOBOOK,
		name: production.title,
		isbn: production.isbn,
		linkedArticle: ebookArticle ? ArticleRef.parse(ebookArticle) : null,
		synopsis: production.synopsis || null,
		...commonArticleData,
	};
	const cover = await createCover({ api, env }, { img: production.img, imgThumb: production.imgThumb });
	const artifact = await createAudioArtifact({ api, env }, { production, article });
	const duration = artifact?.files?.reduce?.((sum, file) => sum + file.duration, 0) || 0;
	const fullPublisherDoc = await getDocumentData(api[env].db.collection("organizations").doc(publisher.id));
	const channels = await createChannels(
		{ api, env },
		{ article, fullPublisherDoc, production, articleType: "total" },
	);
	const delivery = createArticleDelivery({ channels });

	return {
		...article,
		artifact,
		cover,
		duration,
		channels,
		channelIds: Object.keys(channels),
		delivery,
	};
}
