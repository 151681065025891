import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import db from "astrid-firebase/src/db";

import CollectionDataTable from "../../../ui/components/CollectionDataTable/CollectionDataTable";

import AdminCard from "../../../admin/components/AdminCard/AdminCard";
import CreateArtifactButton from "../../../artifacts/components/CreateArtifactForm/CreateArtifactButton";
import useArtifactsTableColumns from "../../../artifacts/hooks/useArtifactsTableColumns";

export default function WorkArtifactsView({ work, organization }) {
	const { t } = useTranslation();

	const navigate = useNavigate();

	const query = db.collection("artifacts").where("work.id", "==", work.id);

	const columns = useArtifactsTableColumns({ work });

	return (
		<AdminCard
			transparent
			header={t("artifacts", "Artifacts")}
			extra={<CreateArtifactButton work={work} publisher={organization} />}
		>
			<CollectionDataTable
				query={query}
				columns={columns}
				onClick={({ id }) => navigate(id)}
				sortBy={{
					id: "name",
					desc: false,
				}}
			/>
		</AdminCard>
	);
}
