import React from "react";
import { useTranslation } from "react-i18next";

import { invoiceTypes } from "astrid-firestore/src/api/invoices/invoiceTypes";

import AdminCard from "../../../../admin/components/AdminCard/AdminCard";
import CreateSalesOrderInvoice from "../../../../invoices/components/CreateSalesOrderInvoice";
import DataTable from "../../../../ui/components/DataTable/DataTable";

import useInvoicesColumns from "../hooks/useInvoicesTableColumns";

function shouldShowCreateInvoice({ order, invoices }) {
	const latestInvoice = invoices?.[0];

	return !order.processed && !order.inactivated && (!invoices?.length || latestInvoice?.type === invoiceTypes.CREDIT);
}

export default function OrderInvoices({ order, invoices }) {
	const { t } = useTranslation();
	const columns = useInvoicesColumns();

	return (
		<AdminCard header={t("invoiceHeader", "Invoices")}>
			{shouldShowCreateInvoice({ order, invoices }) && (
				<CreateSalesOrderInvoice order={order} invoices={invoices} />
			)}

			<DataTable columns={columns} data={invoices} />
		</AdminCard>
	);
}
