import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import articleStatuses from "astrid-firestore/src/api/articles/constants/articleStatuses";

export default function useArticleStatusTranslations() {
	const { t } = useTranslation();

	return useMemo(
		() => ({
			[articleStatuses.DRAFT]: t("draft", "Draft"),
			[articleStatuses.READY]: t("readyForDistribution", "Ready for distribution"),
		}),
		[t],
	);
}
