import React from "react";

import CoverImage from "./CoverImage";

export default function CoverImageStack({ data, size = 80, loading }) {
	const style = { width: size, height: size };

	return (
		<div className="image-stack">
			<CoverImage thumb rounded data={data} style={style} />
			<CoverImage thumb rounded data={data} style={style} loading={loading} />
		</div>
	);
}
