import { useOutletContext } from "react-router-dom";

import ExportPipelineView from "../views/ExportView/ExportPipelineView";

export default function ExportPipeline() {
	const { export: theExport } = useOutletContext();

	if (!theExport.pipeline) {
		return null;
	}

	return <ExportPipelineView export={theExport} />;
}
