import { useCallback } from "react";

import useStore from "../../ui/hooks/useStore";

import { useProfile } from "../../authentication/state/profile";

import selectOrganization from "../utils/selectOrganization";

export default function useSelectOrganization() {
	const user = useProfile();

	const { setProducerId: legacySetProducerId } = useStore();

	return useCallback(
		({ id, type } = {}) => {
			if (id && type && user) {
				selectOrganization({ id, type, user, legacySetProducerId });
			}
		},
		[legacySetProducerId, user],
	);
}
