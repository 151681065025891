import { useTranslation } from "react-i18next";

import useWorkMetadataFields from "../../../hooks/useWorkMetadataFields";

export function useSyncWorkMetadataColumns() {
	const { t } = useTranslation();

	const metadataFields = useWorkMetadataFields();

	return [
		{
			Header: t("field", "Field"),
			accessor: ({ id }) => metadataFields[id]?.label,
		},
		{
			Header: t("value", "Value"),
			accessor: ({ id, value }) => metadataFields[id]?.accessor(value),
			Cell: ({ value }) => (value ? value : <span style={{ opacity: 0.3, fontStyle: "italic" }}>Empty</span>),
		},
	];
}
