import { getOrganizationId } from "../../organizations/state/organizationId";
import { getOrganizationType } from "../../organizations/state/organizationType";

export function getOrganizationPath(path) {
	if (!path.startsWith("/")) {
		return path;
	}

	const organizationType = getOrganizationType();
	const organizationId = getOrganizationId();

	if (organizationType === "admin") {
		return `/admin${path}`;
	}

	if (["publisher", "producer", "narrator"].includes(organizationType)) {
		return `/${organizationType}/${organizationId}${path}`;
	}

	throw new Error("Invalid organization type");
}
