import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import AdminCard from "../../../../admin/components/AdminCard/AdminCard";
import Table from "../../../../ui/components/Table/Table";

import useUserPricesColumns from "../hooks/useUserPricesColumns";

import UserPricesActions from "./UserPricesActions";

export default function UserPrices({ user, organization }) {
	const { t } = useTranslation();

	const data = useMemo(() => Object.values(user.prices?.[organization.id] || {}), [organization.id, user.prices]);

	const columns = useUserPricesColumns({ user, organization });

	return (
		<AdminCard
			header={t("defaultPricing", "Default pricing")}
			extra={<UserPricesActions user={user} organization={organization} />}
		>
			<Table data={data} columns={columns} footer={false} />
		</AdminCard>
	);
}
