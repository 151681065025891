import { useTranslation } from "react-i18next";

import AdminCard from "../../../../admin/components/AdminCard/AdminCard";
import CollectionDataTable from "../../../../ui/components/CollectionDataTable/CollectionDataTable";
import useUsersQuery from "../../../../users/hooks/useUsersQuery";

import usePublisherUsersTableColumns from "../hooks/usePublisherUsersTableColumns";

export default function PublisherUsers({ publisher }) {
	const { t } = useTranslation();

	const usersQuery = useUsersQuery({ organization: publisher });
	const usersColumns = usePublisherUsersTableColumns(publisher);

	return (
		<AdminCard header={t("publisherUsers", "Publisher users")}>
			<CollectionDataTable
				query={usersQuery}
				columns={usersColumns}
				sortBy={{
					id: "name",
					desc: false,
				}}
			/>
		</AdminCard>
	);
}
