import { useTranslation } from "react-i18next";

import { mapAgreementPrices } from "astrid-firestore/src/api/agreements/utils/agreementMappers";

import AdminCard from "../../../../admin/components/AdminCard/AdminCard";
import Table from "../../../../ui/components/Table/Table";

import useAgreementPricesColumns from "../../../hooks/useAgreementPricesColumns";

export default function TemplatePrices({ template }) {
	const { t } = useTranslation();

	const pricesColumns = useAgreementPricesColumns();

	return (
		<AdminCard header={t("prices", "Prices")}>
			<Table data={mapAgreementPrices(template)} columns={pricesColumns} footer={false} />
		</AdminCard>
	);
}
