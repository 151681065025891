import { useTranslation } from "react-i18next";

import Flex from "../../../../components/Flex/Flex";

import useArticleDeliveryStatus from "../../hooks/useArticleDeliveryStatus";

import ArticleDeliveryStatusLabel from "./components/ArticleDeliveryStatusLabel";

export default function ArticleDeliveryStatus({ article, channel }) {
	const { t } = useTranslation();

	const artifactStatus = useArticleDeliveryStatus("artifact", { article, channel });
	const metadataStatus = useArticleDeliveryStatus("metadata", { article, channel });

	return (
		<Flex gap={10} style={{ flex: 0 }}>
			<ArticleDeliveryStatusLabel text={t("meta", "Meta")} {...metadataStatus} />
			<ArticleDeliveryStatusLabel text={t("files", "Files")} {...artifactStatus} />
		</Flex>
	);
}
