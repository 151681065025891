import { Route } from "react-router-dom";

import App from "../../App";
import StudioRedirect from "../../components/StudioRedirect";
import ListProductionsLegacy from "../../components/production/ListProductions";
import renderComponent from "../../hocs/renderComponent";
import withMatch from "../../hocs/withMatch";
import withRight from "../../hocs/withRight";
import withUserProfile from "../../hocs/withUserProfile";
import CalendarLegacy from "../../layouts/Calendar";
import ComputeJobs from "../../layouts/ComputeJobs";
import DistributionOld from "../../layouts/DistributionOld";
import ExternalUpload from "../../layouts/ExternalUpload";
import Publication from "../../layouts/Publication";
import Reader from "../../layouts/Reader";
import ReaderPreview from "../../layouts/ReaderPreview";
import ReaderSignup from "../../layouts/ReaderSignup";
import Scheduled from "../../layouts/Scheduled";
import ProducerSettings from "../../layouts/Settings";
import SharedExport from "../../layouts/SharedExport";
import Upload from "../../layouts/Upload";
import Words from "../../layouts/Words";
import HomeOld from "../../pages/Home/HomeOld";
import Policy from "../ui/pages/Policy/Policy";
import { pageNotFound } from "../ui/routes/pageNotFound";
import { policy } from "../ui/routes/policy";

import { account } from "../account/routes/account";
import Admin from "../admin/pages/Admin";
import { agreementTemplates, agreements, agreementsExpiring, prepareAgreements } from "../agreements/routes/agreements";
import SiteLayout from "../app/layouts/SiteLayout/SiteLayout";
import Apps from "../apps/pages/Apps";
import { articles } from "../articles/routes/articles";
import Artifacts from "../artifacts/pages/Artifacts";
import Login from "../authentication/pages/Login";
import LoginForm from "../authentication/pages/LoginForm";
import ResetPassword from "../authentication/pages/ResetPassword";
import { calendar, calendarNarrator } from "../calendar/routes/calendar";
import { costs, expenses, revenues } from "../costs/routes/costs";
import { distributions } from "../distribution/routes/distributions";
import { documents } from "../documents/routes/documents";
import HomeRedirect from "../home/pages/HomeRedirect";
import { homeNarrator, homeProducer, homePublisher, homeSuperAdmin } from "../home/routes/home";
import { imprints } from "../imprints/routes/imprints";
import { invoices } from "../invoices/routes/invoices";
import {
	listProductionsAdmin,
	listProductionsProducer,
	listProductionsPublisher,
} from "../listproductions/routes/listProductions";
import {
	myProductionsNarrator,
	myProductionsProducer,
	myProductionsPublisher,
} from "../myproductions/routes/myProductions";
import { offers } from "../offers/routes/offers";
import { orders, purchaseOrders, salesOrders } from "../orders/routes/orders";
import NarratorOrganizationContext from "../organizations/layouts/OrganizationContext/NarratorOrganizationContext";
import OrganizationContext from "../organizations/layouts/OrganizationContext/OrganizationContext";
import ProducerDashboard from "../organizations/pages/ProducerDashboard";
import { adminSettings, publisherSettings, publishers } from "../organizations/routes/organizations";
import { prices } from "../prices/routes/prices";
import { createProductionProducer, createProductionPublisher } from "../productions/routes/createProduction";
import { production } from "../productions/routes/production";
import { productions } from "../productions/routes/productions";
import { profilePage } from "../profile/routes/profile";
import { scripts } from "../scripts/routes/scripts";
import { series } from "../series/routes/series";
import { statistics } from "../statistics/routes/statistics";
import StorytoolArchive from "../storytoolArchive/pages/StorytoolArchive";
import { studios } from "../studios/routes/studios";
import SuperAdmin from "../superadmin/pages/SuperAdmin";
import SuperAdminDistributionChannel from "../superadmin/pages/SuperAdminDistributionChannel/SuperAdminDistributionChannel";
import SuperAdminDistributionChannelPipelines from "../superadmin/pages/SuperAdminDistributionChannel/SuperAdminDistributionChannelPipelines";
import SuperAdminDistributionChannelSettings from "../superadmin/pages/SuperAdminDistributionChannel/SuperAdminDistributionChannelSettings";
import SuperAdminDistributionPipeline from "../superadmin/pages/SuperAdminDistributionPipeline/SuperAdminDistributionPipeline";
import SuperAdminDistributionPipelineSettings from "../superadmin/pages/SuperAdminDistributionPipeline/SuperAdminDistributionPipelineSettings";
import {
	superAdminAgreements,
	superAdminApprove,
	superAdminBiblio,
	superAdminCosts,
	superAdminProducers,
	superAdminPublishers,
	superAdminSettings,
	superAdminUsers,
} from "../superadmin/routes/superAdmin";
import { users } from "../users/routes/users";
import { works } from "../works/routes/works";

const routes = (
	<Route path="/" element={<App />}>
		<Route path="readerPreview/:ids" element={renderComponent(withMatch(ReaderPreview))} />

		<Route path="words" element={renderComponent(withMatch(Words))}>
			<Route path=":productionId/:email" element={null} />
			<Route path=":productionId" element={null} />
		</Route>

		<Route path="external/upload/:publisherId/:language" element={renderComponent(withMatch(ExternalUpload))} />

		<Route path="export" element={renderComponent(withMatch(SharedExport))}>
			<Route path=":productionId/:article/:id" element={null} />
			<Route path=":productionId/:article" element={null} />
			<Route path=":productionId" element={null} />
		</Route>

		<Route path="policy" element={<Policy />} />

		<Route path="login" element={<Login />}>
			<Route index element={<LoginForm />} />
			<Route path="reset" element={<ResetPassword />} />
			<Route path=":provider" element={<LoginForm />} />
		</Route>

		<Route element={<SiteLayout />}>
			<Route index element={<HomeRedirect />} />
			<Route path="studio-redirect" element={<StudioRedirect />} />
			<Route path="home-old" element={renderComponent(withMatch(withUserProfile(HomeOld)))} />
			<Route path="apps" element={<Apps />}>
				<Route path=":channel" element={null} />
			</Route>
			<Route path="computejobs" element={renderComponent(withMatch(withUserProfile(ComputeJobs)))} />
			<Route path="upload" element={renderComponent(withMatch(withUserProfile(Upload)))} />
			<Route path="settings" element={renderComponent(withMatch(withUserProfile(ProducerSettings)))}>
				<Route path=":producerId" element={null} />
			</Route>

			<Route
				path="distribution"
				element={renderComponent(withMatch(withRight(DistributionOld, "distribution.editDistributors")))}
			/>

			<Route
				path="scheduled"
				element={renderComponent(withMatch(withRight(Scheduled, "distribution.editDistributors")))}
			/>

			<Route path="reader" element={<Reader />} />

			<Route path="readerSignup" element={<ReaderSignup signedIn />} />

			<Route path="readerPreview/:ids" element={renderComponent(withMatch(withUserProfile(ReaderPreview)))} />

			<Route path="words" element={renderComponent(withMatch(withUserProfile(Words)))}>
				<Route path=":productionId" element={null} />
			</Route>

			<Route path="export" element={renderComponent(withMatch(SharedExport))}>
				<Route path=":productionId/:article/:id" element={null} />
				<Route path=":productionId/:article" element={null} />
				<Route path=":productionId" element={null} />
			</Route>

			{profilePage}

			<Route
				path="calendar"
				element={renderComponent(withMatch(withRight(withUserProfile(CalendarLegacy), "viewCalendar")))}
			>
				<Route path=":productionId" element={null} />
			</Route>

			<Route path="publication" element={renderComponent(withMatch(withUserProfile(Publication)))} />

			<Route path="production" element={renderComponent(withMatch(withUserProfile(ListProductionsLegacy)))} />

			{production}

			{policy}

			<Route path="admin" element={<SuperAdmin />}>
				{homeSuperAdmin}
				{superAdminApprove}
				{superAdminProducers}
				{superAdminPublishers}
				{superAdminSettings}
				{superAdminUsers}
				{superAdminCosts}
				{superAdminAgreements}
				{superAdminBiblio}

				{works}
				{articles}
				{listProductionsAdmin}
				{distributions}

				{/*Ska denna route nedan tas bort?*/}
				<Route path="distribution/channels/:channel" element={<SuperAdminDistributionChannel />}>
					<Route index element={<SuperAdminDistributionChannelSettings />} />

					<Route path="pipelines">
						<Route index element={<SuperAdminDistributionChannelPipelines />} />

						<Route path=":id" element={<SuperAdminDistributionPipeline />}>
							<Route index element={<SuperAdminDistributionPipelineSettings />} />
						</Route>
					</Route>
				</Route>
			</Route>

			{account}

			<Route path="producer/:orgId" element={<OrganizationContext />}>
				{homeProducer}
				{createProductionProducer}

				{users}
				{studios}

				<Route path="admin" element={<Admin />}>
					<Route index element={<ProducerDashboard />} />

					{adminSettings}

					{agreements}
					{agreementsExpiring}
					{prepareAgreements}
					{agreementTemplates}
					{prices}
					{users}
					{publishers}
					{expenses}
					{revenues}
					{purchaseOrders}
					{orders}
					{salesOrders}
					{invoices}
					{offers}
					{productions}
				</Route>

				{listProductionsProducer}

				{myProductionsProducer}

				{documents}

				{statistics}

				{calendar}
			</Route>
			<Route path="publisher/:orgId" element={<OrganizationContext />}>
				{/*<Route index element={renderComponent(withMatch(withUserProfile(PublisherLayout)))} />*/}

				{homePublisher}
				{createProductionPublisher}
				{works}
				{articles}
				{distributions}
				{imprints}
				{series}
				{users}
				{publisherSettings}

				<Route path="admin" element={<Admin />}>
					{agreements}
					{scripts}
					{costs}
					{orders}

					<Route path="articles/artifacts" element={<Artifacts />} />
				</Route>

				{listProductionsPublisher}
				{myProductionsPublisher}
			</Route>

			<Route path="narrator/:orgId" element={<NarratorOrganizationContext />}>
				{homeNarrator}

				{calendarNarrator}

				{myProductionsNarrator}
			</Route>
			<Route path="storytool-archive" element={<StorytoolArchive />} />
		</Route>

		<Route path="readerSignup" element={renderComponent(withMatch(ReaderSignup))}>
			<Route path=":referrer/:campaign" element={null} />
			<Route path=":referrer" element={null} />
		</Route>

		{pageNotFound}
	</Route>
);

export default routes;
