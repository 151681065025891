import { useTranslation } from "react-i18next";

import { useProfile } from "../../authentication/state/profile";
import useHasRight from "../../authorization/hooks/useHasRight";
import useHasRole from "../../authorization/hooks/useHasRole";
import { useOrganizationId } from "../../organizations/state/organizationId";

export default function useProfileDropdownMenuOptions({ user }) {
	const { t } = useTranslation();
	const profile = useProfile();
	const hasRight = useHasRight();
	const hasRole = useHasRole();
	const organizationId = useOrganizationId();

	const organization = user.organizations?.[organizationId];

	return [
		{
			text: t("editAccount", "Edit account"),
			linkTo: `/account/${user.id}/settings`,
			hidden: user.id !== profile.id,
		},
		{
			text: t("editInOrganization", "Edit in {{organization}}", { organization: organization?.name }),
			linkTo: `/${organization?.type}/${organizationId}/users/${user.id}`,
			hidden: !hasRight("users.view") || !organization,
		},
		{
			text: t("editInSuperAdmin", "Edit in super admin"),
			linkTo: `/admin/users/${user.id}`,
			hidden: !hasRole("admin", "owner"),
		},
	];
}
