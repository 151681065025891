import { Route } from "react-router-dom";

import ForwardContext from "../../../layouts/ForwardContext";

import Productions from "../pages/Productions/Productions";
import ProductionsCompleted from "../pages/Productions/ProductionsCompleted";
import ProductionsDashboard from "../pages/Productions/ProductionsDashboard";
import ProductionsDrafts from "../pages/Productions/ProductionsDrafts";
import ProductionsOngoing from "../pages/Productions/ProductionsOngoing";
import ProductionsRequests from "../pages/Productions/ProductionsRequests";
import ProductionsUnfinalized from "../pages/Productions/ProductionsUnfinalized";

export const productions = (
	<Route
		path="productions"
		element={<ForwardContext />}
		handle={{
			crumb: ({ t }) => t("productions", "Productions"),
		}}
	>
		<Route index element={<ProductionsDashboard />} />

		<Route path="all" element={<Productions />} />

		<Route path="drafts" element={<ProductionsDrafts />} />

		<Route path="requests" element={<ProductionsRequests />} />

		<Route path="ongoing" element={<ProductionsOngoing />} />

		<Route path="completed" element={<ProductionsCompleted />} />

		<Route path="unfinalized" element={<ProductionsUnfinalized />} />
	</Route>
);
