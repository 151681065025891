import { Header } from "semantic-ui-react";

import money from "astrid-helpers/src/money";
import msToTime from "astrid-helpers/src/msToTime";

import Flex from "../../../../../components/Flex/Flex";
import Accordion from "../../../../ui/components/Accordion/Accordion";

import OrderLabel from "./OrderLabel";

export default function OrderTitle({ active, order, ...props }) {
	return (
		<Accordion.Title {...props} active={active}>
			<Flex style={{ gap: 12, justifyContent: "flex-start", whiteSpace: "nowrap" }}>
				<Header as="h4" style={{ margin: 0 }}>
					{order.created?.toDate().toLocaleDateString(undefined, {
						year: "numeric",
						month: "long",
						day: "numeric",
					})}
					<Header.Subheader>{order.firstParty.vendor?.name || order.firstParty.name}</Header.Subheader>
				</Header>
			</Flex>

			<Flex style={{ gap: 12, justifyContent: "flex-end", whiteSpace: "nowrap" }}>
				<Header as="h4" style={{ margin: 0 }}>
					{order.total
						? money(-order.total, order.currency)
						: msToTime(order.totalAmountProduced * 60 * 1000, false)}
				</Header>
				<OrderLabel order={order} />
			</Flex>
		</Accordion.Title>
	);
}
