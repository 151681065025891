import { Icon } from "semantic-ui-react";

import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import organizationTypes from "astrid-firestore/src/api/organizations/constants/organizationTypes";

import ProgressBar from "../../../../../components/progressbar/ProgressBar";

import ToggleSwitchIndex from "../components/ToggleSwitchIndex";

import useRefinementTranslations from "./translations/useRefinementTranslations";

function TextNoWrap({ text, style }) {
	return <p style={{ ...style, whiteSpace: "nowrap" }}>{text}</p>;
}

export default function useProductionsColumns({ type, selectedColumns }) {
	const { t } = useTranslation(["common", "language"]);
	const translations = useRefinementTranslations();

	return useMemo(
		() =>
			[
				{
					id: "title",
					Header: t("title"),
					Cell: ({ productionId, title }) => {
						return <Link to={`/production/${productionId}`}>{title}</Link>;
					},
				},
				{
					id: "status",
					Header: t("status"),
					Cell: ({ status }) => translations[status],
				},
				selectedColumns["series"] && {
					id: "series",
					Header: t("seriesPart", "Series (part)"),
				},
				selectedColumns["author"] && {
					id: "author",
					Header: t("author"),
				},
				(type === organizationTypes.PRODUCER || type === organizationTypes.ADMIN) &&
					selectedColumns["publisher"] && {
						id: "publisher",
						Header: t("publisher"),
					},
				(type === organizationTypes.PUBLISHER || type === organizationTypes.ADMIN) &&
					selectedColumns["producer"] && {
						id: "producer",
						Header: t("prodComp"),
					},
				selectedColumns["progressBar"] && {
					id: "progressBar",
					Header: t("progress"),
					Cell: ({ progressBar }) => {
						return <ProgressBar {...progressBar} />;
					},
				},
				selectedColumns["noOfCorrections"] && {
					id: "noOfCorrections",
					Header: t("noOfCorrections"),
					Cell: ({ noOfCorrections }) => {
						return noOfCorrections?.progressText;
					},
				},
				selectedColumns["productionType"] && {
					id: "productionType",
					Header: t("productionTypeNoSemi"),
					Cell: ({ productionType }) => {
						return t(productionType);
					},
				},
				selectedColumns["productionTags"] && {
					id: "productionTags",
					Header: t("productionTags"),
				},
				selectedColumns["isbn"] && {
					id: "isbn",
					Header: t("isbn"),
				},
				selectedColumns["ebookISBN"] && {
					id: "ebookISBN",
					Header: t("ebookISBN", "Ebook ISBN"),
				},
				selectedColumns["language"] && {
					id: "language",
					Header: t("language"),
					Cell: ({ language }) => t(`language:${language}`),
				},
				selectedColumns["publisherManagerNames"] && {
					id: "publisherManagerNames",
					Header: t("publisherManager"),
				},
				selectedColumns["readerNames"] && {
					id: "readerNames",
					Header: t("narrator"),
				},
				selectedColumns["voiceNames"] && {
					id: "voiceNames",
					Header: t("voices", "Voices"),
				},
				selectedColumns["editorNames"] && {
					id: "editorNames",
					Header: t("editor"),
				},
				selectedColumns["prooferNames"] && {
					id: "prooferNames",
					Header: t("proofer"),
				},
				selectedColumns["managerNames"] && {
					id: "managerNames",
					Header: t("productionManager"),
				},
				selectedColumns["subcontractorName"] && {
					id: "subcontractorName",
					Header: t("subcontractor"),
				},
				selectedColumns["duration"] && {
					id: "duration",
					Header: t("duration"),
					Cell: ({ duration }) => {
						return duration?.time && duration?.isEstimated ? `${duration.time} *` : duration?.time;
					},
				},
				selectedColumns["scriptDate"] && {
					id: "scriptDate",
					Header: t("script"),
					Cell: ({ scriptDate }) => {
						return (
							<p style={{ display: "flex", alignItems: "baseline" }}>
								<span style={{ whiteSpace: "nowrap" }}>{scriptDate?.date}</span>
								{scriptDate?.date && scriptDate?.isUploaded && (
									<Icon style={{ marginLeft: "5px" }} name="check" color="green" />
								)}
							</p>
						);
					},
				},
				selectedColumns["doneDate"] && {
					id: "firstDoneDate",
					Header: t("doneDate"),
					Cell: ({ firstDoneDate }) => {
						return <TextNoWrap text={firstDoneDate} />;
					},
				},
				selectedColumns["created"] && {
					id: "created",
					Header: t("created"),
					Cell: ({ created }) => {
						return <TextNoWrap text={created} />;
					},
				},
				selectedColumns["updated"] && {
					id: "updated",
					Header: t("updated"),
					Cell: ({ updated }) => {
						return <TextNoWrap text={updated} />;
					},
				},
				{
					id: "deliveryDate",
					Header: t("deliveryDate"),
					Cell: ({ deliveryDate }) => {
						return <TextNoWrap text={deliveryDate} />;
					},
					CustomHeader: {
						icon: (
							<ToggleSwitchIndex
								items={[
									{
										value: "deliveryDate_asc",
										iconName: "sort up",
										refineTo: "deliveryDate_desc",
									},
									{
										value: "deliveryDate_desc",
										iconName: "sort down",
										refineTo: "deliveryDate_asc",
									},
								]}
								fallback={{
									value: "productions",
									iconName: "sort",
									refineTo: "deliveryDate_asc",
								}}
							/>
						),
					},
				},
				(type === organizationTypes.PRODUCER || type === organizationTypes.ADMIN) && {
					id: "productionDate",
					Header: t("prodDate"),
					CustomHeader: {
						icon: (
							<ToggleSwitchIndex
								items={[
									{
										value: "productions",
										iconName: "sort up",
										refineTo: "productionDate_desc",
									},
									{
										value: "productionDate_desc",
										iconName: "sort down",
										refineTo: "productions",
									},
								]}
								fallback={{
									value: "productions",
									iconName: "sort",
									refineTo: "productionDate_desc",
								}}
							/>
						),
					},
				},
			].filter((v) => v),
		[t, selectedColumns, type, translations],
	);
}
