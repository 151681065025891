import React from "react";
import { Outlet, useOutletContext } from "react-router-dom";

import LoadingContext from "../../ui/components/LoadingContext/LoadingContext";

import featureFlags from "../../authorization/constants/featureFlags";

import WorkView from "../views/WorkView/WorkView";

export default function Work() {
	const { organization, work } = useOutletContext();

	return (
		<LoadingContext featureFlag={featureFlags.FLATTENING}>
			<WorkView organization={organization} work={work}>
				<Outlet context={{ organization, work }} />
			</WorkView>
		</LoadingContext>
	);
}
