import { parsePhoneNumber } from "awesome-phonenumber";
import moment from "moment";

import { Divider, Grid, Header, Segment } from "semantic-ui-react";

import React from "react";
import { useTranslation } from "react-i18next";

import Flex from "astrid-components/lib/components/Layout/Flex";

import { formatDate } from "../../../helpers/date";
import DropdownMenu from "../../ui/components/DropdownMenu/DropdownMenu";
import ImageAvatar from "../../ui/components/ImageAvatar/ImageAvatar";

import VoiceReaderLanguages from "../../readers/components/ReaderSearchResults/components/ReaderLanguages";

import ReaderInformation from "../components/ReaderInformation";
import UserProfileInformation from "../components/UserProfileInformation";
import useProfileDropdownMenuOptions from "../hooks/useProfileDropdownMenuOptions";

export default function ProfileView({ user }) {
	const { t } = useTranslation();

	const options = useProfileDropdownMenuOptions({ user });

	return (
		<Segment padded>
			<Grid style={{ maxWidth: 1400, margin: "0 auto" }}>
				<Grid.Row>
					<Grid.Column width={4}>
						<ImageAvatar src={user.img} size="medium" avatar={false} style={{ borderRadius: 10 }} />
					</Grid.Column>

					<Grid.Column width={11}>
						<Flex style={{ marginBottom: "1em" }}>
							<Header as="h2" style={{ margin: 0 }}>
								{user.firstName} {user.lastName}
							</Header>

							<DropdownMenu iconStyle={{ fontSize: 15 }} options={options} />
						</Flex>

						<Flex style={{ flexDirection: "column", marginTop: 5 }} gap={5} alignItems="flex-start">
							{user.visibleContactDetails && (
								<>
									<UserProfileInformation icon="mail" value={user.email}>
										{user.email}
									</UserProfileInformation>

									{user.phone && (
										<UserProfileInformation icon="phone" value={user.phone}>
											{parsePhoneNumber(user.phone).getNumber("international")}
										</UserProfileInformation>
									)}
								</>
							)}

							<UserProfileInformation icon="birthday" value={user.dateOfBirth}>
								{formatDate(user.dateOfBirth) +
									" " +
									(moment().diff(formatDate(user.dateOfBirth), "years") + " " + t("yearsOld", "y/o"))}
							</UserProfileInformation>

							<UserProfileInformation icon="venus mars" value={user.gender}>
								{t(user.gender)}
							</UserProfileInformation>
						</Flex>

						{user.languages && (
							<>
								<Divider />
								<VoiceReaderLanguages languageSkills={user?.languages} />
							</>
						)}

						<ReaderInformation user={user} />
					</Grid.Column>
				</Grid.Row>
			</Grid>
		</Segment>
	);
}
