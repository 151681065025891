import { useTranslation } from "react-i18next";

export default function useUserMenuOptions({ isReader, isProducer }) {
	const { t } = useTranslation();

	return [
		{
			path: "#permissions",
			name: t("permissions", "Permissions"),
		},
		{
			path: "#general",
			name: t("general", "General"),
			hidden: !isReader,
		},
		{
			path: "#languages",
			name: t("languages", "Languages"),
			hidden: !isReader,
		},
		{
			path: "#narration",
			name: t("narration", "Narration"),
			hidden: !isReader,
		},
		{
			path: "#samples",
			name: t("samples", "Samples"),
			hidden: !isReader,
		},
		{
			path: "#prices",
			name: t("prices", "Prices"),
			hidden: !isProducer,
		},
		{
			path: "#agreements",
			name: t("agreements", "Agreements"),
			hidden: !isProducer,
		},
		{
			path: "#vendors",
			name: t("vendors", "Vendors"),
			hidden: !isProducer,
		},
	];
}
