import React from "react";
import { useTranslation } from "react-i18next";

import { updateDocument } from "astrid-firestore/src/helpers";
import { currencyOptions } from "astrid-hooks/src/useCurrencyOptions";

import AdminForm from "../../../../admin/components/AdminForm/AdminForm";
import Form from "../../../../forms/components/Form/Form";
import useSimpleForm from "../../../../forms/hooks/useSimpleForm";
import CurrencySelect from "../../../../prices/components/CurrencySelect/CurrencySelect";

export default function OrganizationFinanceSettings({ organization }) {
	const { t } = useTranslation();

	const form = useSimpleForm({
		defaultValues: {
			currencyOptions: organization.currencyOptions || [],
			currencyDefault: organization.currencyDefault || null,
			scheduledOrdersDate: organization.finance?.scheduledOrdersDate || 25,
			invoiceFooter: organization.invoiceFooter || "",
		},
		onSubmit: ({ currencyOptions, currencyDefault, scheduledOrdersDate, invoiceFooter }) => {
			return updateDocument(organization.ref, {
				currencyOptions,
				currencyDefault,
				"finance.scheduledOrdersDate": scheduledOrdersDate,
				invoiceFooter,
			});
		},
	});

	const currencyOptions = form.watch("currencyOptions");

	return (
		<AdminForm header={t("financeSettings", "Finance settings")} {...form}>
			<Form.Group widths="equal">
				<Form.Field
					name="currencyOptions"
					label={t("selectableCurrencies", "Selectable currencies")}
					rules={{ required: false }}
				>
					<CurrencySelect search multiple />
				</Form.Field>
				<Form.Field
					name="currencyDefault"
					label={t("currencyDefault", "Default currency")}
					rules={{ required: false }}
				>
					<CurrencySelect search limitedOptions={currencyOptions} />
				</Form.Field>
				<Form.NumberInput
					name="scheduledOrdersDate"
					label={t("scheduledPurchaseOrdersDayInMonth")}
					rules={{ required: false }}
				/>
			</Form.Group>
			<Form.TextArea
				name="invoiceFooter"
				rows={6}
				label={t("invoiceFooter", "Invoice footer")}
				rules={{ required: false }}
			/>
		</AdminForm>
	);
}
