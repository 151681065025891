import { useMemo } from "react";
import { useTranslation } from "react-i18next";

export default function usePriceTargetsTableColumns() {
	const { t } = useTranslation();

	return useMemo(
		() => [
			{
				id: "name",
				filter: "text",
				Header: t("name"),
				accessor: ({ firstName, lastName }) => `${firstName} ${lastName}`,
			},
		],
		[t],
	);
}
